import { createContext, FC, useContext, useEffect } from 'react';

import { Global, css } from '@emotion/react';
import { useRouter } from 'next/router';

import COOKIES from '~/constants/cookies';
import { LanguageId, SupportedSourceLanguages } from '~/constants/languages';
import { useCookie } from '~/helpers/cookies';

const localeContext = createContext<{
  uiLanguage: SupportedSourceLanguages;
  updateUILanguage: (uiLanguage: SupportedSourceLanguages) => void;
}>({
  uiLanguage: LanguageId.En,
  updateUILanguage: () => {},
});

export const useUILanguage = () => useContext(localeContext);

export const LocaleProvider: FC = ({ children }) => {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const [, setUILanguageCookie] = useCookie(COOKIES.NEXT_LOCALE);
  const uiLanguage = (locale || 'en') as SupportedSourceLanguages;
  const updateUILanguage = (languageId: SupportedSourceLanguages) => {
    router.replace({ pathname, query }, asPath, { locale: languageId, scroll: false });
    setUILanguageCookie({ value: languageId, numDaysActive: 10 });
  };
  const isSourceLanguageKO = uiLanguage === LanguageId.Ko;

  useEffect(() => {
    document.documentElement.lang = uiLanguage;
  }, [uiLanguage]);

  return (
    <localeContext.Provider value={{ uiLanguage, updateUILanguage }}>
      {isSourceLanguageKO && (
        <Global
          styles={css`
            * {
              word-break: keep-all;
            }
          `}
        />
      )}
      {children}
    </localeContext.Provider>
  );
};
