import { forwardRef, Ref } from 'react';

import { SerializedStyles } from '@emotion/react';
import { BoxProps as MUIBoxProps, Box as MUIBox } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';

export type BoxProps = MUIBoxProps & { css?: SerializedStyles; ref?: Ref<HTMLDivElement> };

export const Box = forwardRef<HTMLDivElement, BoxProps>(({ ...props }, ref) => (
  // @ts-ignore types are only broken https://github.com/mui-org/material-ui/issues/17010
  <MUIBox {...props} ref={ref} />
));

export type MotionBoxProps = BoxProps & MotionProps;

export const MotionBox = forwardRef<HTMLDivElement, MotionBoxProps>((props, ref) => (
  // @ts-ignore types are only broken
  <MUIBox component={motion.div} {...props} ref={ref} />
));

export type BoxImageProps = BoxProps & { src: string; alt: string };

export const BoxImage = forwardRef<HTMLImageElement, BoxImageProps>((props, ref) => (
  // @ts-ignore types are only broken
  <MUIBox component="img" {...props} ref={ref} />
));
