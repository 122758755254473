export enum COOKIES {
  AD_PLATFORM = 'adPlatform',
  ATTRIBUTION_GROUP = 'attributionGroup',
  LAST_PINCHPOINT = 'last_pp',
  NEXT_LOCALE = 'NEXT_LOCALE',
  PARTNER_SPECIFIC_PROPERTIES = 'partner_specific_properties',
  PARTNER = 'partner',
  TOKEN = 'token',
  REDEEM_CODE = 'redeemCode',
  REFRESH_TOKEN = 'refreshToken',
  REFERRAL_UTM_MEDIUM = 'referralUtmMedium',
  REFERRAL_UTM_SOURCE = 'referralUtmSource',
  TOUCAN_USER_ID = 'toucanUserId',
  HAS_SEEN_EXIT_INTENT_MODAL = 'has_seen_exit_intent_modal',
}

export default COOKIES;
