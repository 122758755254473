import { IncomingMessage } from 'http';

import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import COOKIES from '~/constants/cookies';
import { getCookie } from '~/helpers/cookies';
import pkg from '~/package.json';

import configs from './config';
import { customFetchWithReauthorization } from './reauthorizationApolloClientFetch';

export const createApolloClient = (
  req?: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string;
    }>;
  },
) => {
  const linkOptions = {
    uri: configs.TOUCAN_GRAPHQL_ENDPOINT,
    credentials: 'same-origin',
    fetch: customFetchWithReauthorization,
  };

  const httpLink = new HttpLink(linkOptions);

  const authLink = setContext((_, { headers }) => {
    const token = getCookie({ name: COOKIES.TOKEN, cookies: req?.cookies });
    const refreshToken = getCookie({
      name: COOKIES.REFRESH_TOKEN,
      cookies: req?.cookies,
    });

    // TODO: Get amplitude anonymous id
    let ajsAnonymousId = '';
    if (typeof window !== 'undefined' && window.amplitude) {
      ajsAnonymousId = window.amplitude.getDeviceId() ?? '';
    }
    const authorizationString = token ? `Bearer ${token}` : '';

    return {
      headers: {
        ...headers,
        'apollographql-client-name': pkg.name,
        ...(authorizationString && { authorization: authorizationString }),
        refreshToken: refreshToken || '',
        'ajs-anonymous-id': ajsAnonymousId,
      },
    };
  });

  return new ApolloClient({
    connectToDevTools: typeof window === 'undefined',
    ssrMode: typeof window !== 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
