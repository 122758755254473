import gql from 'graphql-tag';

const EMAIL_EXTENSION_DOWNLOAD_LINK = gql`
  mutation emailExtensionDownloadLink($email: String!) {
    emailExtensionDownloadLink(email: $email)
  }
`;

const LOG_OUT = gql`
  mutation logOut($refreshToken: String!) {
    logOut(refreshToken: $refreshToken)
  }
`;

const TRANSLATE_PHRASE = gql`
  mutation translatePhrase($input: MachineTranslationInput!) {
    translatePhrase(input: $input) {
      translation
    }
  }
`;

const SAVE_LANGUAGE_SELECTION = gql`
  mutation saveLanguageSelection($input: SaveLanguageSelectionInput!) {
    saveLanguageSelection(input: $input)
  }
`;

const CONTACT_US_SUBMISSION = gql`
  mutation contact($email: String!, $name: String!, $feedback: String!, $submissionType: SubmissionType) {
    contact(email: $email, name: $name, feedback: $feedback, submissionType: $submissionType)
  }
`;

const UPDATE_USERS_NAME = gql`
  mutation updateUsersName($name: String!) {
    updateUsersName(name: $name)
  }
`;

const UPDATE_USER_PASSWORD_V2 = gql`
  mutation updateUsersPasswordV2($newPassword: String!, $currentPassword: String!) {
    updateUsersPasswordV2(newPassword: $newPassword, currentPassword: $currentPassword)
  }
`;

const PROCESS_TEXT_THROUGH_NLP_PIPELINE = gql`
  mutation processTextThroughNlpPipeline($input: NlpPipelineInput!) {
    processTextThroughNlpPipeline(input: $input) {
      sentences {
        text
        start
        end
      }
      chunks {
        text
        start
        end
        chunkType
        containsNamedEntity
      }
      tokens {
        text
        index
        pos
        entityType
        dependencyArc {
          start
          end
          label
          direction
        }
      }
    }
  }
`;

export default {
  CONTACT_US_SUBMISSION,
  EMAIL_EXTENSION_DOWNLOAD_LINK,
  LOG_OUT,
  PROCESS_TEXT_THROUGH_NLP_PIPELINE,
  SAVE_LANGUAGE_SELECTION,
  TRANSLATE_PHRASE,
  UPDATE_USER_PASSWORD_V2,
  UPDATE_USERS_NAME,
};
