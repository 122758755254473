import { ReactNode, useState } from 'react';

import { merge } from 'lodash';
import { useTranslation } from 'next-i18next';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';

import { FACEBOOK_APP_ID } from '~/constants/external';
import { LanguageId } from '~/constants/languages';
import { SeoContext } from '~/context/SeoContext';
import Config from '~/lib/config';

import { useUILanguage } from '../LocaleProvider';

interface SeoProviderProps {
  children: ReactNode;
}

export const SeoProvider = ({ children }: SeoProviderProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { uiLanguage: sourceLanguage } = useUILanguage();
  const pageDescription = t('defaultMetaDescription');
  const getOpenGraphImages = () => {
    const openGraphImages = [{ url: `${Config.SITE}/images/home/en/open-graph-default.png`, width: 1200, height: 630 }];
    if (sourceLanguage && sourceLanguage !== LanguageId.En) {
      openGraphImages[0].url = `${Config.SITE}/images/home/${sourceLanguage}/open-graph-en.png`;
    }
    return openGraphImages;
  };
  const defaultOptions: NextSeoProps = {
    title: t('defaultMetaTitle'),
    description: pageDescription,
    openGraph: {
      type: 'website',
      locale: router?.locale || 'en_US',
      site_name: 'jointoucan.com',
      url: `${Config.SITE}${router?.locale !== 'en' ? `/${router?.locale}` : ''}${
        router?.asPath !== '/' ? router?.asPath : ''
      }`,
      title: t('defaultMetaTitle'),
      description: pageDescription,
      images: getOpenGraphImages(),
    },
    twitter: {
      site: '@jointoucan',
      cardType: 'summary_large_image',
    },
    facebook: { appId: FACEBOOK_APP_ID },
  };
  const [pageOptions, setPageOptions] = useState<NextSeoProps>({});
  const mergedOptions = merge(defaultOptions, pageOptions);
  return (
    <SeoContext.Provider
      value={{
        options: mergedOptions,
        setOptions: setPageOptions,
      }}
    >
      <NextSeo {...defaultOptions} />
      {children}
    </SeoContext.Provider>
  );
};
