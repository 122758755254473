import { ApolloClient } from '@apollo/client';
import cookie from 'cookie';

import COOKIES from '~/constants/cookies';
import GraphQLMutations from '~/networking/graphqlMutations';

import { identifyUser } from './analytics';
import { setCookie, expireCookie } from './cookies';

export const authExpirationDays = 1825;

const setTokenCookie = (token: string, numDaysActive = authExpirationDays) => {
  setCookie({
    name: COOKIES.TOKEN,
    value: token,
    numDaysActive,
  });
};

const setRefreshTokenCookie = (token: string, numDaysActive = authExpirationDays) => {
  setCookie({
    name: COOKIES.REFRESH_TOKEN,
    value: token,
    numDaysActive,
  });
};

const setUserIdCookie = (userId: string, numDaysActive = authExpirationDays) => {
  setCookie({
    name: COOKIES.TOUCAN_USER_ID,
    value: userId,
    numDaysActive,
  });
};

const setAuthCookies = ({ token, refreshToken }: { token: string; refreshToken: string }) => {
  setTokenCookie(token);
  setRefreshTokenCookie(refreshToken);
};

const getAuthCookies = () => {
  const cookies = cookie.parse(document.cookie);
  const authToken = cookies.token || '';
  const refreshToken = cookies.refreshToken || '';

  return { authToken, refreshToken };
};

const identifyUserWithAttachedAttributionChannels = (userId: string) => {
  const cookies = cookie.parse(document.cookie);

  identifyUser(userId, {
    ...(cookies.adPlatform && { adPlatform: cookies.adPlatform }),
    ...(cookies.attributionGroup && { attributionGroup: cookies.attributionGroup }),
  });
};

const getPartnerFromCookie = (): string | null => {
  const cookies = cookie.parse(document.cookie);
  return cookies.partner ?? null;
};

const signOut = async (apolloClient: ApolloClient<object> | null) => {
  if (apolloClient) {
    const { refreshToken } = getAuthCookies();
    await apolloClient.mutate({
      mutation: GraphQLMutations.LOG_OUT,
      variables: {
        refreshToken,
      },
    });
  }

  expireCookie({ name: COOKIES.TOKEN });
  expireCookie({ name: COOKIES.REFRESH_TOKEN });
  expireCookie({ name: COOKIES.TOUCAN_USER_ID });
  expireCookie({ name: 'onboardingStep' });
  expireCookie({ name: COOKIES.HAS_SEEN_EXIT_INTENT_MODAL });

  // Clear out all amplitude cookie & local storage values
  window.amplitude?.reset();

  // Force a reload of all the current queries now that the user is
  // logged in, so we don't accidentally leave any state around.
  if (apolloClient) {
    apolloClient.cache.reset();
  }
};

export default {
  identifyUserWithAttachedAttributionChannels,
  getPartnerFromCookie,
  signOut,
  setCookie,
  setAuthCookies,
  getAuthCookies,
  setTokenCookie,
  setRefreshTokenCookie,
  setUserIdCookie,
};
