import { createContext, FC, useContext } from 'react';

import { ApolloClient } from '@apollo/client';

import { PartialBy } from '~/components/shared/ProductTutorial/helpers';
import { ExtensionConfig } from '~/lib/extension-config';
import { LoggedInUser } from '~/lib/getLoggedInUser';
import { useMeLazyQuery } from '~/networking/schema';
import { DeviceConfig } from '~/types/next';

export interface UserContextProps {
  allCookies: { [key: string]: any };
  loggedInUser: LoggedInUser | false;
  isLoggedInUserLoading: boolean;
  refreshUser: () => Promise<void>;
  isLoggedIn: boolean;
  client: ApolloClient<object> | null;
  isMobileDevice: boolean;
  isExternalAuthRedirect: boolean;
  extensionConfig: ExtensionConfig;
  deviceConfig: Partial<DeviceConfig>;
}

const UserContext = createContext<UserContextProps>({
  allCookies: {},
  loggedInUser: false,
  isLoggedIn: false,
  isLoggedInUserLoading: true,
  client: null,
  isMobileDevice: false,
  isExternalAuthRedirect: false,
  refreshUser: async () => {},
  extensionConfig: {
    browserName: '',
    browserVersion: '',
    storeUrl: '',
    isSupported: false,
    extensionId: '',
    messageKey: '',
  },
  deviceConfig: {
    browserName: '',
    browserVersion: '',
    operatingSystem: '',
    operatingSystemVersion: '',
  },
});

export const GlobalContext: FC<PartialBy<UserContextProps, 'refreshUser'>> = ({
  children,
  loggedInUser: user,
  ...otherProps
}) => {
  const [getLoggedInUser, { data, loading, refetch, error }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const authError = error?.message.includes('Auth token');
  const loggedInUser = data?.me || user || false;
  const isLoading = (!loggedInUser && data?.me !== null && !authError) || loading;

  const refreshUser = async () => {
    if (data?.me && refetch) {
      await refetch();
    } else {
      await getLoggedInUser();
    }
  };

  return (
    <UserContext.Provider value={{ ...otherProps, loggedInUser, refreshUser, isLoggedInUserLoading: isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useGlobalContext = () => useContext(UserContext);

export default UserContext;

export const SsrGlobalContext: FC<Partial<UserContextProps>> = ({ children, ...otherProps }) => {
  const globalContext = useGlobalContext();
  return <UserContext.Provider value={{ ...globalContext, ...otherProps }}>{children}</UserContext.Provider>;
};
