import { useEffect, useState } from 'react';

import { useLoggedInUser } from '~/hooks/useLoggedInUser';

import { Features } from './features';

export const useFeatureEnabled = (name: string) => {
  const { loggedInUser } = useLoggedInUser();
  const [isReady, setReady] = useState(Features.isReady);
  const isEnabled =
    loggedInUser && loggedInUser?.id && isReady ? Features.isFeatureEnabled(name, loggedInUser.id) : false;
  useEffect(() => {
    if (!isReady) {
      Features.onReady().then(() => {
        setReady(true);
      });
    }
  }, [isReady]);
  return isEnabled;
};
