import { getConfig } from './extensionDeviceConfig';

export const getBrowserProperties = (userAgent: string) => {
  const { deviceConfig } = getConfig(userAgent);
  const browserName = deviceConfig.browserName ?? 'unknown';
  const browserVersion = deviceConfig.browserVersion ?? 'unknown';
  const operatingSystem = deviceConfig.operatingSystem ?? 'unknown';
  const operatingSystemVersion = deviceConfig.operatingSystemVersion ?? 'unknown';

  // Return human readable browser properties
  return {
    'Browser Name': browserName,
    'Browser Version': browserVersion,
    OS: operatingSystem,
    'OS Version': operatingSystemVersion,
  };
};

/**
 * Record user actions, along with any properties that describe the action. [docs](https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-an-event)
 *
 * This method also has the side effect of adding in browser properties to every event.
 *
 * @param {Object} eventData - Data that is needed to track event
 * @param {string} eventData.name - Name of the action that a user has performed
 * @param {Object} [eventData.properties] - Properties that describe the action
 *
 * @example
 *
 * ```
 * trackEvent({
 *   name: 'Quiz - User logged in',
 *   properties: {
 *     category: 'Challenges',
 *     entryPoint: 'New Tab',
 *   }
 * })
 * ```
 *
 */
export const trackEvent = ({ name, properties = {} }: { name: string; properties?: object }) => {
  if (!window.amplitude) {
    return;
  }
  const decoratedProperties = {
    ...properties,
    ...getBrowserProperties(global.navigator?.userAgent),
    toucanContext: {
      sender: { name: 'toucan-website' },
    },
  };

  window.amplitude?.track(name, decoratedProperties);
};

/**
 * Tie a user to their actions and record traits about them. [docs](https://www.docs.developers.amplitude.com/data/sdks/browser-2/#user-properties)
 *
 * This method also has the side effect of adding in browser properties to every user.
 *
 * @param {Object} properties - Properties that help to identify the user
 *
 * @example
 *
 * ```
 * identifyUser({
 *   organizationId: 123456
 * })
 * ```
 *
 */
export const identifyUser = (userId: string, traits?: object) => {
  if (!window.amplitude) {
    return;
  }

  const decoratedTraits: { [key: string]: number | boolean | string } = {
    ...traits,
    ...getBrowserProperties(global.navigator?.userAgent),
  };

  window.amplitude?.setUserId(userId);

  const identifyObj = new window.amplitude.Identify();

  const decoratedTraitsKeys = Object.keys(decoratedTraits);
  decoratedTraitsKeys.forEach((key: string) => {
    identifyObj.set(key, decoratedTraits[key]);
  });

  window.amplitude?.identify(identifyObj, {
    user_id: userId,
  });
};
