import { useState, useRef } from 'react';

import { ToucanComponents } from '@jointoucan/toucan-design';
import PropTypes from 'prop-types';

import ModalContext from '~/context/ModalContext';

const { Modal } = ToucanComponents;

const initialModalState = {
  ContentComponent: () => {
    return false;
  },
  contentProps: {},
  isCloseButtonVisible: false,
  isModalHeaderVisible: false,
  isToucanBrandingVisible: false,
  isOpen: false,
  onClose: () => {},
  hasPadding: true,
  className: null,
  modalContentClassName: null,
  modalHeader: null,
};

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState(initialModalState);
  const [isInDom, setIsInDom] = useState(false);
  const modalStateRef = useRef();

  // The modal state needs to be saved in a ref so the callbacks that are on the provider have access to the current version of the modal state
  modalStateRef.current = modalState;

  const closeModal = () => {
    setModalState({ ...modalStateRef.current, isOpen: false });
  };

  const showModal = ({
    ContentComponent,
    closeButtonColor,
    contentProps,
    isCloseButtonVisible,
    onClose = () => {},
    hasPadding,
    className,
    modalContentClassName,
    disableBackdropClick,
    isModalHeaderVisible,
    isToucanBrandingVisible,
    modalHeader,
  }) => {
    setModalState({
      ContentComponent,
      closeButtonColor,
      contentProps,
      isCloseButtonVisible,
      isOpen: true,
      onClose,
      hasPadding,
      className,
      modalContentClassName,
      disableBackdropClick,
      isModalHeaderVisible,
      isToucanBrandingVisible,
      modalHeader,
    });
    setIsInDom(true);
  };

  return (
    <ModalContext.Provider
      value={{
        modal: {
          closeModal,
          showModal,
        },
      }}
    >
      {children}
      {isInDom && (
        <Modal
          isOpen={modalState.isOpen}
          onClose={(event, reason) => {
            modalState.onClose(reason);
            setModalState({
              ...modalState,
              isOpen: false,
            });
          }}
          isCloseButtonVisible={modalState.isCloseButtonVisible}
          closeButtonColor={modalState.closeButtonColor}
          hasPadding={modalState.hasPadding}
          className={modalState.className}
          modalContentClassName={modalState.modalContentClassName}
          disableBackdropClick={modalState.disableBackdropClick}
          isModalHeaderVisible={modalState.isModalHeaderVisible}
          isToucanBrandingVisible={modalState.isToucanBrandingVisible}
          modalHeader={modalState.modalHeader}
          onClosedAnimationComplete={() => {
            setIsInDom(false);
          }}
          marginTop={70}
        >
          <modalState.ContentComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...modalState.contentProps}
          />
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
