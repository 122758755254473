import { createContext, useContext, useState, useCallback, FC, useRef, useEffect } from 'react';

import ReactDOM from 'react-dom';
import { v4 } from 'uuid';

import { Toast } from './Toast';
import { ToastBox } from './ToastBox';
import { ToastContext, ToastProps } from './types';

const toastContext = createContext<ToastContext>({
  toasts: [],
  showToast: () => ({ clearTimeout: () => {} }),
  removeToast: () => {},
});
const { Provider } = toastContext;
export const useToast = () => useContext(toastContext);

export const ToastProvider: FC = ({ children }) => {
  const isMounted = useRef<boolean>(true);
  const [toasts, setToasts] = useState<ToastContext['toasts']>([]);
  const [portalNode] = useState<HTMLElement | null>(() => {
    if (typeof document === 'undefined') {
      return null;
    }
    const el = document.createElement('div');
    el.id = 'toast-portal';
    el.style.zIndex = '2147483647';
    document.documentElement.appendChild(el);
    return el;
  });

  const removeToast = useCallback(
    (id: string) => {
      setToasts((currentToasts) => {
        const index = currentToasts.findIndex((toast) => toast.id === id);
        if (index !== -1) {
          return [...currentToasts.slice(0, index), ...currentToasts.slice(index + 1)];
        }
        return currentToasts;
      });
    },
    [toasts],
  );

  const showToast = useCallback(
    (incomingToast: ToastProps) => {
      const id = incomingToast.id ?? v4();

      setToasts((currentToasts) => {
        const index = currentToasts.findIndex((toast) => toast.id === id);
        if (index !== -1) {
          return currentToasts;
        }
        return [...currentToasts, { ...incomingToast, id }];
      });
      // Cleanup toasts in timeout
      const ts = window.setTimeout(() => {
        if (!isMounted) {
          return;
        }
        removeToast(id);
        incomingToast.onTimeout?.();
      }, incomingToast.timeout ?? 5000);

      return {
        clearTimeout: () => {
          clearTimeout(ts);
        },
      };
    },
    [toasts, removeToast],
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Provider value={{ toasts, showToast, removeToast }}>
      {children}
      {portalNode &&
        ReactDOM.createPortal(
          <ToastBox>
            {toasts.map(
              (
                {
                  title,
                  subtitle,
                  image,
                  onClick,
                  onClose,
                  id,
                  className,
                  subtitleColor,
                  titleColor,
                  isAnimation,
                  backgroundColor,
                  boldTitle,
                  boldSubtitle,
                },
                i,
              ) => (
                <Toast
                  yPosition={-(i * 96)} // 96 = 80 toast height + 16 bottom margin
                  id={id}
                  className={className}
                  title={title}
                  subtitle={subtitle}
                  image={image}
                  onClick={onClick}
                  subtitleColor={subtitleColor}
                  titleColor={titleColor}
                  backgroundColor={backgroundColor}
                  boldTitle={boldTitle}
                  boldSubtitle={boldSubtitle}
                  key={id}
                  onAttemptClose={() => {
                    removeToast(id);
                    if (onClose) {
                      onClose();
                    }
                  }}
                  isAnimation={isAnimation}
                />
              ),
            )}
          </ToastBox>,
          portalNode,
        )}
    </Provider>
  );
};
