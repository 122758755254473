type SupportedBrowsers = 'Safari' | 'Chrome' | 'Microsoft Edge' | 'Firefox';
type Environment = 'staging' | 'production' | 'test' | 'development';

export interface ExtensionConfig {
  isSupported: boolean;
  storeUrl: string;
  browserName: string;
  browserVersion: string;
  extensionId: string;
  messageKey: string;
}

interface CreateExtensionOptions {
  browserName?: string;
  browserVersion?: string;
  environment: Environment;
  osName?: string;
}

export const chromeStoreUrl = 'https://chrome.google.com/webstore/detail/toucan/lokjgaehpcnlmkebpmjiofccpklbmoci';
const safariStoreUrl = 'https://apps.apple.com/us/app/toucan-for-safari/id1562755912?mt=12';
export const iosSafariStoreUrl = 'https://apps.apple.com/us/app/toucan-language-learning/id1562755912';
export const edgeStoreUrl =
  'https://microsoftedge.microsoft.com/addons/detail/toucan-language-learnin/ljbocbkhabkbkedlhmppmpfajejdkoei';
const firefoxStoreUrl = 'https://addons.mozilla.org/en-US/firefox/addon/toucan-language-learning';

const safariExtensionId = 'com.jointoucan.toucan-safari.Extension (7QY98ZHYH8)';

export const baseConfig: ExtensionConfig = {
  isSupported: false,
  storeUrl: '',
  browserName: '',
  browserVersion: '',
  extensionId: '',
  messageKey: 'Tanager-message',
};

export enum BrowserName {
  Chrome = 'Chrome',
  Safari = 'Safari',
  Edge = 'Edge',
  Firefox = 'Firefox',
  SafariiOS = 'Safari iOS',
}

export const configs: Record<SupportedBrowsers, Record<Environment, Partial<ExtensionConfig>>> = {
  Chrome: {
    test: {
      extensionId: 'FAKE',
    },
    development: {
      extensionId: 'aodfpolajpmokkkmkagaimpdmoobhkfe',
    },
    staging: {
      extensionId: 'cljaifpdjhboeidmhflhcmefpdoohfbl',
    },
    production: {
      extensionId: 'lokjgaehpcnlmkebpmjiofccpklbmoci',
    },
  },
  Safari: {
    test: {
      extensionId: 'FAKE',
    },
    development: {
      extensionId: safariExtensionId,
    },
    staging: {
      extensionId: safariExtensionId,
    },
    production: {
      extensionId: safariExtensionId,
    },
  },
  'Microsoft Edge': {
    test: {
      extensionId: 'FAKE',
    },
    development: {
      extensionId: 'aodfpolajpmokkkmkagaimpdmoobhkfe',
    },
    staging: {
      extensionId: 'cljaifpdjhboeidmhflhcmefpdoohfbl',
    },
    production: {
      extensionId: 'ljbocbkhabkbkedlhmppmpfajejdkoei',
    },
  },
  Firefox: {
    test: {
      extensionId: 'FAKE',
    },
    development: {
      extensionId: 'dev@jointoucan.com',
    },
    staging: {
      extensionId: 'staging@jointoucan.com',
    },
    production: {
      extensionId: '{fe50e88d-a084-4ff2-9d58-eeac466f937e}',
    },
  },
};

export const createExtensionConfig = ({
  environment,
  browserName,
  browserVersion,
  osName,
}: CreateExtensionOptions): ExtensionConfig => {
  let overrides: Partial<ExtensionConfig> = {};

  switch (browserName) {
    case 'Chrome':
    case 'Brave':
      overrides = {
        ...configs.Chrome[environment],
        isSupported: true,
        storeUrl: chromeStoreUrl,
        browserName: BrowserName.Chrome,
      };
      break;
    case 'Microsoft Edge':
      overrides = {
        ...configs['Microsoft Edge'][environment],
        isSupported: true,
        storeUrl: edgeStoreUrl,
        browserName: BrowserName.Edge, // Short name is good here
      };
      break;
    case 'Safari':
      overrides = {
        ...configs.Safari[environment],
        isSupported: true,
        storeUrl: osName === 'iOS' ? iosSafariStoreUrl : safariStoreUrl,
        browserName: BrowserName.Safari,
      };
      break;
    case 'Firefox':
      overrides = {
        ...configs.Firefox[environment],
        isSupported: true,
        storeUrl: firefoxStoreUrl,
        browserName: BrowserName.Firefox,
      };
      break;
    default:
      overrides = {
        ...configs.Chrome[environment],
        isSupported: false,
        storeUrl: chromeStoreUrl,
        browserName: BrowserName.Chrome,
      };
      break;
  }

  return {
    ...baseConfig,
    ...overrides,
    browserVersion: browserVersion ?? baseConfig.browserVersion,
  };
};
