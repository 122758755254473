import optimizelySdk from '@optimizely/optimizely-sdk';

import Config from '~/lib/config';

optimizelySdk.setLogLevel(Config.OPTIMIZELY_LOG_LEVEL);

let client: optimizelySdk.Client;
let ready: boolean = false;
export const Features = {
  init: (key: string) => {
    client = optimizelySdk.createInstance({
      sdkKey: key,
      datafileOptions: {
        urlTemplate: Config.TOUCAN_OPTIMIZELY_DATAFILE_URL,
      },
    });
    client.onReady().then(() => {
      ready = true;
    });
    return client;
  },
  isReady: () => ready,
  onReady: () => client.onReady(),
  isFeatureEnabled: (feature: string, userId: string) => {
    if (!ready) return false;
    return client.isFeatureEnabled(feature, userId);
  },
  isFeatureEnabledAndReady: async (feature: string, userId: string) => {
    if (!ready) {
      return client.onReady().then(() => {
        return client.isFeatureEnabled(feature, userId);
      });
    }
    return client.isFeatureEnabled(feature, userId);
  },
};
