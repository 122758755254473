import { LanguageId as ServerLanguageId } from '~/networking/schema';

// If you add a new language, add a corresponding page (e.g. pages/korean.tsx).
export enum Language {
  English = 'english',
  German = 'german',
  Spanish = 'spanish',
  French = 'french',
  Italian = 'italian',
  Japanese = 'japanese',
  Korean = 'korean',
  Portuguese = 'portuguese',
  Chinese = 'chinese',
  Arabic = 'arabic',
  Hebrew = 'hebrew',
  Hindi = 'hindi',
}

export enum LanguageCapitalized {
  English = 'English',
  German = 'German',
  Spanish = 'Spanish',
  French = 'French',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  Portuguese = 'Portuguese',
  Chinese = 'Chinese',
  Arabic = 'Arabic',
  Hebrew = 'Hebrew',
  Hindi = 'Hindi',
}

export enum LanguageVariant {
  Roman = 'roman',
  Kanji = 'kanji',
  HiraganaKatakana = 'hiraganaKatakana',
  Abjadiyyah = 'abjadiyyah',
  KtavAshuri = 'ktavAshuri',
  Devanagari = 'devanagari',
  Hangul = 'hangul',
  SimplifiedChinese = 'simplifiedChinese',
}

export enum LanguageId {
  En = 'en',
  De = 'de',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Pt = 'pt',
  Zh = 'zh',
  Ar = 'ar',
  He = 'he',
  Hi = 'hi',
  // UNSUPPORTED LANGUAGES
  Other = 'other',
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Ch = 'ch',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  Dv = 'dv',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  Eo = 'eo',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Ff = 'ff',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  Iu = 'iu',
  Jv = 'jv',
  Ka = 'ka',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nv = 'nv',
  Ny = 'ny',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ug = 'ug',
  Uk = 'uk',
  Ur = 'ur',
  Uz = 'uz',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Za = 'za',
  Zu = 'zu',
}

export enum IN_DEVELOPMENT_LANGUAGE_CODES {
  Other = 'other',
}
export interface LanguageVariantInformation {
  languageId: ServerLanguageId;
  key: LanguageVariant;
  isDefault?: boolean;
}
interface LanguageVariantsInformation {
  [key: string]: LanguageVariantInformation;
}
export interface LanguageInformation {
  key: Language;
  languageId: LanguageId;
  language: string;
  inLanguage?: string;
  imagePath?: string;
  flagEmoji?: string;
  isEarlyAccess?: boolean;
  variants?: LanguageVariantsInformation;
}

export const supportedSourceLanguageIds = [
  LanguageId.De,
  LanguageId.En,
  LanguageId.Es,
  LanguageId.Fr,
  LanguageId.It,
  LanguageId.Pt,
  LanguageId.Hi,
  LanguageId.Ko,
];
export type SupportedSourceLanguages =
  | LanguageId.En
  | LanguageId.Es
  | LanguageId.Pt
  | LanguageId.De
  | LanguageId.Fr
  | LanguageId.Hi
  | LanguageId.Ko
  | LanguageId.It;

// Note: When updating this variable, make sure you also do it at server.js#18
export const babbelSupportedLanguages: SupportedSourceLanguages[] = [
  LanguageId.En,
  LanguageId.Es,
  LanguageId.Pt,
  LanguageId.De,
  LanguageId.Fr,
  LanguageId.It,
];

export const CHARACTERS: Record<LanguageId, string> = {
  [LanguageId.En]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.Es]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.De]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.Fr]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.It]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.Ja]: 'うかけせにれのまを',
  [LanguageId.Ko]: '주년네일차물발', // week, year, yes, one, tea, water, foot
  [LanguageId.Pt]: 'abcdefghijklmnopqrstuvwxyz',
  [LanguageId.Zh]: '',
  [LanguageId.Ar]: '',
  [LanguageId.He]: '',
  [LanguageId.Hi]: '',
  // UNSUPPORTED LANGUAGES
  [LanguageId.Other]: '',
  [LanguageId.Aa]: '',
  [LanguageId.Ab]: '',
  [LanguageId.Ae]: '',
  [LanguageId.Af]: '',
  [LanguageId.Ak]: '',
  [LanguageId.Am]: '',
  [LanguageId.An]: '',
  [LanguageId.As]: '',
  [LanguageId.Av]: '',
  [LanguageId.Ay]: '',
  [LanguageId.Az]: '',
  [LanguageId.Ba]: '',
  [LanguageId.Be]: '',
  [LanguageId.Bg]: '',
  [LanguageId.Bh]: '',
  [LanguageId.Bi]: '',
  [LanguageId.Bm]: '',
  [LanguageId.Bn]: '',
  [LanguageId.Bo]: '',
  [LanguageId.Br]: '',
  [LanguageId.Bs]: '',
  [LanguageId.Ca]: '',
  [LanguageId.Ce]: '',
  [LanguageId.Ch]: '',
  [LanguageId.Co]: '',
  [LanguageId.Cr]: '',
  [LanguageId.Cs]: '',
  [LanguageId.Cu]: '',
  [LanguageId.Cv]: '',
  [LanguageId.Cy]: '',
  [LanguageId.Da]: '',
  [LanguageId.Dv]: '',
  [LanguageId.Dz]: '',
  [LanguageId.Ee]: '',
  [LanguageId.El]: '',
  [LanguageId.Eo]: '',
  [LanguageId.Et]: '',
  [LanguageId.Eu]: '',
  [LanguageId.Fa]: '',
  [LanguageId.Ff]: '',
  [LanguageId.Fi]: '',
  [LanguageId.Fj]: '',
  [LanguageId.Fo]: '',
  [LanguageId.Fy]: '',
  [LanguageId.Ga]: '',
  [LanguageId.Gd]: '',
  [LanguageId.Gl]: '',
  [LanguageId.Gn]: '',
  [LanguageId.Gu]: '',
  [LanguageId.Gv]: '',
  [LanguageId.Ha]: '',
  [LanguageId.Ho]: '',
  [LanguageId.Hr]: '',
  [LanguageId.Ht]: '',
  [LanguageId.Hu]: '',
  [LanguageId.Hy]: '',
  [LanguageId.Hz]: '',
  [LanguageId.Ia]: '',
  [LanguageId.Id]: '',
  [LanguageId.Ie]: '',
  [LanguageId.Ig]: '',
  [LanguageId.Ii]: '',
  [LanguageId.Ik]: '',
  [LanguageId.Io]: '',
  [LanguageId.Is]: '',
  [LanguageId.Iu]: '',
  [LanguageId.Ja]: '',
  [LanguageId.Jv]: '',
  [LanguageId.Ka]: '',
  [LanguageId.Kg]: '',
  [LanguageId.Ki]: '',
  [LanguageId.Kj]: '',
  [LanguageId.Kk]: '',
  [LanguageId.Kl]: '',
  [LanguageId.Km]: '',
  [LanguageId.Kn]: '',
  [LanguageId.Kr]: '',
  [LanguageId.Ks]: '',
  [LanguageId.Ku]: '',
  [LanguageId.Kv]: '',
  [LanguageId.Kw]: '',
  [LanguageId.Ky]: '',
  [LanguageId.La]: '',
  [LanguageId.Lb]: '',
  [LanguageId.Lg]: '',
  [LanguageId.Li]: '',
  [LanguageId.Ln]: '',
  [LanguageId.Lo]: '',
  [LanguageId.Lt]: '',
  [LanguageId.Lu]: '',
  [LanguageId.Lv]: '',
  [LanguageId.Mg]: '',
  [LanguageId.Mh]: '',
  [LanguageId.Mi]: '',
  [LanguageId.Mk]: '',
  [LanguageId.Ml]: '',
  [LanguageId.Mn]: '',
  [LanguageId.Mr]: '',
  [LanguageId.Ms]: '',
  [LanguageId.Mt]: '',
  [LanguageId.My]: '',
  [LanguageId.Na]: '',
  [LanguageId.Nb]: '',
  [LanguageId.Nd]: '',
  [LanguageId.Ne]: '',
  [LanguageId.Ng]: '',
  [LanguageId.Nl]: '',
  [LanguageId.Nn]: '',
  [LanguageId.No]: '',
  [LanguageId.Nr]: '',
  [LanguageId.Nv]: '',
  [LanguageId.Ny]: '',
  [LanguageId.Oc]: '',
  [LanguageId.Oj]: '',
  [LanguageId.Om]: '',
  [LanguageId.Or]: '',
  [LanguageId.Os]: '',
  [LanguageId.Pa]: '',
  [LanguageId.Pi]: '',
  [LanguageId.Pl]: '',
  [LanguageId.Ps]: '',
  [LanguageId.Qu]: '',
  [LanguageId.Rm]: '',
  [LanguageId.Rn]: '',
  [LanguageId.Ro]: '',
  [LanguageId.Ru]: '',
  [LanguageId.Rw]: '',
  [LanguageId.Sa]: '',
  [LanguageId.Sc]: '',
  [LanguageId.Sd]: '',
  [LanguageId.Se]: '',
  [LanguageId.Sg]: '',
  [LanguageId.Si]: '',
  [LanguageId.Sk]: '',
  [LanguageId.Sl]: '',
  [LanguageId.Sm]: '',
  [LanguageId.Sn]: '',
  [LanguageId.So]: '',
  [LanguageId.Sq]: '',
  [LanguageId.Sr]: '',
  [LanguageId.Ss]: '',
  [LanguageId.St]: '',
  [LanguageId.Su]: '',
  [LanguageId.Sv]: '',
  [LanguageId.Sw]: '',
  [LanguageId.Ta]: '',
  [LanguageId.Te]: '',
  [LanguageId.Tg]: '',
  [LanguageId.Th]: '',
  [LanguageId.Ti]: '',
  [LanguageId.Tk]: '',
  [LanguageId.Tl]: '',
  [LanguageId.Tn]: '',
  [LanguageId.To]: '',
  [LanguageId.Tr]: '',
  [LanguageId.Ts]: '',
  [LanguageId.Tt]: '',
  [LanguageId.Tw]: '',
  [LanguageId.Ty]: '',
  [LanguageId.Ug]: '',
  [LanguageId.Uk]: '',
  [LanguageId.Ur]: '',
  [LanguageId.Uz]: '',
  [LanguageId.Ve]: '',
  [LanguageId.Vi]: '',
  [LanguageId.Vo]: '',
  [LanguageId.Wa]: '',
  [LanguageId.Wo]: '',
  [LanguageId.Xh]: '',
  [LanguageId.Yi]: '',
  [LanguageId.Yo]: '',
  [LanguageId.Za]: '',
  [LanguageId.Zu]: '',
};

export const isValidLanguage = (language: string): language is Language => {
  return (
    language === 'spanish' ||
    language === 'french' ||
    language === 'german' ||
    language === 'portuguese' ||
    language === 'italian' ||
    language === 'korean' ||
    language === 'japanese' ||
    language === 'chinese' ||
    language === 'arabic' ||
    language === 'hebrew' ||
    language === 'hindi'
  );
};

export type LanguageInfoInDev = Omit<LanguageInformation, 'key'>;

export interface LanguageMap {
  [key: string]: Omit<LanguageInfoInDev, 'key'>;
}

export const UNSUPPORTED_LANGUAGES: LanguageMap = {
  aa: {
    languageId: LanguageId.Aa,
    language: 'Afar',
  },
  ab: {
    languageId: LanguageId.Ab,
    language: 'Abkhazian',
  },
  ae: {
    languageId: LanguageId.Ae,
    language: 'Avestan',
  },
  af: {
    languageId: LanguageId.Af,
    language: 'Afrikaans',
  },
  ak: {
    languageId: LanguageId.Ak,
    language: 'Akan',
  },
  am: {
    languageId: LanguageId.Am,
    language: 'Amharic',
  },
  an: {
    languageId: LanguageId.An,
    language: 'Aragonese',
  },
  as: {
    languageId: LanguageId.As,
    language: 'Assamese',
  },
  av: {
    languageId: LanguageId.Av,
    language: 'Avaric',
  },
  ay: {
    languageId: LanguageId.Ay,
    language: 'Aymara',
  },
  az: {
    languageId: LanguageId.Az,
    language: 'Azerbaijani',
  },
  ba: {
    languageId: LanguageId.Ba,
    language: 'Bashkir',
  },
  be: {
    languageId: LanguageId.Be,
    language: 'Belarusian',
  },
  bg: {
    languageId: LanguageId.Bg,
    language: 'Bulgarian',
  },
  bh: {
    languageId: LanguageId.Bh,
    language: 'Bihari languages',
  },
  bi: {
    languageId: LanguageId.Bi,
    language: 'Bislama',
  },
  bm: {
    languageId: LanguageId.Bm,
    language: 'Bambara',
  },
  bn: {
    languageId: LanguageId.Bn,
    language: 'Bengali',
  },
  bo: {
    languageId: LanguageId.Bo,
    language: 'Tibetan',
  },
  br: {
    languageId: LanguageId.Br,
    language: 'Breton',
  },
  bs: {
    languageId: LanguageId.Bs,
    language: 'Bosnian',
  },
  ca: {
    languageId: LanguageId.Ca,
    language: 'Catalan; Valencian',
  },
  ce: {
    languageId: LanguageId.Ce,
    language: 'Chechen',
  },
  ch: {
    languageId: LanguageId.Ch,
    language: 'Chamorro',
  },
  co: {
    languageId: LanguageId.Co,
    language: 'Corsican',
  },
  cr: {
    languageId: LanguageId.Cr,
    language: 'Cree',
  },
  cs: {
    languageId: LanguageId.Cs,
    language: 'Czech',
  },
  cu: {
    languageId: LanguageId.Cu,
    language: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  },
  cv: {
    languageId: LanguageId.Cv,
    language: 'Chuvash',
  },
  cy: {
    languageId: LanguageId.Cy,
    language: 'Welsh',
  },
  da: {
    languageId: LanguageId.Da,
    language: 'Danish',
  },
  dv: {
    languageId: LanguageId.Dv,
    language: 'Divehi; Dhivehi; Maldivian',
  },
  dz: {
    languageId: LanguageId.Dz,
    language: 'Dzongkha',
  },
  ee: {
    languageId: LanguageId.Ee,
    language: 'Ewe',
  },
  eo: {
    languageId: LanguageId.Eo,
    language: 'Esperanto',
  },
  et: {
    languageId: LanguageId.Et,
    language: 'Estonian',
  },
  eu: {
    languageId: LanguageId.Eu,
    language: 'Basque',
  },
  ff: {
    languageId: LanguageId.Ff,
    language: 'Fulah',
  },
  fi: {
    languageId: LanguageId.Fi,
    language: 'Finnish',
  },
  fj: {
    languageId: LanguageId.Fj,
    language: 'Fijian',
  },
  fo: {
    languageId: LanguageId.Fo,
    language: 'Faroese',
  },
  fy: {
    languageId: LanguageId.Fy,
    language: 'Western Frisian',
  },
  gd: {
    languageId: LanguageId.Gd,
    language: 'Gaelic; Scottish Gaelic',
  },
  gl: {
    languageId: LanguageId.Gl,
    language: 'Galician',
  },
  gn: {
    languageId: LanguageId.Gn,
    language: 'Guarani',
  },
  gu: {
    languageId: LanguageId.Gu,
    language: 'Gujarati',
  },
  gv: {
    languageId: LanguageId.Gv,
    language: 'Manx',
  },
  ha: {
    languageId: LanguageId.Ha,
    language: 'Hausa',
  },
  ho: {
    languageId: LanguageId.Ho,
    language: 'Hiri Motu',
  },
  hr: {
    languageId: LanguageId.Hr,
    language: 'Croatian',
  },
  ht: {
    languageId: LanguageId.Ht,
    language: 'Haitian; Haitian Creole',
  },
  hu: {
    languageId: LanguageId.Hu,
    language: 'Hungarian',
  },
  hy: {
    languageId: LanguageId.Hy,
    language: 'Armenian',
  },
  hz: {
    languageId: LanguageId.Hz,
    language: 'Herero',
  },
  ia: {
    languageId: LanguageId.Ia,
    language: 'Interlingua (International Auxiliary Language Association)',
  },
  id: {
    languageId: LanguageId.Id,
    language: 'Indonesian',
  },
  ie: {
    languageId: LanguageId.Ie,
    language: 'Interlingue; Occidental',
  },
  ig: {
    languageId: LanguageId.Ig,
    language: 'Igbo',
  },
  ii: {
    languageId: LanguageId.Ii,
    language: 'Sichuan Yi; Nuosu',
  },
  ik: {
    languageId: LanguageId.Ik,
    language: 'Inupiaq',
  },
  io: {
    languageId: LanguageId.Io,
    language: 'Ido',
  },
  is: {
    languageId: LanguageId.Is,
    language: 'Icelandic',
  },
  iu: {
    languageId: LanguageId.Iu,
    language: 'Inuktitut',
  },
  jv: {
    languageId: LanguageId.Jv,
    language: 'Javanese',
  },
  ka: {
    languageId: LanguageId.Ka,
    language: 'Georgian',
  },
  kg: {
    languageId: LanguageId.Kg,
    language: 'Kongo',
  },
  ki: {
    languageId: LanguageId.Ki,
    language: 'Kikuyu; Gikuyu',
  },
  kj: {
    languageId: LanguageId.Kj,
    language: 'Kuanyama; Kwanyama',
  },
  kk: {
    languageId: LanguageId.Kk,
    language: 'Kazakh',
  },
  kl: {
    languageId: LanguageId.Kl,
    language: 'Kalaallisut; Greenlandic',
  },
  km: {
    languageId: LanguageId.Km,
    language: 'Central Khmer',
  },
  kn: {
    languageId: LanguageId.Kn,
    language: 'Kannada',
  },
  kr: {
    languageId: LanguageId.Kr,
    language: 'Kanuri',
  },
  ks: {
    languageId: LanguageId.Ks,
    language: 'Kashmiri',
  },
  ku: {
    languageId: LanguageId.Ku,
    language: 'Kurdish',
  },
  kv: {
    languageId: LanguageId.Kv,
    language: 'Komi',
  },
  kw: {
    languageId: LanguageId.Kw,
    language: 'Cornish',
  },
  ky: {
    languageId: LanguageId.Ky,
    language: 'Kirghiz; Kyrgyz',
  },
  la: {
    languageId: LanguageId.La,
    language: 'Latin',
  },
  lb: {
    languageId: LanguageId.Lb,
    language: 'Luxembourgish; Letzeburgesch',
  },
  lg: {
    languageId: LanguageId.Lg,
    language: 'Ganda',
  },
  li: {
    languageId: LanguageId.Li,
    language: 'Limburgan; Limburger; Limburgish',
  },
  ln: {
    languageId: LanguageId.Ln,
    language: 'Lingala',
  },
  lo: {
    languageId: LanguageId.Lo,
    language: 'Lao',
  },
  lt: {
    languageId: LanguageId.Lt,
    language: 'Lithuanian',
  },
  lu: {
    languageId: LanguageId.Lu,
    language: 'Luba-Katanga',
  },
  lv: {
    languageId: LanguageId.Lv,
    language: 'Latvian',
  },
  mg: {
    languageId: LanguageId.Mg,
    language: 'Malagasy',
  },
  mh: {
    languageId: LanguageId.Mh,
    language: 'Marshallese',
  },
  mi: {
    languageId: LanguageId.Mi,
    language: 'Maori',
  },
  mk: {
    languageId: LanguageId.Mk,
    language: 'Macedonian',
  },
  ml: {
    languageId: LanguageId.Ml,
    language: 'Malayalam',
  },
  mn: {
    languageId: LanguageId.Mn,
    language: 'Mongolian',
  },
  mr: {
    languageId: LanguageId.Mr,
    language: 'Marathi',
  },
  ms: {
    languageId: LanguageId.Ms,
    language: 'Malay',
  },
  mt: {
    languageId: LanguageId.Mt,
    language: 'Maltese',
  },
  my: {
    languageId: LanguageId.My,
    language: 'Burmese',
  },
  na: {
    languageId: LanguageId.Na,
    language: 'Nauru',
  },
  nb: {
    languageId: LanguageId.Nb,
    language: 'Bokmål, Norwegian; Norwegian Bokmål',
  },
  nd: {
    languageId: LanguageId.Nd,
    language: 'Ndebele, North; North Ndebele',
  },
  ne: {
    languageId: LanguageId.Ne,
    language: 'Nepali',
  },
  ng: {
    languageId: LanguageId.Ng,
    language: 'Ndonga',
  },
  nn: {
    languageId: LanguageId.Nn,
    language: 'Norwegian Nynorsk; Nynorsk, Norwegian',
  },
  no: {
    languageId: LanguageId.No,
    language: 'Norwegian',
  },
  nr: {
    languageId: LanguageId.Nr,
    language: 'Ndebele, South; South Ndebele',
  },
  nv: {
    languageId: LanguageId.Nv,
    language: 'Navajo; Navaho',
  },
  ny: {
    languageId: LanguageId.Ny,
    language: 'Chichewa; Chewa; Nyanja',
  },
  oc: {
    languageId: LanguageId.Oc,
    language: 'Occitan (post 1500)',
  },
  oj: {
    languageId: LanguageId.Oj,
    language: 'Ojibwa',
  },
  om: {
    languageId: LanguageId.Om,
    language: 'Oromo',
  },
  or: {
    languageId: LanguageId.Or,
    language: 'Oriya',
  },
  os: {
    languageId: LanguageId.Os,
    language: 'Ossetian; Ossetic',
  },
  pa: {
    languageId: LanguageId.Pa,
    language: 'Panjabi; Punjabi',
  },
  pi: {
    languageId: LanguageId.Pi,
    language: 'Pali',
  },
  ps: {
    languageId: LanguageId.Ps,
    language: 'Pushto; Pashto',
  },
  qu: {
    languageId: LanguageId.Qu,
    language: 'Quechua',
  },
  rm: {
    languageId: LanguageId.Rm,
    language: 'Romansh',
  },
  rn: {
    languageId: LanguageId.Rn,
    language: 'Rundi',
  },
  ro: {
    languageId: LanguageId.Ro,
    language: 'Romanian; Moldavian; Moldovan',
  },
  rw: {
    languageId: LanguageId.Rw,
    language: 'Kinyarwanda',
  },
  sa: {
    languageId: LanguageId.Sa,
    language: 'Sanskrit',
  },
  sc: {
    languageId: LanguageId.Sc,
    language: 'Sardinian',
  },
  sd: {
    languageId: LanguageId.Sd,
    language: 'Sindhi',
  },
  se: {
    languageId: LanguageId.Se,
    language: 'Northern Sami',
  },
  sg: {
    languageId: LanguageId.Sg,
    language: 'Sango',
  },
  si: {
    languageId: LanguageId.Si,
    language: 'Sinhala; Sinhalese',
  },
  sk: {
    languageId: LanguageId.Sk,
    language: 'Slovak',
  },
  sl: {
    languageId: LanguageId.Sl,
    language: 'Slovenian',
  },
  sm: {
    languageId: LanguageId.Sm,
    language: 'Samoan',
  },
  sn: {
    languageId: LanguageId.Sn,
    language: 'Shona',
  },
  so: {
    languageId: LanguageId.So,
    language: 'Somali',
  },
  sq: {
    languageId: LanguageId.Sq,
    language: 'Albanian',
  },
  sr: {
    languageId: LanguageId.Sr,
    language: 'Serbian',
  },
  ss: {
    languageId: LanguageId.Ss,
    language: 'Swati',
  },
  st: {
    languageId: LanguageId.St,
    language: 'Sotho, Southern',
  },
  su: {
    languageId: LanguageId.Su,
    language: 'Sundanese',
  },
  sw: {
    languageId: LanguageId.Sw,
    language: 'Swahili',
  },
  ta: {
    languageId: LanguageId.Ta,
    language: 'Tamil',
  },
  te: {
    languageId: LanguageId.Te,
    language: 'Telugu',
  },
  tg: {
    languageId: LanguageId.Tg,
    language: 'Tajik',
  },
  th: {
    languageId: LanguageId.Th,
    language: 'Thai',
  },
  ti: {
    languageId: LanguageId.Ti,
    language: 'Tigrinya',
  },
  tk: {
    languageId: LanguageId.Tk,
    language: 'Turkmen',
  },
  tl: {
    languageId: LanguageId.Tl,
    language: 'Tagalog',
  },
  tn: {
    languageId: LanguageId.Tn,
    language: 'Tswana',
  },
  to: {
    languageId: LanguageId.To,
    language: 'Tonga (Tonga Islands)',
  },
  ts: {
    languageId: LanguageId.Ts,
    language: 'Tsonga',
  },
  tt: {
    languageId: LanguageId.Tt,
    language: 'Tatar',
  },
  tw: {
    languageId: LanguageId.Tw,
    language: 'Twi',
  },
  ty: {
    languageId: LanguageId.Ty,
    language: 'Tahitian',
  },
  ug: {
    languageId: LanguageId.Ug,
    language: 'Uighur; Uyghur',
  },
  uk: {
    languageId: LanguageId.Uk,
    language: 'Ukrainian',
  },
  ur: {
    languageId: LanguageId.Ur,
    language: 'Urdu',
  },
  uz: {
    languageId: LanguageId.Uz,
    language: 'Uzbek',
  },
  ve: {
    languageId: LanguageId.Ve,
    language: 'Venda',
  },
  vo: {
    languageId: LanguageId.Vo,
    language: 'Volapük',
  },
  wa: {
    languageId: LanguageId.Wa,
    language: 'Walloon',
  },
  wo: {
    languageId: LanguageId.Wo,
    language: 'Wolof',
  },
  xh: {
    languageId: LanguageId.Xh,
    language: 'Xhosa',
  },
  yi: {
    languageId: LanguageId.Yi,
    language: 'Yiddish',
  },
  yo: {
    languageId: LanguageId.Yo,
    language: 'Yoruba',
  },
  za: {
    languageId: LanguageId.Za,
    language: 'Zhuang; Chuang',
  },
};
