import { TFunction } from 'next-i18next';

import { env, Environment } from '~/lib/config';

export enum PlanType {
  Annual = 'Annual',
  AnnualTenPercentDiscount = 'AnnualTenPercentDiscount',
  Monthly = 'Monthly',
  NewYearsPromoAnnual = 'NewYearsPromoAnnual',
  TwoYear = 'TwoYear',
  SixMonth = 'SixMonth',
}

export enum CurrencyCode {
  USD = 'USD',
  INR = 'INR',
  MXN = 'MXN',
  BRL = 'BRL',
  GBP = 'GBP',
  JPY = 'JPY',
  CNY = 'CNY',
  PLN = 'PLN',
  TWD = 'TWD',
  EUR = 'EUR',
  TRY = 'TRY',
  COP = 'COP',
  RUB = 'RUB',
  AUD = 'AUD',
  VND = 'VND',
  PEN = 'PEN',
  IDR = 'IDR',
  PHP = 'PHP',
  MYR = 'MYR',
}

export enum CountryCode {
  US = 'US',
  IN = 'IN',
  MX = 'MX',
  BR = 'BR',
  GB = 'GB',
  JP = 'JP',
  CN = 'CN',
  PL = 'PL',
  TW = 'TW',
  NL = 'NL',
  TR = 'TR',
  FR = 'FR',
  DE = 'DE',
  CO = 'CO',
  RU = 'RU',
  AU = 'AU',
  ES = 'ES',
  VN = 'VN',
  PE = 'PE',
  ID = 'ID',
  PH = 'PH',
  MY = 'MY',
}

export enum PlanInterval {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Biennial = '2 years',
  SixMonth = '6 months',
}

export interface PremiumPlan {
  nameKey: string;
  price: number;
  discount?: {
    percentage: number;
    priceBefore: number;
  };
  country: CountryCode;
  currencyCode: CurrencyCode;
  interval: PlanInterval;
  stripePriceId: string;
  paypalPlanIds: {
    withFreeTrial?: string;
    noFreeTrial?: string;
  };
}

export interface PremiumPricing {
  plans?: Record<string, Partial<Record<PlanType, PremiumPlan>>>;
}

const defaultPremiumPricing: Record<CountryCode, PremiumPricing> = {
  [CountryCode.US]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 12.99,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Doulingo-Test',
          paypalPlanIds: {
            withFreeTrial: 'P-8VN94518RR010292NMIMB3ZQ',
            noFreeTrial: 'P-1EJ38441MM013650DMIMB3QY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 83.88,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Duolingo-Test',
          paypalPlanIds: {
            withFreeTrial: 'P-6KE52471TH498784LMLRR26I',
            noFreeTrial: 'P-09H51819P18711407MIMCACI',
          },
        },
        [PlanType.AnnualTenPercentDiscount]: {
          nameKey: 'annual-ten-percent-discount',
          price: 107.88,
          discount: {
            percentage: 0.1,
            priceBefore: 119.88,
          },
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-ten-percent-discount',
          paypalPlanIds: {
            withFreeTrial: 'P-33R120005M433353TMPMVXVI',
          },
        },
        [PlanType.NewYearsPromoAnnual]: {
          nameKey: 'new-years-22-promo',
          price: 89.91,
          discount: {
            percentage: 0.25,
            priceBefore: 119.97,
          },
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'price_1MF1pRCVKuVKIa5dRVLrXRlP',
          paypalPlanIds: {
            withFreeTrial: 'P-77C51656KW710154AMONWHTI',
          },
        },
        [PlanType.TwoYear]: {
          nameKey: '2-year-test',
          // NOTE: The actual price over two years is 167.76, but it is 83.88 per year.
          price: 83.88,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Biennial,
          stripePriceId: '2-year-test',
          paypalPlanIds: {
            withFreeTrial: 'P-5FL89021ER896382GMONXMVQ',
          },
        },
        [PlanType.SixMonth]: {
          nameKey: 'six-month',
          price: 65.95,
          // $10.99 per month
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.SixMonth,
          stripePriceId: 'six-month-test',
          paypalPlanIds: {
            withFreeTrial: 'P-9MC83975YW670942NMOZUDQA',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 12.99,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'monthly-duolingo',
          paypalPlanIds: {
            withFreeTrial: 'P-6G774190LM942880EMIGY66Q',
            noFreeTrial: 'P-2SV21397VP816292AMIGY7MA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 83.88,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'annual-duolingo',
          paypalPlanIds: {
            withFreeTrial: 'P-91F61061HA427730TMLQ4Z4Q',
            noFreeTrial: 'P-7XK50539T01611637MIGY2HQ',
          },
        },
        [PlanType.AnnualTenPercentDiscount]: {
          nameKey: 'annual-ten-percent-discount',
          price: 107.88,
          discount: {
            percentage: 0.1,
            priceBefore: 119.88,
          },
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-ten-percent-discount',
          paypalPlanIds: {
            withFreeTrial: 'P-6D997432GB671871DMPMV4JY',
          },
        },
        [PlanType.NewYearsPromoAnnual]: {
          nameKey: 'new-years-22-promo',
          price: 89.91,
          discount: {
            percentage: 0.25,
            priceBefore: 119.97,
          },
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'new-years-22-promo',
          paypalPlanIds: {
            withFreeTrial: 'P-92A88016P6285931AMONWHGY',
          },
        },
        [PlanType.TwoYear]: {
          nameKey: '2-year-test',
          // NOTE: The actual price over two years is 167.76, but it is 83.88 per year.
          price: 83.88,
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.Biennial,
          stripePriceId: '2-year-test',
          paypalPlanIds: {
            withFreeTrial: 'P-42S94925VP9660128MONXN4I',
          },
        },
        [PlanType.SixMonth]: {
          nameKey: 'six-month',
          price: 65.95,
          // $10.99 per month
          country: CountryCode.US,
          currencyCode: CurrencyCode.USD,
          interval: PlanInterval.SixMonth,
          stripePriceId: '6-month-test',
          paypalPlanIds: {
            withFreeTrial: 'P-4NU91862JD162204MMOZUA4Q',
          },
        },
      },
    },
  },
  [CountryCode.IN]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 399,
          country: CountryCode.IN,
          currencyCode: CurrencyCode.INR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-India',
          paypalPlanIds: {
            withFreeTrial: 'P-30B61066CD5056121MFXXTBY',
            noFreeTrial: 'P-1YC840478B5567726MFXXTCA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2628,
          country: CountryCode.IN,
          currencyCode: CurrencyCode.INR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-India',
          paypalPlanIds: {
            withFreeTrial: 'P-0VY7381811456200UMLRRKSI',
            noFreeTrial: 'P-5TN15561FE8864234MFXXTBY',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 399,
          country: CountryCode.IN,
          currencyCode: CurrencyCode.INR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-INR',
          paypalPlanIds: {
            withFreeTrial: 'P-6DV3757193331291MMFXXWUI',
            noFreeTrial: 'P-7E417251TU083835GMFXXWUI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2628,
          country: CountryCode.IN,
          currencyCode: CurrencyCode.INR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-INR',
          paypalPlanIds: {
            withFreeTrial: 'P-8RT79003WE212305TMFXT55Y',
            noFreeTrial: 'P-95S11460WF428621XMFXXWUI',
          },
        },
      },
    },
  },
  [CountryCode.MX]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 89,
          country: CountryCode.MX,
          currencyCode: CurrencyCode.MXN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Mexico',
          paypalPlanIds: {
            withFreeTrial: 'P-04R54541KP965593AMFXXTCA',
            noFreeTrial: 'P-57T43677FT8992742MFXXTCI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 588,
          country: CountryCode.MX,
          currencyCode: CurrencyCode.MXN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Mexico',
          paypalPlanIds: {
            withFreeTrial: 'P-81U7757764550210EMLRRLKI',
            noFreeTrial: 'P-336415257L275711PMFXXTCI',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 89,
          country: CountryCode.MX,
          currencyCode: CurrencyCode.MXN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-MXN',
          paypalPlanIds: {
            withFreeTrial: 'P-4ME364137E656404KMFXXWUQ',
            noFreeTrial: 'P-14A52756ES4357452MFXXWUQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 588,
          country: CountryCode.MX,
          currencyCode: CurrencyCode.MXN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-MXN',
          paypalPlanIds: {
            withFreeTrial: 'P-1M714762M4118701FMLROUAI',
            noFreeTrial: 'P-63736403FL022725KMFXXWUQ',
          },
        },
      },
    },
  },
  [CountryCode.BR]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 25.99,
          country: CountryCode.BR,
          currencyCode: CurrencyCode.BRL,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Brazil',
          paypalPlanIds: {
            withFreeTrial: 'P-76S6008297818032KMF5SGVI',
            noFreeTrial: 'P-9UG4064585913923JMF5SGVQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 179.88,
          country: CountryCode.BR,
          currencyCode: CurrencyCode.BRL,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Brazil',
          paypalPlanIds: {
            withFreeTrial: 'P-0R622219PS420092NMLRRORA',
            noFreeTrial: 'P-8U206067AC8086818MF5SGVQ',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 25.99,
          country: CountryCode.BR,
          currencyCode: CurrencyCode.BRL,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-BRL',
          paypalPlanIds: {
            withFreeTrial: 'P-63896930N2949012JMF5SUNQ',
            noFreeTrial: 'P-5KG331175A843681DMF5SUNY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 179.88,
          country: CountryCode.BR,
          currencyCode: CurrencyCode.BRL,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-BRL',
          paypalPlanIds: {
            withFreeTrial: 'P-88819677JW8776930MLROTOA',
            noFreeTrial: 'P-07Y042403N975584MMF5SUNQ',
          },
        },
      },
    },
  },
  [CountryCode.GB]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 6.49,
          country: CountryCode.GB,
          currencyCode: CurrencyCode.GBP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-United-Kingdom',
          paypalPlanIds: {
            withFreeTrial: 'P-4D244095UR520301TMF5SGVY',
            noFreeTrial: 'P-5SP9205117054571KMF5SGVY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 43.08,
          country: CountryCode.GB,
          currencyCode: CurrencyCode.GBP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-United-Kingdom',
          paypalPlanIds: {
            withFreeTrial: 'P-1EK44033Y6193293BMLRRO7Q',
            noFreeTrial: 'P-1FS29022414903406MF5SGVY',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 6.49,
          country: CountryCode.GB,
          currencyCode: CurrencyCode.GBP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-GBP',
          paypalPlanIds: {
            withFreeTrial: 'P-34F07928JP681200TMF5SUNY',
            noFreeTrial: 'P-1TE72286XN8404309MF5SUOA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 43.08,
          country: CountryCode.GB,
          currencyCode: CurrencyCode.GBP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-GBP',
          paypalPlanIds: {
            withFreeTrial: 'P-2JC52698Y2818104GMLROSUY',
            noFreeTrial: 'P-74X310721B424593MMF5SUNY',
          },
        },
      },
    },
  },
  [CountryCode.JP]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 990,
          country: CountryCode.JP,
          currencyCode: CurrencyCode.JPY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Japan',
          paypalPlanIds: {
            withFreeTrial: 'P-69P46382AL303641WMF5SGWA',
            noFreeTrial: 'P-3WJ22959M6453201SMF5SGWI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 6600,
          country: CountryCode.JP,
          currencyCode: CurrencyCode.JPY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Japan',
          paypalPlanIds: {
            withFreeTrial: 'P-14R29466UG4222616MLRRPKA',
            noFreeTrial: 'P-8E4037834E365382LMF5SGWA',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 990,
          country: CountryCode.JP,
          currencyCode: CurrencyCode.JPY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-JPY',
          paypalPlanIds: {
            withFreeTrial: 'P-0027438332853841WMF5SUOA',
            noFreeTrial: 'P-18H44577KD243215MMF5SUOI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 6600,
          country: CountryCode.JP,
          currencyCode: CurrencyCode.JPY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-JPY',
          paypalPlanIds: {
            withFreeTrial: 'P-48J48937VB209890KMLROR7A',
            noFreeTrial: 'P-83S20819R6892601BMF5SUOA',
          },
        },
      },
    },
  },
  [CountryCode.CN]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 12,
          country: CountryCode.CN,
          currencyCode: CurrencyCode.CNY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-China',
          paypalPlanIds: {
            withFreeTrial: 'P-6NS4403739636253KMGCX5RI',
            noFreeTrial: 'P-48781133B76094521MGCX5RI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 84,
          country: CountryCode.CN,
          currencyCode: CurrencyCode.CNY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-China',
          paypalPlanIds: {
            withFreeTrial: 'P-9DB16408L0466711XMLRR2SY',
            noFreeTrial: 'P-2X122654N26973143MGCX5RI',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 12,
          country: CountryCode.CN,
          currencyCode: CurrencyCode.CNY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-CNY',
          paypalPlanIds: {
            withFreeTrial: 'P-6HG73569FS564290WMGCX6CA',
            noFreeTrial: 'P-64L96679UJ7256729MGCX6CI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 84,
          country: CountryCode.CN,
          currencyCode: CurrencyCode.CNY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-CNY',
          paypalPlanIds: {
            withFreeTrial: 'P-3FB70708AY886983VMLRMZNY',
            noFreeTrial: 'P-7BV28844YK697700PMGCX6CI',
          },
        },
      },
    },
  },
  [CountryCode.PL]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 28,
          country: CountryCode.PL,
          currencyCode: CurrencyCode.PLN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Poland',
          paypalPlanIds: {
            withFreeTrial: 'P-8UE10578MA582064KMF5SS2A',
            noFreeTrial: 'P-0X605653SS569925YMF5SS2A',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 192,
          country: CountryCode.PL,
          currencyCode: CurrencyCode.PLN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Poland',
          paypalPlanIds: {
            withFreeTrial: 'P-15F02469SB025545WMLRRPUY',
            noFreeTrial: 'P-8EV58792MW4004253MF5SS2A',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 28,
          country: CountryCode.PL,
          currencyCode: CurrencyCode.PLN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-PLN',
          paypalPlanIds: {
            withFreeTrial: 'P-3HK55221P7738891PMF5SUOI',
            noFreeTrial: 'P-60J04185MG5411517MF5SUOI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 192,
          country: CountryCode.PL,
          currencyCode: CurrencyCode.PLN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-PLN',
          paypalPlanIds: {
            withFreeTrial: 'P-73A279780R0406844MLRORJQ',
            noFreeTrial: 'P-8UJ749000B8351236MF5SUOI',
          },
        },
      },
    },
  },
  [CountryCode.TW]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 250,
          country: CountryCode.TW,
          currencyCode: CurrencyCode.TWD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Taiwan',
          paypalPlanIds: {
            withFreeTrial: 'P-56A51922M1610470LMF5SS2I',
            noFreeTrial: 'P-7PM12603V9226143EMF5SS2Q',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 1668,
          country: CountryCode.TW,
          currencyCode: CurrencyCode.TWD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Taiwan',
          paypalPlanIds: {
            withFreeTrial: 'P-92V10377S1600790NMLRRQGI',
            noFreeTrial: 'P-8PA46925TW302754YMF5SS2I',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 250,
          country: CountryCode.TW,
          currencyCode: CurrencyCode.TWD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-TWD',
          paypalPlanIds: {
            withFreeTrial: 'P-37E7366382533384YMF5SUOQ',
            noFreeTrial: 'P-9J7115038R2767943MF5SUOQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 1668,
          country: CountryCode.TW,
          currencyCode: CurrencyCode.TWD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-TWD',
          paypalPlanIds: {
            withFreeTrial: 'P-7AL758165C066120HMLROQWQ',
            noFreeTrial: 'P-1BA16738137264840MF5SUOQ',
          },
        },
      },
    },
  },
  [CountryCode.NL]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.NL,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Netherlands',
          paypalPlanIds: {
            withFreeTrial: 'P-81J63955G84828448MF5SS2Q',
            noFreeTrial: 'P-03696934M5091062NMF5SS2Y',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.NL,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Netherlands',
          paypalPlanIds: {
            withFreeTrial: 'P-5V0412104B368793MMLRRRMQ',
            noFreeTrial: 'P-80739177MF0670941MF5SS2Y',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.NL,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-2GJ27821M8746784JMF5SUOY',
            noFreeTrial: 'P-91M00899YE191022BMF5SUOY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.NL,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-16R59575GR2224110MLROP4Q',
            noFreeTrial: 'P-9XT70642814848036MF5SUOY',
          },
        },
      },
    },
  },
  [CountryCode.TR]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 27,
          country: CountryCode.TR,
          currencyCode: CurrencyCode.TRY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Turkey',
          paypalPlanIds: {
            withFreeTrial: 'P-56F68257545333546MF5SS3A',
            noFreeTrial: 'P-1C175183PU8347617MF5SS3A',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 191.88,
          country: CountryCode.TR,
          currencyCode: CurrencyCode.TRY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Turkey',
          paypalPlanIds: {
            withFreeTrial: 'P-5J934077CD7512318MLRRRXA',
            noFreeTrial: 'P-3AS555626L2108546MF5SS3A',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 27,
          country: CountryCode.TR,
          currencyCode: CurrencyCode.TRY,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-TRY',
          paypalPlanIds: {
            withFreeTrial: 'P-46580008KH982712UMF5SUPA',
            noFreeTrial: 'P-2YF910013V8011419MF5SUPA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 191.88,
          country: CountryCode.TR,
          currencyCode: CurrencyCode.TRY,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-TRY',
          paypalPlanIds: {
            withFreeTrial: 'P-3MT13826U7986014YMLROPMI',
            noFreeTrial: 'P-9P935794LE873461FMF5SUPA',
          },
        },
      },
    },
  },
  [CountryCode.FR]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.FR,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-France',
          paypalPlanIds: {
            withFreeTrial: 'P-3UB294400L372403BMF5SS3I',
            noFreeTrial: 'P-6S319288TA755773MMF5SS3I',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.FR,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-France',
          paypalPlanIds: {
            withFreeTrial: 'P-78672232VJ648060TMLRRSWA',
            noFreeTrial: 'P-58N1857237565951HMF5SS3I',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.FR,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-8BX250760C3959102MF5SUPI',
            noFreeTrial: 'P-3V3741635H073113GMF5SUPI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.FR,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-4J028186A6390052WMLROOVY',
            noFreeTrial: 'P-3NT753820S104925MMF5SUPI',
          },
        },
      },
    },
  },
  [CountryCode.DE]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.DE,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Germany',
          paypalPlanIds: {
            withFreeTrial: 'P-5YP267060F7901930MF5SS3Q',
            noFreeTrial: 'P-8785346679385021WMF5SS3Y',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.DE,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Germany',
          paypalPlanIds: {
            withFreeTrial: 'P-2G075780R46318648MLRRUIQ',
            noFreeTrial: 'P-6D265359NH456582TMF5SS3Q',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.DE,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-5V0770956X568064SMF5SUPQ',
            noFreeTrial: 'P-17G84959LM883791KMF5SUPQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.DE,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-1NK97633N3311762JMLROOHY',
            noFreeTrial: 'P-2FU01728RH6156447MF5SUPQ',
          },
        },
      },
    },
  },
  [CountryCode.CO]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 19900,
          country: CountryCode.CO,
          currencyCode: CurrencyCode.COP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Colombia',
          paypalPlanIds: {
            withFreeTrial: 'P-25145149NH4046326MF5SS3Y',
            noFreeTrial: 'P-9CM46107MD620083AMF5SS4A',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 130800,
          country: CountryCode.CO,
          currencyCode: CurrencyCode.COP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Colombia',
          paypalPlanIds: {
            withFreeTrial: 'P-3AU29037DA9986730MLRRVDY',
            noFreeTrial: 'P-36730098F98327411MF5SS4A',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 19900,
          country: CountryCode.CO,
          currencyCode: CurrencyCode.COP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-COP',
          paypalPlanIds: {
            withFreeTrial: 'P-0JH656797B581111HMF5SUPY',
            noFreeTrial: 'P-5YV45984YD7348407MF5SUPY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 130800,
          country: CountryCode.CO,
          currencyCode: CurrencyCode.COP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-COP',
          paypalPlanIds: {
            withFreeTrial: 'P-1T049231MM687280FMLRONXI',
            noFreeTrial: 'P-5TG90312R6374135KMF5SUPY',
          },
        },
      },
    },
  },
  [CountryCode.RU]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 399,
          country: CountryCode.RU,
          currencyCode: CurrencyCode.RUB,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Russia',
          paypalPlanIds: {
            withFreeTrial: 'P-5AP98566KY955641SMF5SS4A',
            noFreeTrial: 'P-23P89326R8013533TMF5SS4I',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2628,
          country: CountryCode.RU,
          currencyCode: CurrencyCode.RUB,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Russia',
          paypalPlanIds: {
            withFreeTrial: 'P-02D49732GW7092149MLRRW2Y',
            noFreeTrial: 'P-8KY33222DR442004BMF5SS4I',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 399,
          country: CountryCode.RU,
          currencyCode: CurrencyCode.RUB,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-RUB',
          paypalPlanIds: {
            withFreeTrial: 'P-3A032776MW7697538MF5SUPY',
            noFreeTrial: 'P-85E61551KD709911DMF5SUQA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2628,
          country: CountryCode.RU,
          currencyCode: CurrencyCode.RUB,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-RUB',
          paypalPlanIds: {
            withFreeTrial: 'P-3E5764727X265663HMLRONAY',
            noFreeTrial: 'P-4LG70065DB9108150MF5SUQA',
          },
        },
      },
    },
  },
  [CountryCode.AU]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 10.99,
          country: CountryCode.AU,
          currencyCode: CurrencyCode.AUD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Australia',
          paypalPlanIds: {
            withFreeTrial: 'P-39M11226RR138254UMF5SS4Q',
            noFreeTrial: 'P-7B163353UV7579438MF5SS4Y',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 71.88,
          country: CountryCode.AU,
          currencyCode: CurrencyCode.AUD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Australia',
          paypalPlanIds: {
            withFreeTrial: 'P-7LP283410G288502BMLRRXGI',
            noFreeTrial: 'P-8FP52173N0460542HMF5SS4Q',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 10.99,
          country: CountryCode.AU,
          currencyCode: CurrencyCode.AUD,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-AUD',
          paypalPlanIds: {
            withFreeTrial: 'P-11383485R1988900PMF5SUQI',
            noFreeTrial: 'P-2B144305X1204512YMF5SUQI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 71.88,
          country: CountryCode.AU,
          currencyCode: CurrencyCode.AUD,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-AUD',
          paypalPlanIds: {
            withFreeTrial: 'P-6LU97654JX0244217MLROMDI',
            noFreeTrial: 'P-09E521068N728311VMF5SUQI',
          },
        },
      },
    },
  },
  [CountryCode.ES]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.ES,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Spain',
          paypalPlanIds: {
            withFreeTrial: 'P-19S3569586696543FMF5SS4Y',
            noFreeTrial: 'P-6TM318062Y782001NMF5SS5A',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.ES,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Spain',
          paypalPlanIds: {
            withFreeTrial: 'P-67L98309144781523MLRRXTY',
            noFreeTrial: 'P-7J488186AL627843KMF5SS4Y',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 7.99,
          country: CountryCode.ES,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-41804875LS4307453MF5SUQQ',
            noFreeTrial: 'P-2SN13986K2418703RMF5SUQQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 47.88,
          country: CountryCode.ES,
          currencyCode: CurrencyCode.EUR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-EUR',
          paypalPlanIds: {
            withFreeTrial: 'P-43582896LW7935816MLROLTA',
            noFreeTrial: 'P-2GS957977B5779823MF5SUQQ',
          },
        },
      },
    },
  },
  [CountryCode.VN]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 140000,
          country: CountryCode.VN,
          currencyCode: CurrencyCode.VND,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Vietnam',
          paypalPlanIds: {
            withFreeTrial: 'P-8G6123086L786601WMF5SS5A',
            noFreeTrial: 'P-49V06590966000304MF5SS5I',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 948000,
          country: CountryCode.VN,
          currencyCode: CurrencyCode.VND,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Vietnam',
          paypalPlanIds: {
            withFreeTrial: 'P-9T725971HU450843HMLRRY6I',
            noFreeTrial: 'P-4RB20599S9599422XMF5SS5I',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 140000,
          country: CountryCode.VN,
          currencyCode: CurrencyCode.VND,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-VND',
          paypalPlanIds: {
            withFreeTrial: 'P-2HN78269F92620628MF5SUQY',
            noFreeTrial: 'P-3VS677607U6548241MF5SUQY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 948000,
          country: CountryCode.VN,
          currencyCode: CurrencyCode.VND,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-VND',
          paypalPlanIds: {
            withFreeTrial: 'P-2LS28489RE419073SMLROLDI',
            noFreeTrial: 'P-98X49442834740355MF5SUQY',
          },
        },
      },
    },
  },
  [CountryCode.PE]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 22.9,
          country: CountryCode.PE,
          currencyCode: CurrencyCode.PEN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Peru',
          paypalPlanIds: {
            withFreeTrial: 'P-1X794725LW189991AMF5SS5I',
            noFreeTrial: 'P-25G27879HS256625SMF5SS5Q',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 154.8,
          country: CountryCode.PE,
          currencyCode: CurrencyCode.PEN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Peru',
          paypalPlanIds: {
            withFreeTrial: 'P-88179722U27726840MLRRZJQ',
            noFreeTrial: 'P-5TK13273XH7925613MF5SS5Q',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 22.9,
          country: CountryCode.PE,
          currencyCode: CurrencyCode.PEN,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-PEN',
          paypalPlanIds: {
            withFreeTrial: 'P-4UA97515JN460772AMF5SUQY',
            noFreeTrial: 'P-6PX78268TE781382SMF5SURA',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 154.8,
          country: CountryCode.PE,
          currencyCode: CurrencyCode.PEN,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-PEN',
          paypalPlanIds: {
            withFreeTrial: 'P-8Y4108485R103350KMLROKPI',
            noFreeTrial: 'P-10D39133HY249890WMF5SURA',
          },
        },
      },
    },
  },
  [CountryCode.ID]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 89000,
          country: CountryCode.ID,
          currencyCode: CurrencyCode.IDR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Indonesia',
          paypalPlanIds: {
            withFreeTrial: 'P-06N837984M554012PMF5SS5Y',
            noFreeTrial: 'P-26104082G5071603HMF5SS5Y',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 580000,
          country: CountryCode.ID,
          currencyCode: CurrencyCode.IDR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Indonesia',
          paypalPlanIds: {
            withFreeTrial: 'P-3GJ96318U78738200MLRRZWY',
            noFreeTrial: 'P-2M016915RN317222BMF5SS5Y',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 89000,
          country: CountryCode.ID,
          currencyCode: CurrencyCode.IDR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-IDR',
          paypalPlanIds: {
            withFreeTrial: 'P-8VM6951544203971BMF5SURA',
            noFreeTrial: 'P-5MS02710HH738073WMF5SURI',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 580000,
          country: CountryCode.ID,
          currencyCode: CurrencyCode.IDR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-IDR',
          paypalPlanIds: {
            withFreeTrial: 'P-1UM40782MU507530TMLRM52I',
            noFreeTrial: 'P-0XN03465N50390402MF5SURI',
          },
        },
      },
    },
  },
  [CountryCode.PH]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 299,
          country: CountryCode.PH,
          currencyCode: CurrencyCode.PHP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Philippines',
          paypalPlanIds: {
            withFreeTrial: 'P-22Y92724BH129303JMF5SS6A',
            noFreeTrial: 'P-7TX21962UV551601SMF5SS6I',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2028,
          country: CountryCode.PH,
          currencyCode: CurrencyCode.PHP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Philippines',
          paypalPlanIds: {
            withFreeTrial: 'P-3XK07655PX544904FMLRR2DA',
            noFreeTrial: 'P-28T73036BM764444CMF5SS6A',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 299,
          country: CountryCode.PH,
          currencyCode: CurrencyCode.PHP,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-PHP',
          paypalPlanIds: {
            withFreeTrial: 'P-7Y408462WU890161XMF5SURQ',
            noFreeTrial: 'P-44D65212PS635672PMF5SURQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 2028,
          country: CountryCode.PH,
          currencyCode: CurrencyCode.PHP,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-PHP',
          paypalPlanIds: {
            withFreeTrial: 'P-51E322423J7864803MLRM4IY',
            noFreeTrial: 'P-36U43973UX712552PMF5SURQ',
          },
        },
      },
    },
  },
  [CountryCode.MY]: {
    plans: {
      [Environment.Staging]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 29,
          country: CountryCode.MY,
          currencyCode: CurrencyCode.MYR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'Monthly-Malaysia',
          paypalPlanIds: {
            withFreeTrial: 'P-7KC31219J4667833DMGCX5RQ',
            noFreeTrial: 'P-7CF5546771933731VMGCX5RY',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 228,
          country: CountryCode.MY,
          currencyCode: CurrencyCode.MYR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'Annual-Malaysia',
          paypalPlanIds: {
            withFreeTrial: 'P-3CW082881K599572PMLRRKFQ',
            noFreeTrial: 'P-76Y07789YT677183LMGCX5RY',
          },
        },
      },
      [Environment.Production]: {
        [PlanType.Monthly]: {
          nameKey: 'monthly',
          price: 29,
          country: CountryCode.MY,
          currencyCode: CurrencyCode.MYR,
          interval: PlanInterval.Monthly,
          stripePriceId: 'premium-monthly-MYR',
          paypalPlanIds: {
            withFreeTrial: 'P-92969889YP003341TMGCX6CI',
            noFreeTrial: 'P-50X6180364762470YMGCX6CQ',
          },
        },
        [PlanType.Annual]: {
          nameKey: 'annual',
          price: 228,
          country: CountryCode.MY,
          currencyCode: CurrencyCode.MYR,
          interval: PlanInterval.Yearly,
          stripePriceId: 'premium-annual-MYR',
          paypalPlanIds: {
            withFreeTrial: 'P-7PK634310W297462RMGCX6CI',
            noFreeTrial: 'P-7G796836LG576114AMGCX6CQ',
          },
        },
      },
    },
  },
};

export const getPremiumSubscriptionPricing = (): Record<string, PremiumPricing> => {
  return defaultPremiumPricing;
};

export const getPremiumPlans = (countryCode: CountryCode): Record<string, PremiumPlan> => {
  const premiumPricing = defaultPremiumPricing[countryCode];
  if (!premiumPricing.plans) {
    throw Error('Pricing plans not configured for this locale');
  }

  if (env === Environment.Production) {
    return premiumPricing.plans[env];
  }

  return premiumPricing.plans[env] || premiumPricing.plans[Environment.Staging];
};

export const getBillingText = (interval: PlanInterval, t: TFunction) => {
  switch (interval) {
    case PlanInterval.Yearly:
    case PlanInterval.Biennial:
      return t('billedAnnually', { ns: 'subscribe' });
    case PlanInterval.Monthly:
      return t('billedMonthly', { ns: 'subscribe' });
    case PlanInterval.SixMonth:
      return t('billedSixMonths', { ns: 'subscribe' });
    default:
      return null;
  }
};

export const getDiscountPercentageText = (percentage: number, t: TFunction) => {
  const percentageAsInteger = Math.trunc(percentage * 100);
  return t('savePercentage', { percentage: percentageAsInteger, ns: 'subscribe' });
};
