import { useMemo } from 'react';

import { Language, LanguageId, SupportedSourceLanguages } from '~/constants/languages';
import { CountryCode } from '~/constants/prices';
import { useGlobalContext } from '~/context/GlobalContext';

import { useLanguageInfo } from './useLanguageInfo';

export const useLoggedInUser = () => {
  const { loggedInUser, refreshUser, isLoggedInUserLoading } = useGlobalContext();
  const { getLanguageId, getLanguageIdFromLanguage, getLanguageInfo } = useLanguageInfo();
  const inLanguageOptions = ['inglés'];

  /**
   * updating `selectedLanguage` before setting to `GlobalContext` within `_app.tsx` does not update value permanently therefore updating here
   */
  if (loggedInUser && inLanguageOptions.includes(loggedInUser.selectedLanguage ?? '')) {
    loggedInUser.selectedLanguage = Language.English;
  }

  return {
    loggedInUser: useMemo(
      () =>
        loggedInUser && {
          ...loggedInUser,
          countryCode: loggedInUser?.countryCode ?? CountryCode.US,
        },
      [loggedInUser],
    ),
    isLoggedInUserLoading,
    isLoggedIn: !!loggedInUser,
    loggedInAsRegistered: !!loggedInUser && !!loggedInUser.email,
    refreshUser,
    isPremium: !!loggedInUser && loggedInUser.isPremiumSubscribed,
    sourceLanguage: (loggedInUser && (loggedInUser.sourceLanguage as SupportedSourceLanguages)) || LanguageId.En,
    selectedLanguage: (loggedInUser && (loggedInUser.selectedLanguage as Language)) || Language.Spanish,
    selectedLanguageVariant: loggedInUser ? loggedInUser?.selectedLanguageVariant : undefined,
    targetLanguage: (loggedInUser && getLanguageId(loggedInUser.selectedLanguage as Language)) || LanguageId.Es,
    targetLanguageWithVariant:
      (loggedInUser &&
        getLanguageIdFromLanguage(
          getLanguageInfo(loggedInUser.selectedLanguage as Language),
          loggedInUser.selectedLanguage as Language,
          loggedInUser.selectedLanguageVariant,
        )) ||
      LanguageId.Es,
    hasInitialLanguageBeenSelected: !!loggedInUser && loggedInUser.hasInitialLanguageBeenSelected,
  };
};
