import fetch from 'isomorphic-unfetch';
import { get } from 'lodash';

import AuthHelpers from '~/helpers/auth';

import configs from './config';

// Custom fetch that is used for each graphql request through client.
// Pass in URI and options so that newly created request can use those that were passed from the parent request.
// eslint-disable-next-line import/prefer-default-export
export const customFetchWithReauthorization = async (uri, options) => {
  // Create a reference of the refreshing token request promise to see if we are fetching for the token later on.
  let reauthorizationRequest = null;

  // Get the refresh token to be used in authorize call
  const refreshToken = process.browser
    ? AuthHelpers.getAuthCookies().refreshToken
    : get(options, 'headers.refreshToken');

  // Make request like normal
  // apolloHttpLink expects that whatever fetch function is used, it returns a promise
  const response = await fetch(uri, options);
  const json = await response.json();

  // If response contains expired auth token error, then proceed to refresh the token
  if (get(json, 'errors[0].extensions.code') === 'AUTH_TOKEN_EXPIRED') {
    // TODO: Determine if we should also deal with invalid auth token here
    // Check that the reauthorizationRequest hasn't already been set before creating a new one
    if (!reauthorizationRequest) {
      const body = JSON.stringify({
        query:
          'mutation authorize($input: AuthorizeInput!) { authorize(input: $input) { userId accessToken expiresInPeriod refreshToken } }',
        variables: {
          input: {
            refreshToken,
          },
        },
      });

      // Set the reauthorization request to a fetch for the refreshed token
      reauthorizationRequest = fetch(configs.TOUCAN_GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
    }

    // Resolve the request to get it's response
    const reauthorizationRequestResponse = await Promise.resolve(reauthorizationRequest);

    // Get refreshed auth token
    const reauthorizationResponseJSON = await reauthorizationRequestResponse.json();
    const newAccessToken = get(reauthorizationResponseJSON, 'data.authorize.accessToken');

    // Now that the refreshing promise has been executed, set it to null
    reauthorizationRequest = null;

    // Store the new auth tokens
    if (typeof window === 'object' && newAccessToken && refreshToken) {
      AuthHelpers.setAuthCookies({ token: newAccessToken, refreshToken });
    }

    // Set auth token on initial request to continue inital request with new auth token
    let optionHeaders = options.headers;
    optionHeaders = { ...optionHeaders, authorization: `Bearer ${newAccessToken}` };
    const updatedOptions = { ...options, headers: optionHeaders };

    // Return the new promise with the new auth token
    return fetch(uri, updatedOptions);
  }

  return new Response(JSON.stringify(json), {
    status: 200,
  });
};
