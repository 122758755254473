/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  Upload: any;
};

export type AccountCreditLedgerRecord = {
  __typename?: 'AccountCreditLedgerRecord';
  amount: Scalars['Float'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AccountCreditRedemptionReward = {
  __typename?: 'AccountCreditRedemptionReward';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
  referredUser?: Maybe<ReferredUserInfo>;
  createdAt: Scalars['DateTime'];
  redeemedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountCreditRewardInfo = RewardInfo & {
  __typename?: 'AccountCreditRewardInfo';
  rewardType: Scalars['String'];
  metadata: AccountCreditRewardMetadata;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AccountCreditRewardMetadata = {
  __typename?: 'AccountCreditRewardMetadata';
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type AccountCreditRewardSummary = {
  __typename?: 'AccountCreditRewardSummary';
  rewardType: Scalars['String'];
  rewards: Array<AccountCreditRedemptionReward>;
  totalCredits: Scalars['Float'];
  totalAvailableCredits: Scalars['Float'];
  creditCurrencyCode: Scalars['String'];
};

export type Ad = {
  __typename?: 'Ad';
  id: Scalars['ID'];
  name: Scalars['String'];
  imgSrc: AdImgSrc;
  linkUrl: Scalars['String'];
};

export type AddEventBusEventInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type AdditionalWordInfo = {
  __typename?: 'AdditionalWordInfo';
  definition?: Maybe<Scalars['String']>;
  pronunciation?: Maybe<Scalars['String']>;
};

export type AddVariationToWordListInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  variationId: Scalars['String'];
};

export type AddWordToVectoredWordListInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type AddWordToWordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type AdImgSrc = {
  __typename?: 'AdImgSrc';
  light: Scalars['String'];
  dark?: Maybe<Scalars['String']>;
};

export type AlwaysTranslateSavedWordsSettingInput = {
  isAlwaysTranslateSavedWordsEnabled: Scalars['Boolean'];
};

export type AnonymousUserInput = {
  anonymousId?: Maybe<Scalars['String']>;
  selectedLanguage?: Maybe<Scalars['String']>;
  sourceLanguage: Scalars['String'];
  targetLanguage?: Maybe<LanguageId>;
  source?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
};

export type AudioPreferencesInput = {
  playbackRate: Scalars['Float'];
  voiceNumber: Scalars['Int'];
};

export type AuthenticateExternalLoginInput = {
  code: Scalars['String'];
  redirectUrl: Scalars['String'];
  languageId?: Maybe<LanguageId>;
  source?: Maybe<Scalars['String']>;
  authProvider: ExternalAuthProvider;
  newUserInformation?: Maybe<NewUserInformationInput>;
};

export type AuthorizeInput = {
  refreshToken: Scalars['String'];
};

export type BadgeImage = {
  __typename?: 'BadgeImage';
  badgeImageUrl: Scalars['String'];
  badgeImageAltText: Scalars['String'];
};

export type BatchRecordVariationGamePointsInput = {
  variationGamePointsInputs: Array<RecordVariationGamePointsInput>;
};

export type BatchRecordVariationViewsInput = {
  variationViewsInputs: Array<RecordVariationViewsInput>;
};

export type BatchRecordVectorTranslationGamePointsInput = {
  vectorTranslationGamePointsInputs: Array<RecordVectorTranslationGamePointsInput>;
};

export type BatchRecordVectorTranslationViewsInput = {
  vectorTranslationViewsInputs: Array<RecordVectorTranslationViewsInput>;
};

export type BatchVectorizeWordListInput = {
  batchSize: Scalars['Int'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type CheckCronInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export enum ChecklistItem {
  HasCreatedToucanAccount = 'hasCreatedToucanAccount',
  HasHoveredOverTranslation = 'hasHoveredOverTranslation',
  HasPracticedFirstWord = 'hasPracticedFirstWord',
  HasSavedFirstWord = 'hasSavedFirstWord',
  HasLearnedAboutPremium = 'hasLearnedAboutPremium',
}

export type Chunk = {
  __typename?: 'Chunk';
  text?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  chunkType?: Maybe<Scalars['String']>;
  containsNamedEntity?: Maybe<Scalars['Boolean']>;
  fleschKincaidReadingEase?: Maybe<Scalars['Float']>;
  fleschKincaidGradeLevel?: Maybe<Scalars['Float']>;
};

export type ContextlessLearning = {
  learningType: ContextlessLearningType;
};

export type ContextlessLearningInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  supportedLearningTypes?: Maybe<Array<ContextlessLearningType>>;
};

export type ContextlessLearningMoment = {
  __typename?: 'ContextlessLearningMoment';
  learnings: Array<ContextlessLearning>;
};

export enum ContextlessLearningType {
  TargetPhraseMultipleChoice = 'TargetPhraseMultipleChoice',
  Flashcard = 'Flashcard',
}

export type ContextualizedOnboardingExampleSentencePracticeMoment = ContextualizedOnboardingExtraPracticeMoment & {
  __typename?: 'ContextualizedOnboardingExampleSentencePracticeMoment';
  momentType: ContextualizedOnboardingExtraPracticeMomentType;
  learningShortcut: ContextualizedOnboardingLearningShortcut;
  exampleSentences: Array<ContextualizedOnboardingExtraPracticeExampleSentence>;
  practiceQuestions: Array<ContextualizedOnboardingExtraPracticeQuestion>;
};

export type ContextualizedOnboardingExtraPracticeExampleSentence = {
  __typename?: 'ContextualizedOnboardingExtraPracticeExampleSentence';
  sourceLanguageSentence: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type ContextualizedOnboardingExtraPracticeMoment = {
  momentType: ContextualizedOnboardingExtraPracticeMomentType;
};

export enum ContextualizedOnboardingExtraPracticeMomentType {
  ContextualizedOnboardingExampleSentencePracticeMoment = 'ContextualizedOnboardingExampleSentencePracticeMoment',
}

export type ContextualizedOnboardingExtraPracticeQuestion = {
  questionType: ContextualizedOnboardingExtraPracticeQuestionType;
};

export enum ContextualizedOnboardingExtraPracticeQuestionType {
  ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion = 'ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion',
}

export type ContextualizedOnboardingLearningShortcut = {
  __typename?: 'ContextualizedOnboardingLearningShortcut';
  name: Scalars['String'];
  gerundTitle: Scalars['String'];
  infoTitle: Scalars['String'];
  infoDetails: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion = ContextualizedOnboardingExtraPracticeQuestion & {
  __typename?: 'ContextualizedOnboardingTargetPhraseMultipleChoiceExtraPracticeQuestion';
  questionType: ContextualizedOnboardingExtraPracticeQuestionType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
};

export type CrawlWebPageForAllLanguagePairsInput = {
  url: Scalars['String'];
};

export type CrawlWebPageInput = {
  url: Scalars['String'];
  isHeadless: Scalars['Boolean'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type CreatePromoCodeInput = {
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
};

export type CreatePromotionInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  prefix: Scalars['String'];
  eligibility?: Maybe<PromotionEligibility>;
  period: PromotionDuration;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  promoCodes?: Maybe<Array<PromoCodeInput>>;
};

export type CreateSubscriptionPlanInput = {
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  price: PlanPriceInput;
  recurringInterval: IntervalInput;
  locale: Scalars['String'];
  trialPeriod?: Maybe<TrialPeriodInput>;
};

export type CreateSubscriptionProductInput = {
  externalIds?: Maybe<Array<ExternalIdInput>>;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  plans?: Maybe<Array<CreateSubscriptionPlanInput>>;
  addOns?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Scalars['Boolean'];
};

export enum CurrencyCode {
  Aud = 'AUD',
  Brl = 'BRL',
  Cny = 'CNY',
  Cop = 'COP',
  Eur = 'EUR',
  Gbp = 'GBP',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Mxn = 'MXN',
  Myr = 'MYR',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Rub = 'RUB',
  Try = 'TRY',
  Twd = 'TWD',
  Usd = 'USD',
  Vnd = 'VND',
}

export type DefaultBlacklistedDomains = {
  __typename?: 'DefaultBlacklistedDomains';
  blacklist?: Maybe<Array<Scalars['String']>>;
};

export type Definition = {
  __typename?: 'Definition';
  alternateDefinitions?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedDefinition?: Maybe<Scalars['String']>;
};

export type DependencyArc = {
  __typename?: 'DependencyArc';
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
};

export enum DifficultyTier {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
}

export type DifficultyTierInput = {
  difficultyTier: DifficultyTier;
  densityLevel?: Maybe<Scalars['Float']>;
};

export type DifficultyWeight = {
  __typename?: 'DifficultyWeight';
  tier: DifficultyTier;
  weight: Scalars['Float'];
};

export type Duolingo = {
  __typename?: 'Duolingo';
  /** @deprecated Field no longer supported */
  languages?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Field no longer supported */
  wordExist: Scalars['Boolean'];
};

export type DuolingoWordExistArgs = {
  word?: Maybe<Scalars['String']>;
};

export type EmailShareInfo = {
  __typename?: 'EmailShareInfo';
  to: Scalars['String'];
  hasBeenRedeemed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ErrorReportAdditionalInfoInput = {
  variationId?: Maybe<Scalars['String']>;
  browserName?: Maybe<Scalars['String']>;
  browserVersion?: Maybe<Scalars['String']>;
  osName?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  toucanVersion?: Maybe<Scalars['String']>;
};

export type ExamplePhrase = {
  __typename?: 'ExamplePhrase';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type ExampleSentencePracticeMoment = ExtraPracticeMoment & {
  __typename?: 'ExampleSentencePracticeMoment';
  momentType: ExtraPracticeMomentType;
  learningShortcut: LearningShortcut;
  learningShortcutTopic: LearningShortcutTopic;
  userHomonym: UserHomonym;
  exampleSentences: Array<ExtraPracticeExampleSentence>;
  practiceQuestions: Array<ExtraPracticeQuestion>;
};

export type ExpandedTranslation = {
  __typename?: 'ExpandedTranslation';
  expandedSourcePhrase?: Maybe<Scalars['String']>;
  targetPhrase?: Maybe<Scalars['String']>;
};

export type ExpandTranslationInput = {
  sourcePhrase: Scalars['String'];
  sourcePhraseInstanceIndex: Scalars['Int'];
  sourceSentence: Scalars['String'];
  variationId: Scalars['String'];
};

export type Experiment = {
  __typename?: 'Experiment';
  id: Scalars['ID'];
  experimentName: Scalars['String'];
  accepted: Scalars['Boolean'];
};

export type ExperimentInput = {
  experimentName?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
};

export type ExtensionStat = {
  __typename?: 'extensionStat';
  users?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExtensionStatAttributes = {
  users?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Scalars['Int']>;
};

export enum ExternalAuthProvider {
  Google = 'Google',
  Apple = 'Apple',
}

export type ExternalId = IExternalId & {
  __typename?: 'ExternalId';
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export type ExternalIdInput = {
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export type ExternalPlanId = IExternalId & {
  __typename?: 'ExternalPlanId';
  id: Scalars['String'];
  productProvider: ProductProvider;
  hasTrialPeriod: Scalars['Boolean'];
};

export type ExtraPracticeExampleSentence = {
  __typename?: 'ExtraPracticeExampleSentence';
  sentenceOrder?: Maybe<Scalars['Int']>;
  sourceLanguageSentence: Scalars['String'];
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type ExtraPracticeInput = {
  supportedExtraPracticeMomentTypes: Array<ExtraPracticeMomentType>;
  supportedQuestionTypes: Array<ExtraPracticeQuestionType>;
};

export type ExtraPracticeMoment = {
  momentType: ExtraPracticeMomentType;
};

export enum ExtraPracticeMomentType {
  ExampleSentencePracticeMoment = 'ExampleSentencePracticeMoment',
}

export type ExtraPracticeQuestion = {
  questionType: ExtraPracticeQuestionType;
};

export enum ExtraPracticeQuestionType {
  SourcePhraseMultipleChoiceExtraPracticeQuestion = 'SourcePhraseMultipleChoiceExtraPracticeQuestion',
  TargetPhraseMultipleChoiceExtraPracticeQuestion = 'TargetPhraseMultipleChoiceExtraPracticeQuestion',
  ListenExtraPracticeQuestion = 'ListenExtraPracticeQuestion',
}

export type FeedNotification = {
  __typename?: 'FeedNotification';
  layout: FeedNotificationLayout;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaURL?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  addedAt: Scalars['DateTime'];
};

export enum FeedNotificationLayout {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export type Filter = {
  word?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Flashcard = ContextlessLearning & {
  __typename?: 'Flashcard';
  learningType: ContextlessLearningType;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type FluencyQuiz = {
  __typename?: 'FluencyQuiz';
  questions: Array<FluencyQuizQuestion>;
};

export type FluencyQuizInput = {
  targetLanguage: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  targetPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
};

export type FluencyQuizQuestion = {
  questionType: FluencyQuizType;
};

export enum FluencyQuizType {
  SwapQuestion = 'SwapQuestion',
  ListenQuestion = 'ListenQuestion',
  LatinSpellingQuestion = 'LatinSpellingQuestion',
  NonLatinSpellingQuestion = 'NonLatinSpellingQuestion',
  MatchQuestion = 'MatchQuestion',
  SourcePhraseSwapQuestion = 'SourcePhraseSwapQuestion',
}

export type GenerateDataSetsInput = {
  forceRegenerate: Scalars['Boolean'];
};

export type GetRedisKeyInput = {
  prefix: Scalars['String'];
  key: Scalars['String'];
};

export type GetUsersInput = {
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type HasCompletedContextualizedOnboardingInput = {
  hasCompletedContextualizedOnboarding: Scalars['Boolean'];
};

export type HeroImage = {
  __typename?: 'HeroImage';
  heroImageUrl: Scalars['String'];
  heroImageAltText: Scalars['String'];
};

export type Homonym = {
  __typename?: 'Homonym';
  id: Scalars['String'];
  variations: Array<Variation>;
  partOfSpeech?: Maybe<Scalars['String']>;
  isDefaultHomonym: Scalars['Boolean'];
};

export type IExternalId = {
  id: Scalars['String'];
  productProvider: ProductProvider;
};

export type IncrementHomonymProgressionInput = {
  homonymRecordId: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  progressionDifference: Scalars['Int'];
};

export enum InDevLanguageId {
  Nl = 'nl',
  En = 'en',
  Fa = 'fa',
  El = 'el',
  Ga = 'ga',
  Pl = 'pl',
  Ru = 'ru',
  Sv = 'sv',
  Tr = 'tr',
  Vi = 'vi',
  Zu = 'zu',
  Other = 'other',
}

export type Interval = {
  __typename?: 'Interval';
  intervalUnit: IntervalUnit;
  count: Scalars['Int'];
};

export type IntervalInput = {
  intervalUnit: IntervalUnit;
  count: Scalars['Int'];
};

export enum IntervalUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export type InvalidateWebPageTranslationsInput = {
  url?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
};

export type KnownTranslationInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export enum LanguageId {
  De = 'de',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Pt = 'pt',
  En = 'en',
  Zh = 'zh',
  Hi = 'hi',
  He = 'he',
  Ar = 'ar',
  JaKanji = 'jaKanji',
  JaRoman = 'jaRoman',
  HiRoman = 'hiRoman',
  KoRoman = 'koRoman',
  ArRoman = 'arRoman',
  HeRoman = 'heRoman',
  ZhRoman = 'zhRoman',
}

export type LanguageLeaderboardItem = {
  __typename?: 'LanguageLeaderboardItem';
  languageId: InDevLanguageId;
  votes: Scalars['Int'];
};

export enum LanguageLearningMotivation {
  GoingOnATripSoon = 'goingOnATripSoon',
  NeedItForWork = 'needItForWork',
  LearningForSchool = 'learningForSchool',
  ConnectWithLovedOne = 'connectWithLovedOne',
  WantToImproveMyself = 'wantToImproveMyself',
  SomethingElse = 'somethingElse',
}

export type LanguageLearningMotivationInput = {
  languageLearningMotivation: LanguageLearningMotivation;
};

export type LanguageSettingsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type LanguageTranslation = {
  __typename?: 'LanguageTranslation';
  language?: Maybe<Scalars['String']>;
  translation?: Maybe<TranslationWithGenderFormsAndPrefixes>;
};

export type LastSeenTranslationCursorInput = {
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type LatinSpellingPracticeQuestion = PracticeQuestion & {
  __typename?: 'LatinSpellingPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  accentOptions: Array<Maybe<Scalars['String']>>;
  answerVariationId: Scalars['String'];
};

export type LatinSpellingQuestion = FluencyQuizQuestion & {
  __typename?: 'LatinSpellingQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  accentOptions: Array<Maybe<Scalars['String']>>;
};

export type LearningShortcut = {
  __typename?: 'LearningShortcut';
  id: Scalars['ID'];
  name: Scalars['String'];
  gerundTitle: Scalars['String'];
  infoTitle: Scalars['String'];
  infoDetails: Scalars['String'];
  learningShortcutTopics: Array<LearningShortcutTopic>;
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  examplePhrases: Array<ExamplePhrase>;
  heroImage: HeroImage;
  titleImage: TitleImage;
  badgeImage: BadgeImage;
  celebrationTitle: Scalars['String'];
  subject: LearningShortcutSubject;
  isRecommended?: Maybe<Scalars['Boolean']>;
};

export type LearningShortcutsInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
};

export enum LearningShortcutSubject {
  Food = 'food',
  Friends = 'friends',
  Greetings = 'greetings',
  Survival = 'survival',
}

export type LearningShortcutTopic = {
  __typename?: 'LearningShortcutTopic';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Lexicon = {
  __typename?: 'Lexicon';
  definitions?: Maybe<Definition>;
  pos?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<LanguageTranslation>>>;
  isDfltPos?: Maybe<Scalars['Boolean']>;
};

export type ListenExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'ListenExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type ListenPracticeQuestion = PracticeQuestion & {
  __typename?: 'ListenPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type ListenQuestion = FluencyQuizQuestion & {
  __typename?: 'ListenQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type LogInUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MachineTranslation = {
  __typename?: 'MachineTranslation';
  original?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type MachineTranslationInput = {
  sourceLanguage?: Maybe<Scalars['String']>;
  sourcePhrase?: Maybe<Scalars['String']>;
  phrase?: Maybe<Scalars['String']>;
  targetLanguage: Scalars['String'];
};

export type MatchPracticeOption = {
  __typename?: 'MatchPracticeOption';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  variationId: Scalars['String'];
};

export type MatchPracticeQuestion = PracticeQuestion & {
  __typename?: 'MatchPracticeQuestion';
  questionType: PracticeType;
  options: Array<MatchPracticeOption>;
};

export type MatchQuestion = FluencyQuizQuestion & {
  __typename?: 'MatchQuestion';
  questionType: FluencyQuizType;
  options: Array<MatchQuizOption>;
};

export type MatchQuizOption = {
  __typename?: 'MatchQuizOption';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  setRedisKey: Scalars['String'];
  putKinesisEvent: Scalars['Boolean'];
  addEventBusEvent: Scalars['Boolean'];
  checkCron: Scalars['Boolean'];
  sendSqsMessage: Scalars['Boolean'];
  sendSqsMessageOnce: Scalars['Boolean'];
  sendHydrateSubscriptionAnalytics: Scalars['Boolean'];
  updatePremiumSubscriptionStripePriceData: Scalars['Boolean'];
  updatePremiumSubscriptionPaypalPlanData: Scalars['Boolean'];
  updateSubscriptionsStatusUpdatedAtDate: Scalars['Boolean'];
  updateUserEventAttributesForAllCurrentPremiumSubscriptions: Scalars['Boolean'];
  contact?: Maybe<Scalars['String']>;
  addCEFRPhrases: Scalars['Boolean'];
  addCEFRDataToTranslationsV4: Scalars['Boolean'];
  addCognateFlagToTranslationsV4: Scalars['Boolean'];
  addPopulationBlacklistedDomain?: Maybe<Scalars['Boolean']>;
  removePopulationBlacklistedDomain?: Maybe<Scalars['Boolean']>;
  addUserBlacklistedDomain?: Maybe<Array<Scalars['String']>>;
  removeUserBlacklistedDomain?: Maybe<Array<Scalars['String']>>;
  emailExtensionDownloadLink: Scalars['Boolean'];
  updateExampleSentences: Scalars['Boolean'];
  expandTranslation: ExpandedTranslation;
  updateExtensionStat: UpdateExtensionStatResult;
  saveLanguageSelection?: Maybe<Scalars['Boolean']>;
  updateLearningShortcuts: Scalars['Boolean'];
  translatePhrase?: Maybe<MachineTranslation>;
  /** @deprecated End of Chunk MT Beta Test */
  translateChunk?: Maybe<MachineTranslation>;
  processTextThroughNlpPipeline: ProcessedText;
  maybeAddOnboardingChecklist: OnboardingChecklist;
  maybeMigrateOnboardingChecklist?: Maybe<Scalars['Boolean']>;
  createPremiumSubscription: Scalars['Boolean'];
  createPromotionalPremiumSubscription?: Maybe<PremiumSubscription>;
  createPremiumSubscriptionForUserWithAdminCode: Scalars['Boolean'];
  syncPremiumSubscriptionWithAppleAppStoreReceipt: Scalars['Boolean'];
  syncPremiumSubscriptionWithPayPalSubscriptionId: Scalars['Boolean'];
  cancelPremiumPayPalSubscription?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  generateReferralCode?: Maybe<PublicPromoCode>;
  createPromotion: Promotion;
  updatePromotion: Promotion;
  createPromoCode: PromoCode;
  referUsersByEmail: ReferUsersByEmailResponse;
  setUserReferralRewardInfo: ReferralInfo;
  setUserReferralRewardInfoMetadata: ReferralInfo;
  _?: Maybe<Scalars['String']>;
  updateTranslationsDataSets: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  saveTranslationsFromCms: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  generateV3Translations: Scalars['Boolean'];
  generateTranslations: Scalars['Boolean'];
  /** @deprecated use authorize */
  reauthenticate?: Maybe<Tokens>;
  /** @deprecated use registerUser */
  anonymousSignUp?: Maybe<Tokens>;
  /** @deprecated use logInUser */
  signIn?: Maybe<Tokens>;
  logOut: Scalars['Boolean'];
  logInUser: Tokens;
  authenticateExternalLogin: Tokens;
  registerUser: Tokens;
  authorize: Tokens;
  updateUsersName?: Maybe<Scalars['String']>;
  updateUsersPasswordV2: Scalars['Boolean'];
  saveLanguageSettings: Scalars['Boolean'];
  saveLanguageLearningMotivation: Scalars['Boolean'];
  saveHasCompletedContextualizedOnboarding: Scalars['Boolean'];
  saveDarkModeSetting: Scalars['Boolean'];
  saveUnsubscribedToEmailsSetting: Scalars['Boolean'];
  lostPassword?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<ResetPasswordReturn>;
  updateUserPersonalization?: Maybe<UpdatedUserPersonalization>;
  saveRating?: Maybe<Rating>;
  deleteEntirely: Scalars['Boolean'];
  saveAlwaysTranslateSavedWordsSetting: Scalars['Boolean'];
  setUserAudioPreferences: User;
  sendErrorReportEmail: Scalars['Boolean'];
  batchIncrementHomonymProgression: Array<UserHomonym>;
  saveUserLearningShortcutSetting: UserLearningShortcut;
  saveUserLearningShortcutIndicator: UserLearningShortcut;
  saveUserLearningShortcutCelebration: UserLearningShortcut;
  /** @deprecated use 'batchRecordVariationViews' */
  logTranslationViewCounts?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVariationViews' */
  recordTranslationViews?: Maybe<UserTranslation>;
  /** @deprecated use 'batchRecordVariationGamePoints' */
  recordGamePoints: Array<UserTranslationPostGameInformation>;
  /** @deprecated use 'saveVariationIsKnown' */
  saveKnownTranslation?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeSourcePhrase' */
  saveTranslationIsRemoved?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVectorTranslationIsFluent' */
  saveTranslationIsFluent?: Maybe<UserTranslation>;
  /** @deprecated use 'recordVariationAudioPlay' */
  recordTranslationAudioPlay?: Maybe<UserTranslation>;
  removeSourcePhrase: Array<Scalars['String']>;
  enqueueCreateTargetToSourceLookupJob?: Maybe<Scalars['Boolean']>;
  enqueueCreateVariationIdLookupJob?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'batchRecordVariationViews' */
  batchRecordVectorTranslationViews?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVectorVariationViews' */
  recordVectorTranslationViews?: Maybe<UserTranslation>;
  /** @deprecated use 'batchRecordVariationGamePoints' */
  batchRecordVectorTranslationGamePoints?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'recordVariationAudioPlay' */
  recordVectorTranslationAudioPlay?: Maybe<UserTranslation>;
  /** @deprecated use 'recordVariationAudioPlay' */
  saveVectorTranslationIsKnown?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVariationIsKnown' */
  saveVectorTranslationIsFluent?: Maybe<UserTranslation>;
  /** @deprecated use 'saveVariationIsPracticed' */
  saveVectorTranslationIsPracticed?: Maybe<Scalars['Boolean']>;
  batchRecordVariationViews?: Maybe<Scalars['Boolean']>;
  recordVariationViews?: Maybe<UserTranslation>;
  batchRecordVariationGamePoints?: Maybe<Scalars['Boolean']>;
  saveVariationIsPracticed?: Maybe<Scalars['Boolean']>;
  recordVariationAudioPlay?: Maybe<UserTranslation>;
  saveVariationIsKnown?: Maybe<UserTranslation>;
  saveVariationIsFluent?: Maybe<UserTranslation>;
  updateLastSeenProgressionScore?: Maybe<UserTranslation>;
  updateTranslationInaccuracies: Array<TranslationInaccuracies>;
  updateTranslationInaccuraciesDashboard: Scalars['Boolean'];
  crawlWebPage: WebPageTranslationsResponse;
  crawlWebPageForAllLanguagePairs: Array<WebPageTranslationsResponse>;
  incrementWebPageVisit: Scalars['Boolean'];
  invalidateWebPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'addWordToVectoredWordList' */
  addWordToWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeWordFromVectoredWordList' */
  removeWordFromWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'addVariationToWordList' */
  addWordToVectoredWordList?: Maybe<Scalars['Boolean']>;
  /** @deprecated use 'removeVariationFromWordList' */
  removeWordFromVectoredWordList?: Maybe<Scalars['Boolean']>;
  addVariationToWordList?: Maybe<Scalars['Boolean']>;
  removeVariationFromWordList?: Maybe<Scalars['Boolean']>;
  updateDifficultyTier: User;
  createSubscriptionProduct: SubscriptionProduct;
  updateSubscriptionProduct: SubscriptionProduct;
  createSubscriptionPlan: SubscriptionProduct;
  archiveSubscriptionPlan: SubscriptionProduct;
  updateSubscriptionPlan: SubscriptionProduct;
};

export type MutationSetRedisKeyArgs = {
  input: SetRedisKeyInput;
};

export type MutationPutKinesisEventArgs = {
  input: PutKinesisEventInput;
};

export type MutationAddEventBusEventArgs = {
  input: AddEventBusEventInput;
};

export type MutationCheckCronArgs = {
  input: CheckCronInput;
};

export type MutationContactArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  feedback: Scalars['String'];
  submissionType?: Maybe<SubmissionType>;
};

export type MutationAddPopulationBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationRemovePopulationBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationAddUserBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationRemoveUserBlacklistedDomainArgs = {
  domain?: Maybe<Scalars['String']>;
};

export type MutationEmailExtensionDownloadLinkArgs = {
  email: Scalars['String'];
};

export type MutationUpdateExampleSentencesArgs = {
  input: UpdateExampleSentencesInput;
};

export type MutationExpandTranslationArgs = {
  input: ExpandTranslationInput;
};

export type MutationUpdateExtensionStatArgs = {
  attributes: ExtensionStatAttributes;
};

export type MutationSaveLanguageSelectionArgs = {
  input: SaveLanguageSelectionInput;
};

export type MutationTranslatePhraseArgs = {
  input: MachineTranslationInput;
};

export type MutationTranslateChunkArgs = {
  input: MachineTranslationInput;
};

export type MutationProcessTextThroughNlpPipelineArgs = {
  input: NlpPipelineInput;
};

export type MutationMaybeAddOnboardingChecklistArgs = {
  checklistItem: ChecklistItem;
};

export type MutationMaybeMigrateOnboardingChecklistArgs = {
  numberOfMonthsToLookBack: Scalars['Int'];
};

export type MutationCreatePremiumSubscriptionArgs = {
  stripePaymentMethodId: Scalars['String'];
  stripePriceId: Scalars['String'];
  includeFreeTrial?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MutationCreatePremiumSubscriptionForUserWithAdminCodeArgs = {
  userId: Scalars['String'];
  promoCode: Scalars['String'];
};

export type MutationSyncPremiumSubscriptionWithAppleAppStoreReceiptArgs = {
  appReceipt: Scalars['String'];
};

export type MutationSyncPremiumSubscriptionWithPayPalSubscriptionIdArgs = {
  payPalSubscriptionId: Scalars['String'];
};

export type MutationCancelPremiumPayPalSubscriptionArgs = {
  payPalSubscriptionId: Scalars['String'];
};

export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};

export type MutationUpdatePromotionArgs = {
  promotionId: Scalars['ID'];
  input: UpdatePromotionInput;
};

export type MutationCreatePromoCodeArgs = {
  promotionId: Scalars['ID'];
  input: CreatePromoCodeInput;
};

export type MutationReferUsersByEmailArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationSetUserReferralRewardInfoArgs = {
  input: ReferralRewardInfoInput;
};

export type MutationSetUserReferralRewardInfoMetadataArgs = {
  input: ReferralRewardInfoMetadataInput;
};

export type MutationUpdateTranslationsDataSetsArgs = {
  input: GenerateDataSetsInput;
};

export type MutationGenerateV3TranslationsArgs = {
  minUpdateRecencyDate?: Maybe<Scalars['DateTime']>;
};

export type MutationGenerateTranslationsArgs = {
  minUpdateRecencyDate?: Maybe<Scalars['DateTime']>;
};

export type MutationReauthenticateArgs = {
  refreshToken: Scalars['String'];
  authToken?: Maybe<Scalars['String']>;
};

export type MutationAnonymousSignUpArgs = {
  user: AnonymousUserInput;
};

export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLogOutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationLogInUserArgs = {
  input: LogInUserInput;
};

export type MutationAuthenticateExternalLoginArgs = {
  input?: Maybe<AuthenticateExternalLoginInput>;
};

export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type MutationAuthorizeArgs = {
  input?: Maybe<AuthorizeInput>;
};

export type MutationUpdateUsersNameArgs = {
  name: Scalars['String'];
};

export type MutationUpdateUsersPasswordV2Args = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};

export type MutationSaveLanguageSettingsArgs = {
  input: LanguageSettingsInput;
};

export type MutationSaveLanguageLearningMotivationArgs = {
  input: LanguageLearningMotivationInput;
};

export type MutationSaveHasCompletedContextualizedOnboardingArgs = {
  input: HasCompletedContextualizedOnboardingInput;
};

export type MutationSaveDarkModeSettingArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationSaveUnsubscribedToEmailsSettingArgs = {
  isUnsubscribedToEmails: Scalars['Boolean'];
};

export type MutationLostPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationUpdateUserPersonalizationArgs = {
  input?: Maybe<UpdateUserPersonalizationInput>;
};

export type MutationSaveRatingArgs = {
  input: RatingInput;
};

export type MutationSaveAlwaysTranslateSavedWordsSettingArgs = {
  input?: Maybe<AlwaysTranslateSavedWordsSettingInput>;
};

export type MutationSetUserAudioPreferencesArgs = {
  input: AudioPreferencesInput;
};

export type MutationSendErrorReportEmailArgs = {
  errorReason: Scalars['String'];
  translation: Scalars['String'];
  errorDetails?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<ErrorReportAdditionalInfoInput>;
};

export type MutationBatchIncrementHomonymProgressionArgs = {
  input: Array<IncrementHomonymProgressionInput>;
};

export type MutationSaveUserLearningShortcutSettingArgs = {
  input: SaveUserLearningShortcutSettingInput;
};

export type MutationSaveUserLearningShortcutIndicatorArgs = {
  input: SaveUserLearningShortcutIndicatorInput;
};

export type MutationSaveUserLearningShortcutCelebrationArgs = {
  input: UserLearningShortcutInput;
};

export type MutationLogTranslationViewCountsArgs = {
  input: Array<TranslationViewCountInput>;
};

export type MutationRecordTranslationViewsArgs = {
  input: RecordTranslationViewsInput;
};

export type MutationRecordGamePointsArgs = {
  input: Array<TranslationGamePointsInput>;
};

export type MutationSaveKnownTranslationArgs = {
  input: KnownTranslationInput;
};

export type MutationSaveTranslationIsRemovedArgs = {
  input: SaveTranslationIsRemovedInput;
};

export type MutationSaveTranslationIsFluentArgs = {
  input: SaveTranslationIsFluentInput;
};

export type MutationRecordTranslationAudioPlayArgs = {
  input: RecordTranslationAudioPlayInput;
};

export type MutationRemoveSourcePhraseArgs = {
  input: RemoveSourcePhraseInput;
};

export type MutationBatchRecordVectorTranslationViewsArgs = {
  input: BatchRecordVectorTranslationViewsInput;
};

export type MutationRecordVectorTranslationViewsArgs = {
  input: RecordVectorTranslationViewsInput;
};

export type MutationBatchRecordVectorTranslationGamePointsArgs = {
  input: BatchRecordVectorTranslationGamePointsInput;
};

export type MutationRecordVectorTranslationAudioPlayArgs = {
  input: RecordVectorTranslationAudioPlayInput;
};

export type MutationSaveVectorTranslationIsKnownArgs = {
  input: SaveVectorTranslationIsKnownInput;
};

export type MutationSaveVectorTranslationIsFluentArgs = {
  input: SaveVectorTranslationIsFluentInput;
};

export type MutationSaveVectorTranslationIsPracticedArgs = {
  input: BatchRecordVectorTranslationGamePointsInput;
};

export type MutationBatchRecordVariationViewsArgs = {
  input: BatchRecordVariationViewsInput;
};

export type MutationRecordVariationViewsArgs = {
  input: RecordVariationViewsInput;
};

export type MutationBatchRecordVariationGamePointsArgs = {
  input: BatchRecordVariationGamePointsInput;
};

export type MutationSaveVariationIsPracticedArgs = {
  input: BatchRecordVariationGamePointsInput;
};

export type MutationRecordVariationAudioPlayArgs = {
  variationId: Scalars['String'];
};

export type MutationSaveVariationIsKnownArgs = {
  input: SaveVariationIsKnownInput;
};

export type MutationSaveVariationIsFluentArgs = {
  input: SaveVariationIsFluentInput;
};

export type MutationUpdateLastSeenProgressionScoreArgs = {
  input: UpdateLastSeenProgressionScoreInput;
};

export type MutationUpdateTranslationInaccuraciesArgs = {
  input: TranslationInaccuraciesInput;
};

export type MutationCrawlWebPageArgs = {
  input: CrawlWebPageInput;
};

export type MutationCrawlWebPageForAllLanguagePairsArgs = {
  input: CrawlWebPageForAllLanguagePairsInput;
};

export type MutationIncrementWebPageVisitArgs = {
  input: WebPageVisitInput;
};

export type MutationInvalidateWebPageArgs = {
  input: InvalidateWebPageTranslationsInput;
};

export type MutationAddWordToWordListArgs = {
  input: AddWordToWordListInput;
};

export type MutationRemoveWordFromWordListArgs = {
  input: RemoveWordFromWordListInput;
};

export type MutationAddWordToVectoredWordListArgs = {
  input: AddWordToVectoredWordListInput;
};

export type MutationRemoveWordFromVectoredWordListArgs = {
  input: RemoveWordFromVectoredWordListInput;
};

export type MutationAddVariationToWordListArgs = {
  input: AddVariationToWordListInput;
};

export type MutationRemoveVariationFromWordListArgs = {
  input: RemoveVariationFromWordListInput;
};

export type MutationUpdateDifficultyTierArgs = {
  input: DifficultyTierInput;
};

export type MutationCreateSubscriptionProductArgs = {
  input: CreateSubscriptionProductInput;
};

export type MutationUpdateSubscriptionProductArgs = {
  productId: Scalars['ID'];
  input: UpdateSubscriptionProductInput;
};

export type MutationCreateSubscriptionPlanArgs = {
  productId: Scalars['ID'];
  input: CreateSubscriptionPlanInput;
};

export type MutationArchiveSubscriptionPlanArgs = {
  productId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type MutationUpdateSubscriptionPlanArgs = {
  planId: Scalars['ID'];
  input: UpdateSubscriptionPlanInput;
};

export type NewUserInformationInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type NlpPipelineInput = {
  text: Scalars['String'];
};

export type NonLatinSpellingPracticeQuestion = PracticeQuestion & {
  __typename?: 'NonLatinSpellingPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type NonLatinSpellingQuestion = FluencyQuizQuestion & {
  __typename?: 'NonLatinSpellingQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type NotificationFeedInput = {
  sourceLanguage: LanguageId;
};

export type OnboardingChecklist = {
  __typename?: 'OnboardingChecklist';
  hasCreatedToucanAccount: Scalars['Boolean'];
  hasHoveredOverTranslation: Scalars['Boolean'];
  hasPracticedFirstWord: Scalars['Boolean'];
  hasSavedFirstWord: Scalars['Boolean'];
  hasLearnedAboutPremium: Scalars['Boolean'];
};

export type OnboardingTranslationDataSetInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type OnboardingTranslations = {
  __typename?: 'OnboardingTranslations';
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  translations: Array<TranslationInfo>;
};

export enum OrderBy {
  CreatedAt = 'createdAt',
  Word = 'word',
}

export type Pagination = {
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  lastSeenId?: Maybe<Scalars['ID']>;
};

export type PaginationBySkip = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<Filter>;
  orderBy?: Maybe<OrderBy>;
  orderAsc?: Maybe<Scalars['Boolean']>;
};

export enum PartOfSpeech {
  Adjective = 'adjective',
  Adverb = 'adverb',
  Conjunction = 'conjunction',
  Determiner = 'determiner',
  Interjection = 'interjection',
  Noun = 'noun',
  Number = 'number',
  Phrase = 'phrase',
  Preposition = 'preposition',
  Pronoun = 'pronoun',
  Verb = 'verb',
}

export enum PaymentCountryCode {
  Us = 'US',
  In = 'IN',
  Mx = 'MX',
}

export type PlanPrice = {
  __typename?: 'PlanPrice';
  amount: Scalars['Float'];
  currencyCode: CurrencyCode;
  updatedAt: Scalars['DateTime'];
};

export type PlanPriceInput = {
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
};

export type Practice = {
  __typename?: 'Practice';
  questions: Array<PracticeQuestion>;
};

export type PracticeInput = {
  supportedQuestionTypes?: Maybe<Array<PracticeType>>;
  variationIds: Array<Scalars['String']>;
};

export type PracticeQuestion = {
  questionType: PracticeType;
};

export type PracticeQuizInput = {
  translations: Array<PracticeTranslationVector>;
  supportedQuestionTypes?: Maybe<Array<FluencyQuizType>>;
};

export type PracticeTranslationVector = {
  targetLanguage: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  targetPhrase: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  sourcePhrase: Scalars['String'];
  sourceRoot?: Maybe<Scalars['String']>;
};

export enum PracticeType {
  SwapPracticeQuestion = 'SwapPracticeQuestion',
  ListenPracticeQuestion = 'ListenPracticeQuestion',
  LatinSpellingPracticeQuestion = 'LatinSpellingPracticeQuestion',
  NonLatinSpellingPracticeQuestion = 'NonLatinSpellingPracticeQuestion',
  MatchPracticeQuestion = 'MatchPracticeQuestion',
  SourcePhraseSwapPracticeQuestion = 'SourcePhraseSwapPracticeQuestion',
}

export type PremiumSubscription = {
  __typename?: 'PremiumSubscription';
  provider?: Maybe<SubscriptionPaymentProvider>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  appStoreOriginalTransactionId?: Maybe<Scalars['String']>;
  payPalSubscriptionId?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PublicPromoCode>;
  planDisplayName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  startDate: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  cancelAt?: Maybe<Scalars['DateTime']>;
  trialStart?: Maybe<Scalars['DateTime']>;
  trialEnd?: Maybe<Scalars['DateTime']>;
  isAdmin: Scalars['Boolean'];
};

export type ProcessedText = {
  __typename?: 'ProcessedText';
  sentences?: Maybe<Array<Maybe<Sentence>>>;
  chunks?: Maybe<Array<Maybe<Chunk>>>;
  tokens?: Maybe<Array<Maybe<Token>>>;
};

export enum ProductProvider {
  Stripe = 'stripe',
  PayPal = 'payPal',
}

export enum ProgressChartType {
  Learned = 'Learned',
  Fluent = 'Fluent',
}

export type ProgressReport = {
  __typename?: 'ProgressReport';
  id?: Maybe<Scalars['ID']>;
  userPercentileText?: Maybe<Scalars['String']>;
  totalWordsInReportingPeriod?: Maybe<Scalars['Int']>;
  uniqueWordsInReportingPeriod?: Maybe<Scalars['Int']>;
  totalWordsSinceAccountCreation?: Maybe<Scalars['Int']>;
  weekOf?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  promoId: Scalars['String'];
  redemptions: Scalars['Int'];
  maxRedemptions: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PromoCodeInput = {
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromotionEligibility>;
  period: PromotionDuration;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  promoCodes?: Maybe<Array<PromoCode>>;
};

export enum PromotionDuration {
  P1W = 'P1W',
  P30D = 'P30D',
  P1M = 'P1M',
  P2M = 'P2M',
  P3M = 'P3M',
  P4M = 'P4M',
  P5M = 'P5M',
  P6M = 'P6M',
  P7M = 'P7M',
  P8M = 'P8M',
  P9M = 'P9M',
  P10M = 'P10M',
  P11M = 'P11M',
  P1Y = 'P1Y',
}

export enum PromotionEligibility {
  OthersNeverSubscribed = 'OthersNeverSubscribed',
  SelfAndOthersNeverSubscribed = 'SelfAndOthersNeverSubscribed',
}

export type PublicPromoCode = {
  __typename?: 'PublicPromoCode';
  code: Scalars['String'];
  promoName: Scalars['String'];
  promoPeriod: Scalars['String'];
  promoEligibility: PromotionEligibility;
  isRedeemed: Scalars['Boolean'];
  referredByUserId?: Maybe<Scalars['String']>;
  referredByName?: Maybe<Scalars['String']>;
};

export type PutKinesisEventInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ads: Array<Ad>;
  getSignUpsSince: RecentUsers;
  countUsersSince: Scalars['Int'];
  getRedisKey?: Maybe<Scalars['String']>;
  contextlessLearningMoment: ContextlessLearningMoment;
  getAdditionalWordInfoV4: AdditionalWordInfo;
  getOnboardingTranslationDataSet: OnboardingTranslations;
  defaultBlacklistedDomains?: Maybe<DefaultBlacklistedDomains>;
  userBlacklistedDomains?: Maybe<UserBlacklistedDomains>;
  /** @deprecated Field no longer supported */
  duolingo?: Maybe<Duolingo>;
  extensionStat?: Maybe<ExtensionStat>;
  extraPractice?: Maybe<ExtraPracticeMoment>;
  contextualizedOnboardingExtraPractice: ContextualizedOnboardingExtraPracticeMoment;
  fluencyQuiz?: Maybe<FluencyQuiz>;
  /** @deprecated use 'randomQuizQuestionV2' */
  randomQuizQuestion?: Maybe<FluencyQuizQuestion>;
  /** @deprecated use 'practiceQuizV2' */
  practiceQuiz?: Maybe<FluencyQuiz>;
  languageLeaderboard: Array<LanguageLeaderboardItem>;
  learningShortcuts: Array<Maybe<LearningShortcut>>;
  notificationFeed: Array<FeedNotification>;
  getCurrentSubscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  getDefaultCardInfo?: Maybe<StripeCardInfo>;
  getPremiumSubscription?: Maybe<PremiumSubscription>;
  getQueuedPremiumSubscription?: Maybe<PremiumSubscription>;
  getStripeCustomerPortal?: Maybe<StripeCustomerPortal>;
  premiumStripePrices?: Maybe<Array<StripePrice>>;
  randomPracticeQuestion?: Maybe<PracticeQuestion>;
  practice?: Maybe<Practice>;
  getPromotionDurations: Array<Scalars['String']>;
  getAdminPromoCodes: Array<Scalars['String']>;
  userReferral: UserReferral;
  getReferrer: ReferralUserInfo;
  _?: Maybe<Scalars['String']>;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSet: TranslationsDataSet;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSetV2: TranslationsDataSet;
  /** @deprecated use 'translationsDataSetV4' */
  translationsDataSetV3: TranslationsDataSet;
  translationsDataSetV4: TranslationsDataSet;
  user?: Maybe<User>;
  me?: Maybe<User>;
  isResetTokenValid: Scalars['Boolean'];
  getUsers: Array<User>;
  getUserAccountCreditsInfo: UserAccountCredits;
  progressReportForCurrentUser?: Maybe<ProgressReport>;
  progressReportById: ProgressReport;
  /** @deprecated use 'vectorUserTranslation' */
  userTranslation?: Maybe<UserTranslation>;
  /** @deprecated use 'userTranslationVariation' */
  vectorUserTranslation?: Maybe<UserTranslation>;
  userTranslationVariation?: Maybe<UserTranslation>;
  removedSourcePhrases: Array<Scalars['String']>;
  userTranslationsWithin?: Maybe<UserTranslationsForProgression>;
  /** @deprecated No longer in use. */
  userTranslationProgressChart?: Maybe<Array<Scalars['Int']>>;
  /** @deprecated use 'userTranslationsByVariationId' */
  userTranslationsByPhraseVectorSet?: Maybe<Array<UserTranslation>>;
  userTranslationsByVariationIds?: Maybe<Array<UserTranslation>>;
  getTranslationInaccuracies: Array<TranslationInaccuracies>;
  webPageTranslation: WebPageTranslationsResponse;
  webPageTranslations: Array<WebPageTranslationsResponse>;
  wordList?: Maybe<WordList>;
  getSubscriptionProduct: SubscriptionProduct;
  getUsersPremiumProduct: SubscriptionProduct;
  getSubscriptionProducts: Array<SubscriptionProduct>;
};

export type QueryGetSignUpsSinceArgs = {
  input: RecentUsersSearchInput;
};

export type QueryCountUsersSinceArgs = {
  input: RecentUsersSearchInput;
};

export type QueryGetRedisKeyArgs = {
  input: GetRedisKeyInput;
};

export type QueryContextlessLearningMomentArgs = {
  input: ContextlessLearningInput;
};

export type QueryGetAdditionalWordInfoV4Args = {
  variationId?: Maybe<Scalars['String']>;
};

export type QueryGetOnboardingTranslationDataSetArgs = {
  input: OnboardingTranslationDataSetInput;
};

export type QueryExtraPracticeArgs = {
  input: ExtraPracticeInput;
};

export type QueryFluencyQuizArgs = {
  input: FluencyQuizInput;
};

export type QueryRandomQuizQuestionArgs = {
  input: RandomQuizQuestionInput;
};

export type QueryPracticeQuizArgs = {
  input: PracticeQuizInput;
};

export type QueryLearningShortcutsArgs = {
  input: LearningShortcutsInput;
};

export type QueryNotificationFeedArgs = {
  input: NotificationFeedInput;
};

export type QueryPremiumStripePricesArgs = {
  paymentCountryCode?: Maybe<PaymentCountryCode>;
};

export type QueryRandomPracticeQuestionArgs = {
  input: RandomPracticeQuestionInput;
};

export type QueryPracticeArgs = {
  input: PracticeInput;
};

export type QueryUserReferralArgs = {
  userId: Scalars['String'];
};

export type QueryGetReferrerArgs = {
  redeemCode: Scalars['String'];
};

export type QueryTranslationsDataSetArgs = {
  input: TranslationsDataSetInput;
};

export type QueryTranslationsDataSetV2Args = {
  input: TranslationsDataSetInput;
};

export type QueryTranslationsDataSetV3Args = {
  input: TranslationsDataSetV3Input;
};

export type QueryTranslationsDataSetV4Args = {
  input: TranslationsDataSetV4Input;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryIsResetTokenValidArgs = {
  token: Scalars['String'];
};

export type QueryGetUsersArgs = {
  input?: Maybe<GetUsersInput>;
};

export type QueryGetUserAccountCreditsInfoArgs = {
  userId: Scalars['ID'];
};

export type QueryProgressReportByIdArgs = {
  id: Scalars['ID'];
};

export type QueryUserTranslationArgs = {
  input: UserTranslationInput;
};

export type QueryVectorUserTranslationArgs = {
  input: VectorUserTranslationInput;
};

export type QueryUserTranslationVariationArgs = {
  variationId: Scalars['String'];
};

export type QueryRemovedSourcePhrasesArgs = {
  input: RemovedSourcePhrasesInput;
};

export type QueryUserTranslationsWithinArgs = {
  input: UserTranslationsWithinInput;
};

export type QueryUserTranslationProgressChartArgs = {
  type?: Maybe<ProgressChartType>;
};

export type QueryUserTranslationsByPhraseVectorSetArgs = {
  input: UserTranslationsByPhraseVectorSetInput;
};

export type QueryUserTranslationsByVariationIdsArgs = {
  input: UserTranslationsByVariationIdsInput;
};

export type QueryGetTranslationInaccuraciesArgs = {
  language: LanguageId;
};

export type QueryWebPageTranslationArgs = {
  input: WebPageQueryInput;
};

export type QueryWebPageTranslationsArgs = {
  filter?: Maybe<WebPagesSearchInput>;
};

export type QueryWordListArgs = {
  input: WordListInput;
};

export type QueryGetSubscriptionProductArgs = {
  productId: Scalars['ID'];
};

export type QueryGetUsersPremiumProductArgs = {
  userId: Scalars['ID'];
};

export type QueryGetSubscriptionProductsArgs = {
  filters: SubscriptionProductFilters;
};

export type RandomPracticeQuestionInput = {
  supportedQuestionTypes?: Maybe<Array<PracticeType>>;
  variationId: Scalars['String'];
};

export type RandomQuizQuestionInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type Rating = {
  __typename?: 'Rating';
  ratingValue: Scalars['Int'];
  ratedAt: Scalars['DateTime'];
};

export type RatingInput = {
  ratingValue: Scalars['Int'];
};

export type RecentUsers = {
  __typename?: 'RecentUsers';
  users: Array<Maybe<User>>;
  count: Scalars['Int'];
  since: Scalars['DateTime'];
};

export type RecentUsersSearchInput = {
  createdSince: Scalars['DateTime'];
  source?: Maybe<Scalars['String']>;
  excludeSource?: Maybe<Scalars['String']>;
  minWordCount?: Maybe<Scalars['Int']>;
  registrationStatus?: Maybe<RegistrationStatus>;
};

export type RecordTranslationAudioPlayInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
};

export type RecordTranslationViewsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type RecordVariationGamePointsInput = {
  variationId: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type RecordVariationViewsInput = {
  variationId: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type RecordVectorTranslationAudioPlayInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type RecordVectorTranslationGamePointsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type RecordVectorTranslationViewsInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  incrementalViews: Scalars['Int'];
};

export type ReferralInfo = UserReferralInfo & {
  __typename?: 'ReferralInfo';
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo: Array<EmailShareInfo>;
  rewardInfo: RewardInfo;
};

export type ReferralRewardInfoInput = {
  rewardType: Scalars['String'];
};

export type ReferralRewardInfoMetadataInput = {
  schoolDonation?: Maybe<SchoolDonationRewardMetadataInput>;
};

export type ReferralRewardSummary = {
  __typename?: 'ReferralRewardSummary';
  accountCreditRewards?: Maybe<AccountCreditRewardSummary>;
  schoolDonationRewards?: Maybe<SchoolDonationRewardSummary>;
};

export type ReferralUserInfo = {
  __typename?: 'ReferralUserInfo';
  redeemCode: Scalars['String'];
  isAnonymous: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
};

export type ReferredUserInfo = {
  __typename?: 'ReferredUserInfo';
  name?: Maybe<Scalars['String']>;
};

export type ReferUsersByEmailResponse = {
  __typename?: 'ReferUsersByEmailResponse';
  referral: ReferralInfo;
};

export type RegisterOptions = {
  redeemCode?: Maybe<Scalars['String']>;
  isUnsubscribedToEmails?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
  countryCode?: Maybe<Scalars['String']>;
  options?: Maybe<RegisterOptions>;
};

export enum RegistrationStatus {
  Anonymous = 'anonymous',
  Registered = 'registered',
}

export type RemovedSourcePhrasesInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type RemoveSourcePhraseInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
};

export type RemoveVariationFromWordListInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  variationId: Scalars['String'];
};

export type RemoveWordFromVectoredWordListInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type RemoveWordFromWordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type ResetPasswordReturn = {
  __typename?: 'ResetPasswordReturn';
  jwt?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
};

export type RewardInfo = {
  rewardType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SaveLanguageSelectionInput = {
  email: Scalars['String'];
  languageSelection: InDevLanguageId;
  otherLanguage?: Maybe<Scalars['String']>;
};

export type SaveTranslationIsFluentInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveTranslationIsKnownInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SaveTranslationIsRemovedInput = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  isRemoved: Scalars['Boolean'];
};

export type SaveUserLearningShortcutIndicatorInput = {
  learningShortcutId: Scalars['String'];
  indicator: Scalars['String'];
};

export type SaveUserLearningShortcutSettingInput = {
  learningShortcutId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type SaveVariationIsFluentInput = {
  variationId: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveVariationIsKnownInput = {
  variationId: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SaveVectorTranslationIsFluentInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  isFluent: Scalars['Boolean'];
};

export type SaveVectorTranslationIsKnownInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  isKnown: Scalars['Boolean'];
};

export type SchoolDonationRedemptionReward = {
  __typename?: 'SchoolDonationRedemptionReward';
  id: Scalars['ID'];
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
  referredUser?: Maybe<ReferredUserInfo>;
  createdAt: Scalars['DateTime'];
  redeemedAt: Scalars['DateTime'];
};

export type SchoolDonationRewardInfo = RewardInfo & {
  __typename?: 'SchoolDonationRewardInfo';
  rewardType: Scalars['String'];
  metadata: SchoolDonationRewardMetadata;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SchoolDonationRewardMetadata = {
  __typename?: 'SchoolDonationRewardMetadata';
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
};

export type SchoolDonationRewardMetadataInput = {
  schoolName: Scalars['String'];
  schoolLocation: Scalars['String'];
};

export type SchoolDonationRewardSummary = {
  __typename?: 'SchoolDonationRewardSummary';
  rewardType: Scalars['String'];
  rewards: Array<SchoolDonationRedemptionReward>;
};

export type Sentence = {
  __typename?: 'Sentence';
  text?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  fleschKincaidReadingEase?: Maybe<Scalars['Float']>;
  fleschKincaidGradeLevel?: Maybe<Scalars['Float']>;
};

export type SetRedisKeyInput = {
  prefix: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SignUpOptions = {
  redeemCode?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourcePhraseMultipleChoiceExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'SourcePhraseMultipleChoiceExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type SourcePhraseSwapPracticeQuestion = PracticeQuestion & {
  __typename?: 'SourcePhraseSwapPracticeQuestion';
  questionType: PracticeType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type SourcePhraseSwapQuestion = FluencyQuizQuestion & {
  __typename?: 'SourcePhraseSwapQuestion';
  questionType: FluencyQuizType;
  sourcePhrase: Scalars['String'];
  targetPhraseAnswer: Scalars['String'];
  targetPhraseOptions: Array<Scalars['String']>;
};

export type StripeCardInfo = {
  __typename?: 'StripeCardInfo';
  id: Scalars['String'];
  brand: Scalars['String'];
  lastFour: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type StripeCustomerPortal = {
  __typename?: 'StripeCustomerPortal';
  url: Scalars['String'];
};

export type StripePrice = {
  __typename?: 'StripePrice';
  stripePriceId: Scalars['String'];
  currency: Scalars['String'];
  unitAmount: Scalars['Int'];
  interval: Scalars['String'];
  intervalCount: Scalars['Int'];
  trialPeriodDays?: Maybe<Scalars['Int']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  subscriptionId: Scalars['String'];
  status: Scalars['String'];
  billingInterval: Scalars['String'];
  nextBillingDate: Scalars['Int'];
  billingAmountDecimal: Scalars['Float'];
};

export enum StripeSubscriptionStatusFilter {
  Canceled = 'canceled',
}

export enum SubmissionType {
  Contact = 'contact',
  DeleteAccount = 'deleteAccount',
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['String']>;
};

export enum SubscriptionPaymentProvider {
  Stripe = 'Stripe',
  Apple = 'Apple',
  PayPal = 'PayPal',
  ToucanPromo = 'ToucanPromo',
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  externalIds: Array<ExternalPlanId>;
  status: SubscriptionPlanStatus;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  price: PlanPrice;
  recurringInterval: Interval;
  locale: Scalars['String'];
  trialPeriod?: Maybe<TrialPeriodInfo>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPlanFilters = {
  locale?: Maybe<Scalars['String']>;
  productProvider?: Maybe<ProductProvider>;
};

export enum SubscriptionPlanStatus {
  Active = 'active',
  Archived = 'archived',
}

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  id: Scalars['ID'];
  externalIds: Array<ExternalId>;
  name: Scalars['String'];
  nameTranslationKey: Scalars['String'];
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  plans: Array<SubscriptionPlan>;
  addOns: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionProductPlansArgs = {
  filters?: Maybe<SubscriptionPlanFilters>;
};

export type SubscriptionProductFilters = {
  locale?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type SwapPracticeQuestion = PracticeQuestion & {
  __typename?: 'SwapPracticeQuestion';
  questionType: PracticeType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
  answerVariationId: Scalars['String'];
};

export type SwapQuestion = FluencyQuizQuestion & {
  __typename?: 'SwapQuestion';
  questionType: FluencyQuizType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
};

export type SyncPaypalSubscriptionsInput = {
  limit: Scalars['Int'];
  updatedBefore: Scalars['DateTime'];
};

export type SyncStripeSubscriptionsInput = {
  limit: Scalars['Int'];
  status: StripeSubscriptionStatusFilter;
  updatedBefore: Scalars['DateTime'];
};

export type TargetPhraseMultipleChoice = ContextlessLearning & {
  __typename?: 'TargetPhraseMultipleChoice';
  learningType: ContextlessLearningType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  sourcePhraseOptions: Array<Scalars['String']>;
  variationId: Scalars['String'];
};

export type TargetPhraseMultipleChoiceExtraPracticeQuestion = ExtraPracticeQuestion & {
  __typename?: 'TargetPhraseMultipleChoiceExtraPracticeQuestion';
  questionType: ExtraPracticeQuestionType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourcePhraseOptions: Array<Scalars['String']>;
  answerHomonymRecordId: Scalars['String'];
};

export type TitleImage = {
  __typename?: 'TitleImage';
  titleImageUrl: Scalars['String'];
  titleImageAltText: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  text?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  pos?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  dependencyArc?: Maybe<DependencyArc>;
};

export type Tokens = {
  __typename?: 'Tokens';
  id: Scalars['String'];
  jwt: Scalars['String'];
  refreshToken: Scalars['String'];
  userId: Scalars['String'];
  accessToken: Scalars['String'];
  expiresInPeriod: Scalars['String'];
  isNewUser?: Maybe<Scalars['Boolean']>;
};

export type Translation = {
  __typename?: 'Translation';
  englishWord?: Maybe<Scalars['String']>;
  lexicon?: Maybe<Array<Maybe<Lexicon>>>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TranslationGamePointsInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
  pointDifference?: Maybe<Scalars['Int']>;
};

export type TranslationInaccuracies = {
  __typename?: 'TranslationInaccuracies';
  errorType: Scalars['String'];
  translationId: Scalars['String'];
  language: LanguageId;
  reportedUrl: Scalars['String'];
  translatedPhrase: Scalars['String'];
  word: Scalars['String'];
  sentenceWithoutTranslation: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
};

export type TranslationInaccuraciesInput = {
  word: Scalars['String'];
  translatedPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  sentenceWithTranslation: Scalars['String'];
  sentenceWithoutTranslation: Scalars['String'];
  language: LanguageId;
  reportedUrl: Scalars['String'];
  errorType: TranslationInaccuracyError;
  translationId: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
};

export enum TranslationInaccuracyError {
  Misspelling = 'misspelling',
  Grammar = 'grammar',
  Context = 'context',
}

export type TranslationInfo = {
  __typename?: 'TranslationInfo';
  id: Scalars['String'];
  homonyms: Array<Homonym>;
  sourcePhrase: Scalars['String'];
};

export type TranslationPhraseVectorInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type TranslationsDataSet = {
  __typename?: 'TranslationsDataSet';
  url: Scalars['String'];
};

export type TranslationsDataSetInput = {
  language: LanguageId;
};

export type TranslationsDataSetV3Input = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
  useCdn?: Maybe<Scalars['Boolean']>;
};

export type TranslationsDataSetV4Input = {
  targetLanguage: LanguageId;
  sourceLanguage: LanguageId;
};

export enum TranslationStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Inactive = 'Inactive',
}

export type TranslationViewCount = {
  translation: Scalars['String'];
  newViewCount: Scalars['Int'];
};

export type TranslationViewCountInput = {
  language?: Maybe<LanguageId>;
  sourceLanguage?: Maybe<LanguageId>;
  translationViewCounts: Array<TranslationViewCount>;
};

export type TranslationWithGenderFormsAndPrefixes = {
  __typename?: 'TranslationWithGenderFormsAndPrefixes';
  _com?: Maybe<Scalars['String']>;
  _fem?: Maybe<Scalars['String']>;
  _masc?: Maybe<Scalars['String']>;
  a?: Maybe<Scalars['String']>;
  an?: Maybe<Scalars['String']>;
  her?: Maybe<Scalars['String']>;
  his?: Maybe<Scalars['String']>;
  my?: Maybe<Scalars['String']>;
  our?: Maybe<Scalars['String']>;
  the?: Maybe<Scalars['String']>;
  their?: Maybe<Scalars['String']>;
  your?: Maybe<Scalars['String']>;
};

export type TrialPeriodInfo = {
  __typename?: 'TrialPeriodInfo';
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  interval: Interval;
};

export type TrialPeriodInput = {
  description: Scalars['String'];
  descriptionTranslationKey: Scalars['String'];
  interval: IntervalInput;
};

export type UpdatedUserPersonalization = {
  __typename?: 'UpdatedUserPersonalization';
  selectedLanguage?: Maybe<Scalars['String']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  sourceLanguage?: Maybe<LanguageId>;
};

export type UpdateExampleSentencesInput = {
  forceUpdate: Scalars['Boolean'];
};

export type UpdateExtensionStatResult = {
  __typename?: 'UpdateExtensionStatResult';
  success: Scalars['Boolean'];
  extensionStatId: Scalars['ID'];
  extensionStat?: Maybe<ExtensionStat>;
};

export type UpdateLastSeenProgressionScoreInput = {
  variationId: Scalars['String'];
  lastSeenProgressionScore: Scalars['Int'];
};

export type UpdatePromotionInput = {
  description?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  eligibility?: Maybe<PromotionEligibility>;
  period?: Maybe<PromotionDuration>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateSubscriptionPlanInput = {
  name?: Maybe<Scalars['String']>;
  nameTranslationKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionTranslationKey?: Maybe<Scalars['String']>;
  trialPeriod?: Maybe<TrialPeriodInput>;
};

export type UpdateSubscriptionProductInput = {
  externalIds?: Maybe<Array<ExternalIdInput>>;
  name?: Maybe<Scalars['String']>;
  nameTranslationKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionTranslationKey?: Maybe<Scalars['String']>;
  addOns?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserPersonalizationInput = {
  language?: Maybe<Scalars['String']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  sourceLanguage?: Maybe<LanguageId>;
};

export type User = {
  __typename?: 'User';
  onboardingChecklist?: Maybe<OnboardingChecklist>;
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  createdAt: Scalars['String'];
  isPro?: Maybe<Scalars['Boolean']>;
  isPremiumSubscribed: Scalars['Boolean'];
  hasBeenPremiumSubscribed: Scalars['Boolean'];
  wordCount?: Maybe<Scalars['String']>;
  selectedLanguage?: Maybe<Scalars['String']>;
  selectedLanguageVariant?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<Scalars['String']>;
  hasGrantedExtensionPermissions?: Maybe<Scalars['Boolean']>;
  hasFullyOnboarded?: Maybe<Scalars['Boolean']>;
  isDarkModeEnabled?: Maybe<Scalars['Boolean']>;
  isContextlessLearningMomentEnabled?: Maybe<Scalars['Boolean']>;
  registrationStatus?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  experiments: Array<Experiment>;
  customerID?: Maybe<Scalars['String']>;
  isExternallyAuthed: Scalars['Boolean'];
  hasSavedFirstWord: Scalars['Boolean'];
  translationInaccuracies: Array<TranslationInaccuracies>;
  premiumSubscription?: Maybe<PremiumSubscription>;
  rating?: Maybe<Rating>;
  hasCompletedFluencyCheck: Scalars['Boolean'];
  hasCompletedPracticeQuiz: Scalars['Boolean'];
  hasCompletedContextualizedOnboarding: Scalars['Boolean'];
  isUnsubscribedToEmails: Scalars['Boolean'];
  hasInitialLanguageBeenSelected: Scalars['Boolean'];
  isAlwaysTranslateSavedWordsEnabled: Scalars['Boolean'];
  referral?: Maybe<ReferralInfo>;
  referralRewardSummary?: Maybe<ReferralRewardSummary>;
  countryCode?: Maybe<Scalars['String']>;
  audioPreferences?: Maybe<UserAudioPreferences>;
  learningShortcuts: Array<Maybe<UserLearningShortcut>>;
  learningShortcut?: Maybe<UserLearningShortcut>;
  learningShortcutTopic: UserLearningShortcutTopicProgress;
  difficultyTier: DifficultyTier;
  densityLevel: Scalars['Float'];
  difficultyWeights: Array<DifficultyWeight>;
};

export type UserLearningShortcutArgs = {
  input?: Maybe<UserLearningShortcutInput>;
};

export type UserLearningShortcutTopicArgs = {
  input?: Maybe<UserLearningShortcutTopicInput>;
};

export type UserAccountCredits = {
  __typename?: 'UserAccountCredits';
  userId: Scalars['ID'];
  totalCredits: Scalars['Float'];
  totalAvailableCredits: Scalars['Float'];
  ledger: Array<AccountCreditLedgerRecord>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAudioPreferences = {
  __typename?: 'UserAudioPreferences';
  playbackRate: Scalars['Float'];
  voiceNumber: Scalars['Int'];
};

export type UserBlacklistedDomains = {
  __typename?: 'UserBlacklistedDomains';
  blacklist?: Maybe<Array<Scalars['String']>>;
};

export type UserDetailsNonSensitive = {
  __typename?: 'UserDetailsNonSensitive';
  id: Scalars['ID'];
  name: Scalars['String'];
  registrationStatus: Scalars['String'];
};

export type UserHomonym = {
  __typename?: 'UserHomonym';
  homonymRecordId: Scalars['String'];
  homonymSourcePhrase?: Maybe<Scalars['String']>;
  homonymTargetPhrase?: Maybe<Scalars['String']>;
  progressionScore: Scalars['Int'];
  possibleProgressionScore: Scalars['Int'];
  learnedAt?: Maybe<Scalars['DateTime']>;
};

export type UserLearningShortcut = {
  __typename?: 'UserLearningShortcut';
  learningShortcut: LearningShortcut;
  learningShortcutId: Scalars['String'];
  isActive: Scalars['Boolean'];
  focusLearningShortcutTopic?: Maybe<LearningShortcutTopic>;
  indicator?: Maybe<Scalars['String']>;
  userLearningShortcutProgress: UserLearningShortcutProgress;
};

export type UserLearningShortcutInput = {
  learningShortcutId?: Maybe<Scalars['String']>;
};

export type UserLearningShortcutProgress = {
  __typename?: 'UserLearningShortcutProgress';
  completedLearningShortcutPhraseCount: Scalars['Int'];
  possibleLearningShortcutPhraseCount: Scalars['Int'];
  numberOfWordsLearnedToday: Scalars['Int'];
  userLearningShortcutTopicProgress: Array<UserLearningShortcutTopicProgress>;
  hasCelebrated: Scalars['Boolean'];
};

export type UserLearningShortcutTopicInput = {
  learningShortcutTopicId?: Maybe<Scalars['String']>;
};

export type UserLearningShortcutTopicProgress = {
  __typename?: 'UserLearningShortcutTopicProgress';
  learningShortcutTopic: LearningShortcutTopic;
  completedTopicPhraseCount: Scalars['Int'];
  possibleTopicPhraseCount: Scalars['Int'];
  homonyms: Array<UserHomonym>;
};

export type UserReferral = UserReferralInfo & {
  __typename?: 'UserReferral';
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo?: Maybe<Array<EmailShareInfo>>;
  rewardInfo: RewardInfo;
  createdAt: Scalars['DateTime'];
};

export type UserReferralInfo = {
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo?: Maybe<Array<EmailShareInfo>>;
  rewardInfo: RewardInfo;
};

export type UserTranslation = {
  __typename?: 'UserTranslation';
  /** @deprecated use the 'variation' graph to access this value */
  translation: Scalars['String'];
  variationId?: Maybe<Scalars['String']>;
  /** @deprecated use the 'variation' graph to access this value */
  sourceLanguage: LanguageId;
  /** @deprecated use the 'variation' graph to access this value */
  targetLanguage: LanguageId;
  /** @deprecated use the 'variation' graph to access this value */
  targetPhrase: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourcePhrase: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourceRoot: Scalars['String'];
  /** @deprecated use the 'variation' graph to access this value */
  sourcePrefix?: Maybe<Scalars['String']>;
  /** @deprecated use the 'variation' graph to access this value */
  sourceSuffix?: Maybe<Scalars['String']>;
  viewCount: Scalars['Int'];
  audioPlayCount: Scalars['Int'];
  gamePoints: Scalars['Int'];
  isKnown: Scalars['Boolean'];
  /** @deprecated use 'removedSourcePhrases' */
  isRemoved: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  learnedAt?: Maybe<Scalars['DateTime']>;
  fluentAt?: Maybe<Scalars['DateTime']>;
  progressionScore: Scalars['Int'];
  lastSeenProgressionScore?: Maybe<Scalars['Int']>;
  lastPracticedAt?: Maybe<Scalars['DateTime']>;
  variation?: Maybe<UserTranslationVariation>;
};

export type UserTranslationInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  translation: Scalars['String'];
};

export type UserTranslationPostGameInformation = {
  __typename?: 'UserTranslationPostGameInformation';
  language: LanguageId;
  translation: Scalars['String'];
  viewCount: Scalars['Int'];
  beforeGamePoints: Scalars['Int'];
  afterGamePoints: Scalars['Int'];
};

export type UserTranslationsByPhraseVectorSetInput = {
  phraseVectors: Array<TranslationPhraseVectorInput>;
};

export type UserTranslationsByVariationIdsInput = {
  variationIds: Array<Scalars['String']>;
};

export type UserTranslationsForProgression = {
  __typename?: 'UserTranslationsForProgression';
  totalResults: Scalars['Int'];
  userTranslations: Array<UserTranslation>;
};

export enum UserTranslationsWithinFilterType {
  LearnedAt = 'LearnedAt',
  FluentAt = 'FluentAt',
}

export type UserTranslationsWithinInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  filterBy: UserTranslationsWithinFilterType;
  before?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['DateTime']>;
  sortBy?: Maybe<UserTranslationsWithinSortType>;
  lastSeenTranslationCursor?: Maybe<LastSeenTranslationCursorInput>;
  pageSize?: Maybe<Scalars['Int']>;
};

export enum UserTranslationsWithinSortType {
  LastLearned = 'LastLearned',
  FirstLearned = 'FirstLearned',
  LastFluent = 'LastFluent',
  FirstFluent = 'FirstFluent',
}

export type UserTranslationVariation = {
  __typename?: 'UserTranslationVariation';
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  targetPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
};

export type Variation = {
  __typename?: 'Variation';
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  targetPhrase: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  variationId: Scalars['String'];
};

export type VectorUserTranslationInput = {
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export enum WebPageProcessingStatus {
  Queued = 'Queued',
  Inprogress = 'Inprogress',
  Failed = 'Failed',
  Done = 'Done',
  Waiting = 'Waiting',
}

export type WebPageQueryInput = {
  url: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
};

export type WebPageReplacement = {
  __typename?: 'WebPageReplacement';
  address: Scalars['String'];
  translations: Array<Maybe<WebPageTranslation>>;
};

export type WebPagesSearchInput = {
  url?: Maybe<Scalars['String']>;
  sourceLanguage?: Maybe<LanguageId>;
  targetLanguage?: Maybe<LanguageId>;
  limit?: Maybe<Scalars['Int']>;
};

export type WebPageTranslation = {
  __typename?: 'WebPageTranslation';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  instanceOfSourcePhrase?: Maybe<Scalars['Int']>;
};

export type WebPageTranslationsResponse = {
  __typename?: 'WebPageTranslationsResponse';
  url: Scalars['String'];
  sourceLanguage: LanguageId;
  targetLanguage: LanguageId;
  processingStatus: WebPageProcessingStatus;
  replacements: Array<WebPageReplacement>;
  translationStatus: TranslationStatus;
};

export type WebPageVisitInput = {
  url: Scalars['String'];
};

export type Word = {
  __typename?: 'Word';
  language?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WordInfo = {
  __typename?: 'WordInfo';
  word: Scalars['String'];
  translation: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
};

export type WordInfoForGameInput = {
  numWordsDesired: Scalars['Int'];
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  providedWords?: Maybe<Array<Scalars['String']>>;
  wordListId?: Maybe<Scalars['String']>;
  maxWordLength?: Maybe<Scalars['Int']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
  requireDefinition?: Maybe<Scalars['Boolean']>;
};

export type WordList = {
  __typename?: 'WordList';
  id: Scalars['String'];
  size: Scalars['Int'];
  numberLearned: Scalars['Int'];
  words: Array<WordListWord>;
};

export type WordListInput = {
  language: LanguageId;
  sourceLanguage?: Maybe<LanguageId>;
  sortBy?: Maybe<WordListSortType>;
  query?: Maybe<Scalars['String']>;
  lastSeenTranslationCursor?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  wordListCursor?: Maybe<Scalars['String']>;
};

export enum WordListSortType {
  LastSeen = 'LastSeen',
  LeastToMostSeen = 'LeastToMostSeen',
  MostToLeastSeen = 'MostToLeastSeen',
  RecentlyAdded = 'RecentlyAdded',
}

export type WordListWord = {
  __typename?: 'WordListWord';
  _id?: Maybe<Scalars['String']>;
  translation: Scalars['String'];
  viewCount: Scalars['Float'];
  gamePoints: Scalars['Int'];
  isKnown: Scalars['Boolean'];
  englishWord: Scalars['String'];
  sourcePhrase: Scalars['String'];
  pronunciation?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  partOfSpeech?: Maybe<PartOfSpeech>;
  partOfSpeechDisplay?: Maybe<Scalars['String']>;
  learnedAt?: Maybe<Scalars['DateTime']>;
  progressionScore: Scalars['Int'];
  lastPracticedAt?: Maybe<Scalars['DateTime']>;
  fluentAt?: Maybe<Scalars['DateTime']>;
  targetPhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['String']>;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation' } & {
  registerUser: { __typename?: 'Tokens' } & Pick<Tokens, 'id' | 'jwt' | 'refreshToken' | 'isNewUser'>;
};

export type LogInUserMutationVariables = Exact<{
  input: LogInUserInput;
}>;

export type LogInUserMutation = { __typename?: 'Mutation' } & {
  logInUser: { __typename?: 'Tokens' } & Pick<Tokens, 'id' | 'jwt' | 'refreshToken' | 'isNewUser'>;
};

export type AuthenticateExternalLoginMutationVariables = Exact<{
  input: AuthenticateExternalLoginInput;
}>;

export type AuthenticateExternalLoginMutation = { __typename?: 'Mutation' } & {
  authenticateExternalLogin: { __typename?: 'Tokens' } & Pick<Tokens, 'id' | 'jwt' | 'refreshToken' | 'isNewUser'>;
};

export type ExtensionStatQueryVariables = Exact<{ [key: string]: never }>;

export type ExtensionStatQuery = { __typename?: 'Query' } & {
  extensionStat?: Maybe<{ __typename?: 'extensionStat' } & Pick<ExtensionStat, 'users' | 'reviews'>>;
};

export type GetLanguageLeaderboardQueryVariables = Exact<{ [key: string]: never }>;

export type GetLanguageLeaderboardQuery = { __typename?: 'Query' } & {
  languageLeaderboard: Array<
    { __typename?: 'LanguageLeaderboardItem' } & Pick<LanguageLeaderboardItem, 'languageId' | 'votes'>
  >;
};

export type SaveUsersLanguageMutationVariables = Exact<{
  input: LanguageSettingsInput;
}>;

export type SaveUsersLanguageMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'saveLanguageSettings'>;

export type IsResetTokenValidQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type IsResetTokenValidQuery = { __typename?: 'Query' } & Pick<Query, 'isResetTokenValid'>;

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword?: Maybe<{ __typename?: 'ResetPasswordReturn' } & Pick<ResetPasswordReturn, 'jwt' | 'refreshToken'>>;
};

export type GetReferrerQueryVariables = Exact<{
  redeemCode: Scalars['String'];
}>;

export type GetReferrerQuery = { __typename?: 'Query' } & {
  getReferrer: { __typename?: 'ReferralUserInfo' } & Pick<ReferralUserInfo, 'name' | 'isAnonymous' | 'targetLanguage'>;
};

export type UserReferralInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserReferralInfoQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & Pick<User, 'countryCode'> & {
        referral?: Maybe<
          { __typename?: 'ReferralInfo' } & {
            rewardInfo:
              | ({ __typename?: 'AccountCreditRewardInfo' } & Pick<AccountCreditRewardInfo, 'rewardType'> & {
                    metadata: { __typename?: 'AccountCreditRewardMetadata' } & Pick<
                      AccountCreditRewardMetadata,
                      'amount' | 'currencyCode'
                    >;
                  })
              | ({ __typename?: 'SchoolDonationRewardInfo' } & Pick<SchoolDonationRewardInfo, 'rewardType'> & {
                    metadata: { __typename?: 'SchoolDonationRewardMetadata' } & Pick<
                      SchoolDonationRewardMetadata,
                      'schoolId' | 'schoolName'
                    >;
                  });
          }
        >;
        referralRewardSummary?: Maybe<
          { __typename?: 'ReferralRewardSummary' } & {
            accountCreditRewards?: Maybe<
              { __typename?: 'AccountCreditRewardSummary' } & Pick<
                AccountCreditRewardSummary,
                'totalAvailableCredits' | 'creditCurrencyCode' | 'rewardType'
              > & {
                  rewards: Array<
                    { __typename?: 'AccountCreditRedemptionReward' } & Pick<
                      AccountCreditRedemptionReward,
                      'amount' | 'currencyCode'
                    >
                  >;
                }
            >;
            schoolDonationRewards?: Maybe<
              { __typename?: 'SchoolDonationRewardSummary' } & Pick<SchoolDonationRewardSummary, 'rewardType'> & {
                  rewards: Array<
                    { __typename?: 'SchoolDonationRedemptionReward' } & Pick<
                      SchoolDonationRedemptionReward,
                      'schoolName'
                    >
                  >;
                }
            >;
          }
        >;
      }
  >;
};

export type SetUserReferralRewardInfoMetadataMutationVariables = Exact<{
  input: ReferralRewardInfoMetadataInput;
}>;

export type SetUserReferralRewardInfoMetadataMutation = { __typename?: 'Mutation' } & {
  setUserReferralRewardInfoMetadata: { __typename?: 'ReferralInfo' } & {
    rewardInfo:
      | ({ __typename?: 'AccountCreditRewardInfo' } & Pick<AccountCreditRewardInfo, 'rewardType'>)
      | ({ __typename?: 'SchoolDonationRewardInfo' } & Pick<SchoolDonationRewardInfo, 'rewardType'>);
  };
};

export type GetLearningShortcutsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLearningShortcutsQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & {
      learningShortcuts: Array<
        Maybe<
          { __typename?: 'UserLearningShortcut' } & Pick<UserLearningShortcut, 'learningShortcutId'> & {
              learningShortcut: { __typename?: 'LearningShortcut' } & Pick<
                LearningShortcut,
                'name' | 'infoTitle' | 'infoDetails'
              > & {
                  badgeImage: { __typename?: 'BadgeImage' } & Pick<BadgeImage, 'badgeImageUrl' | 'badgeImageAltText'>;
                  titleImage: { __typename?: 'TitleImage' } & Pick<TitleImage, 'titleImageUrl' | 'titleImageAltText'>;
                  examplePhrases: Array<
                    { __typename?: 'ExamplePhrase' } & Pick<ExamplePhrase, 'sourcePhrase' | 'targetPhrase'>
                  >;
                };
            }
        >
      >;
    }
  >;
};

export type ActivateLearningShortcutMutationVariables = Exact<{
  input: SaveUserLearningShortcutSettingInput;
}>;

export type ActivateLearningShortcutMutation = { __typename?: 'Mutation' } & {
  saveUserLearningShortcutSetting: { __typename?: 'UserLearningShortcut' } & Pick<UserLearningShortcut, 'isActive'>;
};

export type DoesCurrentLanguageVectorSupportShortcutsQueryVariables = Exact<{
  input: LearningShortcutsInput;
}>;

export type DoesCurrentLanguageVectorSupportShortcutsQuery = { __typename?: 'Query' } & {
  learningShortcuts: Array<Maybe<{ __typename?: 'LearningShortcut' } & Pick<LearningShortcut, 'name'>>>;
};

export type GetPremiumStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetPremiumStatusQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'User' } & Pick<User, 'isPremiumSubscribed'>>;
};

export type GetPremiumSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPremiumSubscriptionsQuery = { __typename?: 'Query' } & {
  getPremiumSubscription?: Maybe<
    { __typename?: 'PremiumSubscription' } & Pick<
      PremiumSubscription,
      | 'stripeSubscriptionId'
      | 'planDisplayName'
      | 'status'
      | 'startDate'
      | 'cancelAtPeriodEnd'
      | 'currentPeriodEnd'
      | 'currentPeriodStart'
      | 'cancelAt'
      | 'trialStart'
      | 'trialEnd'
    > & {
        promoCode?: Maybe<
          { __typename?: 'PublicPromoCode' } & Pick<
            PublicPromoCode,
            'promoName' | 'promoPeriod' | 'isRedeemed' | 'code'
          >
        >;
      }
  >;
  getQueuedPremiumSubscription?: Maybe<{ __typename?: 'PremiumSubscription' } & Pick<PremiumSubscription, 'startDate'>>;
};

export type CreatePremiumSubscriptionMutationVariables = Exact<{
  stripePaymentMethodId: Scalars['String'];
  stripePriceId: Scalars['String'];
  includeFreeTrial?: Maybe<Scalars['Boolean']>;
}>;

export type CreatePremiumSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createPremiumSubscription'
>;

export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationVariables = Exact<{
  payPalSubscriptionId: Scalars['String'];
}>;

export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'syncPremiumSubscriptionWithPayPalSubscriptionId'
>;

export type CancelPremiumPayPalSubscriptionMutationVariables = Exact<{
  payPalSubscriptionId: Scalars['String'];
}>;

export type CancelPremiumPayPalSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'cancelPremiumPayPalSubscription'
>;

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'email'
      | 'name'
      | 'role'
      | 'isPro'
      | 'countryCode'
      | 'isPremiumSubscribed'
      | 'hasBeenPremiumSubscribed'
      | 'selectedLanguage'
      | 'selectedLanguageVariant'
      | 'hasGrantedExtensionPermissions'
      | 'hasFullyOnboarded'
      | 'isDarkModeEnabled'
      | 'customerID'
      | 'isExternallyAuthed'
      | 'sourceLanguage'
      | 'hasInitialLanguageBeenSelected'
      | 'hasCompletedContextualizedOnboarding'
    >
  >;
};

export type UpdateUserPersonalizationMutationVariables = Exact<{
  input: UpdateUserPersonalizationInput;
}>;

export type UpdateUserPersonalizationMutation = { __typename?: 'Mutation' } & {
  updateUserPersonalization?: Maybe<
    { __typename?: 'UpdatedUserPersonalization' } & Pick<
      UpdatedUserPersonalization,
      'selectedLanguage' | 'isDarkModeEnabled'
    >
  >;
};

export type LostPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type LostPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'lostPassword'>;

export type SaveLanguageLearningMotivationMutationVariables = Exact<{
  input: LanguageLearningMotivationInput;
}>;

export type SaveLanguageLearningMotivationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveLanguageLearningMotivation'
>;

export const RegisterUserDocument = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      id
      jwt
      refreshToken
      isNewUser
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const LogInUserDocument = gql`
  mutation logInUser($input: LogInUserInput!) {
    logInUser(input: $input) {
      id
      jwt
      refreshToken
      isNewUser
    }
  }
`;
export type LogInUserMutationFn = Apollo.MutationFunction<LogInUserMutation, LogInUserMutationVariables>;

/**
 * __useLogInUserMutation__
 *
 * To run a mutation, you first call `useLogInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInUserMutation, { data, loading, error }] = useLogInUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogInUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LogInUserMutation, LogInUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogInUserMutation, LogInUserMutationVariables>(LogInUserDocument, options);
}
export type LogInUserMutationHookResult = ReturnType<typeof useLogInUserMutation>;
export type LogInUserMutationResult = Apollo.MutationResult<LogInUserMutation>;
export type LogInUserMutationOptions = Apollo.BaseMutationOptions<LogInUserMutation, LogInUserMutationVariables>;
export const AuthenticateExternalLoginDocument = gql`
  mutation authenticateExternalLogin($input: AuthenticateExternalLoginInput!) {
    authenticateExternalLogin(input: $input) {
      id
      jwt
      refreshToken
      isNewUser
    }
  }
`;
export type AuthenticateExternalLoginMutationFn = Apollo.MutationFunction<
  AuthenticateExternalLoginMutation,
  AuthenticateExternalLoginMutationVariables
>;

/**
 * __useAuthenticateExternalLoginMutation__
 *
 * To run a mutation, you first call `useAuthenticateExternalLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateExternalLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateExternalLoginMutation, { data, loading, error }] = useAuthenticateExternalLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateExternalLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateExternalLoginMutation,
    AuthenticateExternalLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthenticateExternalLoginMutation, AuthenticateExternalLoginMutationVariables>(
    AuthenticateExternalLoginDocument,
    options,
  );
}
export type AuthenticateExternalLoginMutationHookResult = ReturnType<typeof useAuthenticateExternalLoginMutation>;
export type AuthenticateExternalLoginMutationResult = Apollo.MutationResult<AuthenticateExternalLoginMutation>;
export type AuthenticateExternalLoginMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateExternalLoginMutation,
  AuthenticateExternalLoginMutationVariables
>;
export const ExtensionStatDocument = gql`
  query extensionStat {
    extensionStat {
      users
      reviews
    }
  }
`;

/**
 * __useExtensionStatQuery__
 *
 * To run a query within a React component, call `useExtensionStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtensionStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtensionStatQuery({
 *   variables: {
 *   },
 * });
 */
export function useExtensionStatQuery(
  baseOptions?: Apollo.QueryHookOptions<ExtensionStatQuery, ExtensionStatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExtensionStatQuery, ExtensionStatQueryVariables>(ExtensionStatDocument, options);
}
export function useExtensionStatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExtensionStatQuery, ExtensionStatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExtensionStatQuery, ExtensionStatQueryVariables>(ExtensionStatDocument, options);
}
export type ExtensionStatQueryHookResult = ReturnType<typeof useExtensionStatQuery>;
export type ExtensionStatLazyQueryHookResult = ReturnType<typeof useExtensionStatLazyQuery>;
export type ExtensionStatQueryResult = Apollo.QueryResult<ExtensionStatQuery, ExtensionStatQueryVariables>;
export const GetLanguageLeaderboardDocument = gql`
  query getLanguageLeaderboard {
    languageLeaderboard {
      languageId
      votes
    }
  }
`;

/**
 * __useGetLanguageLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetLanguageLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguageLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguageLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguageLeaderboardQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLanguageLeaderboardQuery, GetLanguageLeaderboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLanguageLeaderboardQuery, GetLanguageLeaderboardQueryVariables>(
    GetLanguageLeaderboardDocument,
    options,
  );
}
export function useGetLanguageLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLanguageLeaderboardQuery, GetLanguageLeaderboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLanguageLeaderboardQuery, GetLanguageLeaderboardQueryVariables>(
    GetLanguageLeaderboardDocument,
    options,
  );
}
export type GetLanguageLeaderboardQueryHookResult = ReturnType<typeof useGetLanguageLeaderboardQuery>;
export type GetLanguageLeaderboardLazyQueryHookResult = ReturnType<typeof useGetLanguageLeaderboardLazyQuery>;
export type GetLanguageLeaderboardQueryResult = Apollo.QueryResult<
  GetLanguageLeaderboardQuery,
  GetLanguageLeaderboardQueryVariables
>;
export const SaveUsersLanguageDocument = gql`
  mutation saveUsersLanguage($input: LanguageSettingsInput!) {
    saveLanguageSettings(input: $input)
  }
`;
export type SaveUsersLanguageMutationFn = Apollo.MutationFunction<
  SaveUsersLanguageMutation,
  SaveUsersLanguageMutationVariables
>;

/**
 * __useSaveUsersLanguageMutation__
 *
 * To run a mutation, you first call `useSaveUsersLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUsersLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUsersLanguageMutation, { data, loading, error }] = useSaveUsersLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUsersLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveUsersLanguageMutation, SaveUsersLanguageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveUsersLanguageMutation, SaveUsersLanguageMutationVariables>(
    SaveUsersLanguageDocument,
    options,
  );
}
export type SaveUsersLanguageMutationHookResult = ReturnType<typeof useSaveUsersLanguageMutation>;
export type SaveUsersLanguageMutationResult = Apollo.MutationResult<SaveUsersLanguageMutation>;
export type SaveUsersLanguageMutationOptions = Apollo.BaseMutationOptions<
  SaveUsersLanguageMutation,
  SaveUsersLanguageMutationVariables
>;
export const IsResetTokenValidDocument = gql`
  query isResetTokenValid($token: String!) {
    isResetTokenValid(token: $token)
  }
`;

/**
 * __useIsResetTokenValidQuery__
 *
 * To run a query within a React component, call `useIsResetTokenValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsResetTokenValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsResetTokenValidQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsResetTokenValidQuery(
  baseOptions: Apollo.QueryHookOptions<IsResetTokenValidQuery, IsResetTokenValidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsResetTokenValidQuery, IsResetTokenValidQueryVariables>(IsResetTokenValidDocument, options);
}
export function useIsResetTokenValidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsResetTokenValidQuery, IsResetTokenValidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsResetTokenValidQuery, IsResetTokenValidQueryVariables>(
    IsResetTokenValidDocument,
    options,
  );
}
export type IsResetTokenValidQueryHookResult = ReturnType<typeof useIsResetTokenValidQuery>;
export type IsResetTokenValidLazyQueryHookResult = ReturnType<typeof useIsResetTokenValidLazyQuery>;
export type IsResetTokenValidQueryResult = Apollo.QueryResult<IsResetTokenValidQuery, IsResetTokenValidQueryVariables>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      jwt
      refreshToken
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const GetReferrerDocument = gql`
  query getReferrer($redeemCode: String!) {
    getReferrer(redeemCode: $redeemCode) {
      name
      isAnonymous
      targetLanguage
    }
  }
`;

/**
 * __useGetReferrerQuery__
 *
 * To run a query within a React component, call `useGetReferrerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferrerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferrerQuery({
 *   variables: {
 *      redeemCode: // value for 'redeemCode'
 *   },
 * });
 */
export function useGetReferrerQuery(baseOptions: Apollo.QueryHookOptions<GetReferrerQuery, GetReferrerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferrerQuery, GetReferrerQueryVariables>(GetReferrerDocument, options);
}
export function useGetReferrerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferrerQuery, GetReferrerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferrerQuery, GetReferrerQueryVariables>(GetReferrerDocument, options);
}
export type GetReferrerQueryHookResult = ReturnType<typeof useGetReferrerQuery>;
export type GetReferrerLazyQueryHookResult = ReturnType<typeof useGetReferrerLazyQuery>;
export type GetReferrerQueryResult = Apollo.QueryResult<GetReferrerQuery, GetReferrerQueryVariables>;
export const UserReferralInfoDocument = gql`
  query userReferralInfo {
    me {
      countryCode
      referral {
        rewardInfo {
          ... on AccountCreditRewardInfo {
            rewardType
            metadata {
              amount
              currencyCode
            }
          }
          ... on SchoolDonationRewardInfo {
            rewardType
            metadata {
              schoolId
              schoolName
            }
          }
        }
      }
      referralRewardSummary {
        accountCreditRewards {
          totalAvailableCredits
          creditCurrencyCode
          rewardType
          rewards {
            amount
            currencyCode
          }
        }
        schoolDonationRewards {
          rewardType
          rewards {
            schoolName
          }
        }
      }
    }
  }
`;

/**
 * __useUserReferralInfoQuery__
 *
 * To run a query within a React component, call `useUserReferralInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReferralInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReferralInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReferralInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<UserReferralInfoQuery, UserReferralInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserReferralInfoQuery, UserReferralInfoQueryVariables>(UserReferralInfoDocument, options);
}
export function useUserReferralInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserReferralInfoQuery, UserReferralInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserReferralInfoQuery, UserReferralInfoQueryVariables>(UserReferralInfoDocument, options);
}
export type UserReferralInfoQueryHookResult = ReturnType<typeof useUserReferralInfoQuery>;
export type UserReferralInfoLazyQueryHookResult = ReturnType<typeof useUserReferralInfoLazyQuery>;
export type UserReferralInfoQueryResult = Apollo.QueryResult<UserReferralInfoQuery, UserReferralInfoQueryVariables>;
export const SetUserReferralRewardInfoMetadataDocument = gql`
  mutation setUserReferralRewardInfoMetadata($input: ReferralRewardInfoMetadataInput!) {
    setUserReferralRewardInfoMetadata(input: $input) {
      rewardInfo {
        rewardType
      }
    }
  }
`;
export type SetUserReferralRewardInfoMetadataMutationFn = Apollo.MutationFunction<
  SetUserReferralRewardInfoMetadataMutation,
  SetUserReferralRewardInfoMetadataMutationVariables
>;

/**
 * __useSetUserReferralRewardInfoMetadataMutation__
 *
 * To run a mutation, you first call `useSetUserReferralRewardInfoMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserReferralRewardInfoMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserReferralRewardInfoMetadataMutation, { data, loading, error }] = useSetUserReferralRewardInfoMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserReferralRewardInfoMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserReferralRewardInfoMetadataMutation,
    SetUserReferralRewardInfoMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserReferralRewardInfoMetadataMutation,
    SetUserReferralRewardInfoMetadataMutationVariables
  >(SetUserReferralRewardInfoMetadataDocument, options);
}
export type SetUserReferralRewardInfoMetadataMutationHookResult = ReturnType<
  typeof useSetUserReferralRewardInfoMetadataMutation
>;
export type SetUserReferralRewardInfoMetadataMutationResult = Apollo.MutationResult<SetUserReferralRewardInfoMetadataMutation>;
export type SetUserReferralRewardInfoMetadataMutationOptions = Apollo.BaseMutationOptions<
  SetUserReferralRewardInfoMetadataMutation,
  SetUserReferralRewardInfoMetadataMutationVariables
>;
export const GetLearningShortcutsDocument = gql`
  query getLearningShortcuts {
    me {
      learningShortcuts {
        learningShortcut {
          name
          badgeImage {
            badgeImageUrl
            badgeImageAltText
          }
          titleImage {
            titleImageUrl
            titleImageAltText
          }
          infoTitle
          infoDetails
          examplePhrases {
            sourcePhrase
            targetPhrase
          }
        }
        learningShortcutId
      }
    }
  }
`;

/**
 * __useGetLearningShortcutsQuery__
 *
 * To run a query within a React component, call `useGetLearningShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningShortcutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLearningShortcutsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLearningShortcutsQuery, GetLearningShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningShortcutsQuery, GetLearningShortcutsQueryVariables>(
    GetLearningShortcutsDocument,
    options,
  );
}
export function useGetLearningShortcutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningShortcutsQuery, GetLearningShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningShortcutsQuery, GetLearningShortcutsQueryVariables>(
    GetLearningShortcutsDocument,
    options,
  );
}
export type GetLearningShortcutsQueryHookResult = ReturnType<typeof useGetLearningShortcutsQuery>;
export type GetLearningShortcutsLazyQueryHookResult = ReturnType<typeof useGetLearningShortcutsLazyQuery>;
export type GetLearningShortcutsQueryResult = Apollo.QueryResult<
  GetLearningShortcutsQuery,
  GetLearningShortcutsQueryVariables
>;
export const ActivateLearningShortcutDocument = gql`
  mutation activateLearningShortcut($input: SaveUserLearningShortcutSettingInput!) {
    saveUserLearningShortcutSetting(input: $input) {
      isActive
    }
  }
`;
export type ActivateLearningShortcutMutationFn = Apollo.MutationFunction<
  ActivateLearningShortcutMutation,
  ActivateLearningShortcutMutationVariables
>;

/**
 * __useActivateLearningShortcutMutation__
 *
 * To run a mutation, you first call `useActivateLearningShortcutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLearningShortcutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLearningShortcutMutation, { data, loading, error }] = useActivateLearningShortcutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateLearningShortcutMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateLearningShortcutMutation, ActivateLearningShortcutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateLearningShortcutMutation, ActivateLearningShortcutMutationVariables>(
    ActivateLearningShortcutDocument,
    options,
  );
}
export type ActivateLearningShortcutMutationHookResult = ReturnType<typeof useActivateLearningShortcutMutation>;
export type ActivateLearningShortcutMutationResult = Apollo.MutationResult<ActivateLearningShortcutMutation>;
export type ActivateLearningShortcutMutationOptions = Apollo.BaseMutationOptions<
  ActivateLearningShortcutMutation,
  ActivateLearningShortcutMutationVariables
>;
export const DoesCurrentLanguageVectorSupportShortcutsDocument = gql`
  query doesCurrentLanguageVectorSupportShortcuts($input: LearningShortcutsInput!) {
    learningShortcuts(input: $input) {
      name
    }
  }
`;

/**
 * __useDoesCurrentLanguageVectorSupportShortcutsQuery__
 *
 * To run a query within a React component, call `useDoesCurrentLanguageVectorSupportShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoesCurrentLanguageVectorSupportShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoesCurrentLanguageVectorSupportShortcutsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDoesCurrentLanguageVectorSupportShortcutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DoesCurrentLanguageVectorSupportShortcutsQuery,
    DoesCurrentLanguageVectorSupportShortcutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DoesCurrentLanguageVectorSupportShortcutsQuery,
    DoesCurrentLanguageVectorSupportShortcutsQueryVariables
  >(DoesCurrentLanguageVectorSupportShortcutsDocument, options);
}
export function useDoesCurrentLanguageVectorSupportShortcutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DoesCurrentLanguageVectorSupportShortcutsQuery,
    DoesCurrentLanguageVectorSupportShortcutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DoesCurrentLanguageVectorSupportShortcutsQuery,
    DoesCurrentLanguageVectorSupportShortcutsQueryVariables
  >(DoesCurrentLanguageVectorSupportShortcutsDocument, options);
}
export type DoesCurrentLanguageVectorSupportShortcutsQueryHookResult = ReturnType<
  typeof useDoesCurrentLanguageVectorSupportShortcutsQuery
>;
export type DoesCurrentLanguageVectorSupportShortcutsLazyQueryHookResult = ReturnType<
  typeof useDoesCurrentLanguageVectorSupportShortcutsLazyQuery
>;
export type DoesCurrentLanguageVectorSupportShortcutsQueryResult = Apollo.QueryResult<
  DoesCurrentLanguageVectorSupportShortcutsQuery,
  DoesCurrentLanguageVectorSupportShortcutsQueryVariables
>;
export const GetPremiumStatusDocument = gql`
  query getPremiumStatus {
    me {
      isPremiumSubscribed
    }
  }
`;

/**
 * __useGetPremiumStatusQuery__
 *
 * To run a query within a React component, call `useGetPremiumStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPremiumStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPremiumStatusQuery, GetPremiumStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPremiumStatusQuery, GetPremiumStatusQueryVariables>(GetPremiumStatusDocument, options);
}
export function useGetPremiumStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPremiumStatusQuery, GetPremiumStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPremiumStatusQuery, GetPremiumStatusQueryVariables>(GetPremiumStatusDocument, options);
}
export type GetPremiumStatusQueryHookResult = ReturnType<typeof useGetPremiumStatusQuery>;
export type GetPremiumStatusLazyQueryHookResult = ReturnType<typeof useGetPremiumStatusLazyQuery>;
export type GetPremiumStatusQueryResult = Apollo.QueryResult<GetPremiumStatusQuery, GetPremiumStatusQueryVariables>;
export const GetPremiumSubscriptionsDocument = gql`
  query getPremiumSubscriptions {
    getPremiumSubscription {
      stripeSubscriptionId
      planDisplayName
      status
      startDate
      cancelAtPeriodEnd
      currentPeriodEnd
      currentPeriodStart
      cancelAt
      promoCode {
        promoName
        promoPeriod
        isRedeemed
        code
      }
      trialStart
      trialEnd
    }
    getQueuedPremiumSubscription {
      startDate
    }
  }
`;

/**
 * __useGetPremiumSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetPremiumSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPremiumSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPremiumSubscriptionsQuery, GetPremiumSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPremiumSubscriptionsQuery, GetPremiumSubscriptionsQueryVariables>(
    GetPremiumSubscriptionsDocument,
    options,
  );
}
export function useGetPremiumSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPremiumSubscriptionsQuery, GetPremiumSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPremiumSubscriptionsQuery, GetPremiumSubscriptionsQueryVariables>(
    GetPremiumSubscriptionsDocument,
    options,
  );
}
export type GetPremiumSubscriptionsQueryHookResult = ReturnType<typeof useGetPremiumSubscriptionsQuery>;
export type GetPremiumSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetPremiumSubscriptionsLazyQuery>;
export type GetPremiumSubscriptionsQueryResult = Apollo.QueryResult<
  GetPremiumSubscriptionsQuery,
  GetPremiumSubscriptionsQueryVariables
>;
export const CreatePremiumSubscriptionDocument = gql`
  mutation createPremiumSubscription(
    $stripePaymentMethodId: String!
    $stripePriceId: String!
    $includeFreeTrial: Boolean
  ) {
    createPremiumSubscription(
      stripePaymentMethodId: $stripePaymentMethodId
      stripePriceId: $stripePriceId
      includeFreeTrial: $includeFreeTrial
    )
  }
`;
export type CreatePremiumSubscriptionMutationFn = Apollo.MutationFunction<
  CreatePremiumSubscriptionMutation,
  CreatePremiumSubscriptionMutationVariables
>;

/**
 * __useCreatePremiumSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreatePremiumSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePremiumSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPremiumSubscriptionMutation, { data, loading, error }] = useCreatePremiumSubscriptionMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *      stripePriceId: // value for 'stripePriceId'
 *      includeFreeTrial: // value for 'includeFreeTrial'
 *   },
 * });
 */
export function useCreatePremiumSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePremiumSubscriptionMutation,
    CreatePremiumSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePremiumSubscriptionMutation, CreatePremiumSubscriptionMutationVariables>(
    CreatePremiumSubscriptionDocument,
    options,
  );
}
export type CreatePremiumSubscriptionMutationHookResult = ReturnType<typeof useCreatePremiumSubscriptionMutation>;
export type CreatePremiumSubscriptionMutationResult = Apollo.MutationResult<CreatePremiumSubscriptionMutation>;
export type CreatePremiumSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreatePremiumSubscriptionMutation,
  CreatePremiumSubscriptionMutationVariables
>;
export const SyncPremiumSubscriptionWithPayPalSubscriptionIdDocument = gql`
  mutation syncPremiumSubscriptionWithPayPalSubscriptionId($payPalSubscriptionId: String!) {
    syncPremiumSubscriptionWithPayPalSubscriptionId(payPalSubscriptionId: $payPalSubscriptionId)
  }
`;
export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationFn = Apollo.MutationFunction<
  SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation,
  SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationVariables
>;

/**
 * __useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation__
 *
 * To run a mutation, you first call `useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPremiumSubscriptionWithPayPalSubscriptionIdMutation, { data, loading, error }] = useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation({
 *   variables: {
 *      payPalSubscriptionId: // value for 'payPalSubscriptionId'
 *   },
 * });
 */
export function useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation,
    SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation,
    SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationVariables
  >(SyncPremiumSubscriptionWithPayPalSubscriptionIdDocument, options);
}
export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationHookResult = ReturnType<
  typeof useSyncPremiumSubscriptionWithPayPalSubscriptionIdMutation
>;
export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationResult = Apollo.MutationResult<SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation>;
export type SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationOptions = Apollo.BaseMutationOptions<
  SyncPremiumSubscriptionWithPayPalSubscriptionIdMutation,
  SyncPremiumSubscriptionWithPayPalSubscriptionIdMutationVariables
>;
export const CancelPremiumPayPalSubscriptionDocument = gql`
  mutation cancelPremiumPayPalSubscription($payPalSubscriptionId: String!) {
    cancelPremiumPayPalSubscription(payPalSubscriptionId: $payPalSubscriptionId)
  }
`;
export type CancelPremiumPayPalSubscriptionMutationFn = Apollo.MutationFunction<
  CancelPremiumPayPalSubscriptionMutation,
  CancelPremiumPayPalSubscriptionMutationVariables
>;

/**
 * __useCancelPremiumPayPalSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelPremiumPayPalSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPremiumPayPalSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPremiumPayPalSubscriptionMutation, { data, loading, error }] = useCancelPremiumPayPalSubscriptionMutation({
 *   variables: {
 *      payPalSubscriptionId: // value for 'payPalSubscriptionId'
 *   },
 * });
 */
export function useCancelPremiumPayPalSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPremiumPayPalSubscriptionMutation,
    CancelPremiumPayPalSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPremiumPayPalSubscriptionMutation, CancelPremiumPayPalSubscriptionMutationVariables>(
    CancelPremiumPayPalSubscriptionDocument,
    options,
  );
}
export type CancelPremiumPayPalSubscriptionMutationHookResult = ReturnType<
  typeof useCancelPremiumPayPalSubscriptionMutation
>;
export type CancelPremiumPayPalSubscriptionMutationResult = Apollo.MutationResult<CancelPremiumPayPalSubscriptionMutation>;
export type CancelPremiumPayPalSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelPremiumPayPalSubscriptionMutation,
  CancelPremiumPayPalSubscriptionMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      id
      email
      name
      role
      isPro
      countryCode
      isPremiumSubscribed
      hasBeenPremiumSubscribed
      selectedLanguage
      selectedLanguageVariant
      hasGrantedExtensionPermissions
      hasFullyOnboarded
      isDarkModeEnabled
      customerID
      isExternallyAuthed
      sourceLanguage
      hasInitialLanguageBeenSelected
      hasCompletedContextualizedOnboarding
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateUserPersonalizationDocument = gql`
  mutation updateUserPersonalization($input: UpdateUserPersonalizationInput!) {
    updateUserPersonalization(input: $input) {
      selectedLanguage
      isDarkModeEnabled
    }
  }
`;
export type UpdateUserPersonalizationMutationFn = Apollo.MutationFunction<
  UpdateUserPersonalizationMutation,
  UpdateUserPersonalizationMutationVariables
>;

/**
 * __useUpdateUserPersonalizationMutation__
 *
 * To run a mutation, you first call `useUpdateUserPersonalizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPersonalizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPersonalizationMutation, { data, loading, error }] = useUpdateUserPersonalizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPersonalizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPersonalizationMutation,
    UpdateUserPersonalizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPersonalizationMutation, UpdateUserPersonalizationMutationVariables>(
    UpdateUserPersonalizationDocument,
    options,
  );
}
export type UpdateUserPersonalizationMutationHookResult = ReturnType<typeof useUpdateUserPersonalizationMutation>;
export type UpdateUserPersonalizationMutationResult = Apollo.MutationResult<UpdateUserPersonalizationMutation>;
export type UpdateUserPersonalizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPersonalizationMutation,
  UpdateUserPersonalizationMutationVariables
>;
export const LostPasswordDocument = gql`
  mutation lostPassword($email: String!) {
    lostPassword(email: $email)
  }
`;
export type LostPasswordMutationFn = Apollo.MutationFunction<LostPasswordMutation, LostPasswordMutationVariables>;

/**
 * __useLostPasswordMutation__
 *
 * To run a mutation, you first call `useLostPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLostPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lostPasswordMutation, { data, loading, error }] = useLostPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLostPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<LostPasswordMutation, LostPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LostPasswordMutation, LostPasswordMutationVariables>(LostPasswordDocument, options);
}
export type LostPasswordMutationHookResult = ReturnType<typeof useLostPasswordMutation>;
export type LostPasswordMutationResult = Apollo.MutationResult<LostPasswordMutation>;
export type LostPasswordMutationOptions = Apollo.BaseMutationOptions<
  LostPasswordMutation,
  LostPasswordMutationVariables
>;
export const SaveLanguageLearningMotivationDocument = gql`
  mutation saveLanguageLearningMotivation($input: LanguageLearningMotivationInput!) {
    saveLanguageLearningMotivation(input: $input)
  }
`;
export type SaveLanguageLearningMotivationMutationFn = Apollo.MutationFunction<
  SaveLanguageLearningMotivationMutation,
  SaveLanguageLearningMotivationMutationVariables
>;

/**
 * __useSaveLanguageLearningMotivationMutation__
 *
 * To run a mutation, you first call `useSaveLanguageLearningMotivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLanguageLearningMotivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLanguageLearningMotivationMutation, { data, loading, error }] = useSaveLanguageLearningMotivationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLanguageLearningMotivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveLanguageLearningMotivationMutation,
    SaveLanguageLearningMotivationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveLanguageLearningMotivationMutation, SaveLanguageLearningMotivationMutationVariables>(
    SaveLanguageLearningMotivationDocument,
    options,
  );
}
export type SaveLanguageLearningMotivationMutationHookResult = ReturnType<
  typeof useSaveLanguageLearningMotivationMutation
>;
export type SaveLanguageLearningMotivationMutationResult = Apollo.MutationResult<SaveLanguageLearningMotivationMutation>;
export type SaveLanguageLearningMotivationMutationOptions = Apollo.BaseMutationOptions<
  SaveLanguageLearningMotivationMutation,
  SaveLanguageLearningMotivationMutationVariables
>;
