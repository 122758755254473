// Initialize Sentry on the browser.
// Used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.ENV,
  dsn: 'https://368589e36a4146ad8afc4e5eca37bd8b@sentry.io/1872985',
  attachStacktrace: true,
  tracesSampleRate: process.env.ENV === 'production' ? 0.05 : 1.0,
});
