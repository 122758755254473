import { useEffect, useState } from 'react';

import { ApolloClient, ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import cookie from 'cookie';
import isMobile from 'ismobilejs';
import { isEmpty, omit } from 'lodash';
import { appWithTranslation } from 'next-i18next';
import * as qs from 'qs';
import Modal from 'react-modal';

import { LocaleProvider } from '~/components/LocaleProvider';
import Providers from '~/components/Providers';
import { GlobalContext } from '~/context/GlobalContext';
import createEmotionCache from '~/helpers/createEmotionCache';
import { getConfig } from '~/helpers/extensionDeviceConfig';
import { replaceBracketStrings } from '~/helpers/utility';
import { createApolloClient } from '~/lib/apollo';
import Config from '~/lib/config';
import { Features } from '~/lib/features';
import getLoggedInUser from '~/lib/getLoggedInUser';
import { ToucanAppProps } from '~/types/next';

Features.init(Config.OPTIMIZELY_SDK_KEY);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = ({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: ToucanAppProps) => {
  const allCookies = cookie.parse(global.document?.cookie || '');
  const isMobileDevice = isMobile(global.navigator?.userAgent).any;
  const { extensionConfig, deviceConfig } = getConfig(global.navigator?.userAgent);

  const client = useState(() => createApolloClient())[0];

  const initialize = async (apolloClient: ApolloClient<object>) => {
    const response = allCookies.token ? await getLoggedInUser(apolloClient) : false;
    if (!response || isEmpty(response)) {
      return;
    }
    const { id, email } = response;
    Sentry.configureScope((scope) => {
      scope.setUser({
        userId: id,
        userEmail: email,
      });
    });
  };
  initialize(client);

  const isExternalAuthRedirect = !!router.query.fromExternalAuth;

  // If the current page is a redirect due to google auth success, remove the query variable and store the value in global context
  if (global.history && isExternalAuthRedirect) {
    // When the pathname is a dynamic URL, it shows the variable name. This replaces that with the value it should contain.
    const pathname = replaceBracketStrings(router.pathname, router.query);
    const pageParams = omit(router.query, ['fromExternalAuth']);
    const pageParamsString = Object.keys(pageParams).length >= 1 ? `?${qs.stringify(pageParams)}` : '';

    global.history.replaceState({}, '', `${pathname}${pageParamsString}`);
  }

  useEffect(() => {
    const root = document.getElementById('__next');
    if (root) {
      Modal.setAppElement(root);
    }
  }, []);

  return (
    <LocaleProvider>
      <ApolloProvider client={client}>
        <GlobalContext
          allCookies={allCookies}
          loggedInUser={false}
          isLoggedInUserLoading
          client={client}
          isLoggedIn={false}
          isMobileDevice={isMobileDevice}
          isExternalAuthRedirect={isExternalAuthRedirect}
          extensionConfig={extensionConfig}
          deviceConfig={deviceConfig}
        >
          <Providers emotionCache={emotionCache}>
            <Component key={router.asPath} {...pageProps} />
          </Providers>
        </GlobalContext>
      </ApolloProvider>
    </LocaleProvider>
  );
};

export default appWithTranslation(MyApp);
