import getConfig from 'next/config';

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
}

export interface Config {
  FAVICON_URL: string;
  GOOGLE_TEXT_TO_SPEECH_KEY: string;
  OPTIMIZELY_LOG_LEVEL: string;
  TOUCAN_OPTIMIZELY_DATAFILE_URL: string;
  OPTIMIZELY_SDK_KEY: string;
  PREMIUM_SUBSCRIPTION_PLAN_ANNUAL: string;
  PREMIUM_SUBSCRIPTION_PLAN_MONTHLY: string;
  SITE: string;
  STRIPE_TOKEN: string;
  TOUCAN_GOOGLE_AUTH_CLIENT_ID: string;
  TOUCAN_APPLE_AUTH_CLIENT_ID: string;
  TOUCAN_GRAPHQL_ENDPOINT: string;
  AZURE_TEXT_TO_SPEECH_KEY: string;
  IP_REGISTRY_GEOLOCATION_KEY: string;
  GOOGLE_AUTH_REDIRECT_URI: string;
  GOOGLE_TAG_MANAGER_ACCOUNT_ID: string;
  APPLE_AUTH_REDIRECT_URI: string;
  APPLE_AUTH_REDIRECT_URI_NGROK?: string;
  PAYPAL_CLIENT_ID: string;
  AMPLITUDE_EXPERIMENT_DEPLOYMENT: string;
  AMPLITUDE_API_KEY: string;
}

const { publicRuntimeConfig } = getConfig();
export const env = (publicRuntimeConfig.ENV || 'development') as Environment;

const APPLE_AUTH_STAGING_CLIENT_ID = 'com.toucan-sign-in-with-apple-service-staging';
const APPLE_AUTH_PRODUCTION_CLIENT_ID = 'com.jointoucan.signin';

const { SITE_URL } = publicRuntimeConfig;

const configs: Record<Environment, Config> = {
  [Environment.Test]: {
    FAVICON_URL: '/images/icons/staging/favicon.ico',
    GOOGLE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.GOOGLE_TEXT_TO_SPEECH_KEY ?? '',
    OPTIMIZELY_LOG_LEVEL: '',
    TOUCAN_OPTIMIZELY_DATAFILE_URL: 'https://stage.toucanapi.com/rollouts/config',
    OPTIMIZELY_SDK_KEY: publicRuntimeConfig.OPTIMIZELY_SDK_KEY ?? '',
    PREMIUM_SUBSCRIPTION_PLAN_ANNUAL: 'Annual',
    PREMIUM_SUBSCRIPTION_PLAN_MONTHLY: 'Monthly',
    SITE: 'https://stage-internal.jointoucan.com',
    STRIPE_TOKEN: 'fake',
    TOUCAN_GOOGLE_AUTH_CLIENT_ID: 'fake',
    TOUCAN_APPLE_AUTH_CLIENT_ID: APPLE_AUTH_STAGING_CLIENT_ID,
    TOUCAN_GRAPHQL_ENDPOINT: 'https://stage.toucanapi.com/graphql',
    AZURE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.AZURE_TEXT_TO_SPEECH_KEY ?? '',
    IP_REGISTRY_GEOLOCATION_KEY: publicRuntimeConfig.IP_REGISTRY_GEOLOCATION_KEY ?? '',
    GOOGLE_AUTH_REDIRECT_URI: 'https://stage-internal.jointoucan.com/auth/google/redirect',
    GOOGLE_TAG_MANAGER_ACCOUNT_ID: 'GTM-PRKLRM4',
    APPLE_AUTH_REDIRECT_URI: 'https://stage-internal.jointoucan.com/auth/apple/process',
    PAYPAL_CLIENT_ID: 'AdtfGaRFaOWfmGzzIqADQD3GHRbqvhJaxEPwoFi0e7ZiQSAki_FsJYnkl4_CJpqjOdEHRtHrtEau_QV3',
    AMPLITUDE_EXPERIMENT_DEPLOYMENT: '',
    AMPLITUDE_API_KEY: '',
  },
  [Environment.Development]: {
    FAVICON_URL: '/images/icons/development/favicon.ico',
    GOOGLE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.GOOGLE_TEXT_TO_SPEECH_KEY ?? '',
    OPTIMIZELY_LOG_LEVEL: '',
    OPTIMIZELY_SDK_KEY: publicRuntimeConfig.OPTIMIZELY_SDK_KEY ?? '',
    TOUCAN_OPTIMIZELY_DATAFILE_URL: 'http://localhost:4000/rollouts/config',
    PREMIUM_SUBSCRIPTION_PLAN_ANNUAL: 'Annual',
    PREMIUM_SUBSCRIPTION_PLAN_MONTHLY: 'Monthly',
    SITE: SITE_URL ?? 'https://localhost:3000',
    STRIPE_TOKEN: 'pk_test_1gC5ubdjwGpHJFdWBYvJtWE900lmtjHDlb',
    TOUCAN_GOOGLE_AUTH_CLIENT_ID: '337996432830-6slue916putu4q6pbar51ov7p60m32ot.apps.googleusercontent.com',
    TOUCAN_APPLE_AUTH_CLIENT_ID: APPLE_AUTH_STAGING_CLIENT_ID,
    TOUCAN_GRAPHQL_ENDPOINT: 'http://localhost:4000/graphql',
    AZURE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.AZURE_TEXT_TO_SPEECH_KEY ?? '',
    IP_REGISTRY_GEOLOCATION_KEY: publicRuntimeConfig.IP_REGISTRY_GEOLOCATION_KEY ?? '',
    GOOGLE_AUTH_REDIRECT_URI: 'https://localhost:3000/auth/google/redirect',
    GOOGLE_TAG_MANAGER_ACCOUNT_ID: 'GTM-PRKLRM4',
    APPLE_AUTH_REDIRECT_URI:
      process.env.NEXT_PUBLIC_TOUCAN_APPLE_AUTH_REDIRECT_URI_NGROK ?? 'https://localhost:3000/auth/apple/process',
    PAYPAL_CLIENT_ID: 'AdtfGaRFaOWfmGzzIqADQD3GHRbqvhJaxEPwoFi0e7ZiQSAki_FsJYnkl4_CJpqjOdEHRtHrtEau_QV3',
    AMPLITUDE_EXPERIMENT_DEPLOYMENT: 'client-0FWqSBbBCL02gNhyainGdQT2Je3wuYJ6',
    AMPLITUDE_API_KEY: '2063a1669e311f300738bc2f23ec40b2',
  },
  [Environment.Staging]: {
    FAVICON_URL: '/images/icons/staging/favicon.ico',
    GOOGLE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.GOOGLE_TEXT_TO_SPEECH_KEY ?? '',
    OPTIMIZELY_LOG_LEVEL: '',
    OPTIMIZELY_SDK_KEY: publicRuntimeConfig.OPTIMIZELY_SDK_KEY ?? '',
    TOUCAN_OPTIMIZELY_DATAFILE_URL: 'https://stage.toucanapi.com/rollouts/config',
    PREMIUM_SUBSCRIPTION_PLAN_ANNUAL: 'Annual',
    PREMIUM_SUBSCRIPTION_PLAN_MONTHLY: 'Monthly',
    SITE: SITE_URL ?? 'https://stage-internal.jointoucan.com',
    STRIPE_TOKEN: 'pk_test_1gC5ubdjwGpHJFdWBYvJtWE900lmtjHDlb',
    TOUCAN_GOOGLE_AUTH_CLIENT_ID: '337996432830-6slue916putu4q6pbar51ov7p60m32ot.apps.googleusercontent.com',
    TOUCAN_APPLE_AUTH_CLIENT_ID: APPLE_AUTH_STAGING_CLIENT_ID,
    TOUCAN_GRAPHQL_ENDPOINT: 'https://stage.toucanapi.com/graphql',
    AZURE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.AZURE_TEXT_TO_SPEECH_KEY ?? '',
    IP_REGISTRY_GEOLOCATION_KEY: publicRuntimeConfig.IP_REGISTRY_GEOLOCATION_KEY ?? '',
    GOOGLE_AUTH_REDIRECT_URI: SITE_URL
      ? `${SITE_URL}/auth/google/redirect`
      : 'https://stage-internal.jointoucan.com/auth/google/redirect',
    GOOGLE_TAG_MANAGER_ACCOUNT_ID: 'GTM-PRKLRM4',
    APPLE_AUTH_REDIRECT_URI: 'https://stage-internal.jointoucan.com/auth/apple/process',
    PAYPAL_CLIENT_ID: 'AdtfGaRFaOWfmGzzIqADQD3GHRbqvhJaxEPwoFi0e7ZiQSAki_FsJYnkl4_CJpqjOdEHRtHrtEau_QV3',
    AMPLITUDE_EXPERIMENT_DEPLOYMENT: 'client-0FWqSBbBCL02gNhyainGdQT2Je3wuYJ6',
    AMPLITUDE_API_KEY: '2063a1669e311f300738bc2f23ec40b2',
  },
  [Environment.Production]: {
    FAVICON_URL: '/images/icons/production/favicon.ico',
    GOOGLE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.GOOGLE_TEXT_TO_SPEECH_KEY ?? '',
    OPTIMIZELY_LOG_LEVEL: 'info',
    OPTIMIZELY_SDK_KEY: publicRuntimeConfig.OPTIMIZELY_SDK_KEY ?? '',
    TOUCAN_OPTIMIZELY_DATAFILE_URL: 'https://toucanapi.com/rollouts/config',
    PREMIUM_SUBSCRIPTION_PLAN_ANNUAL: 'premium-annual',
    PREMIUM_SUBSCRIPTION_PLAN_MONTHLY: 'premium-monthly',
    SITE: publicRuntimeConfig.SITE_URL ?? 'https://jointoucan.com',
    STRIPE_TOKEN: 'pk_live_V6NNdKSVwQKjAKx0TJ5ENkjN00j0aq6dS4',
    TOUCAN_GOOGLE_AUTH_CLIENT_ID: '337996432830-6slue916putu4q6pbar51ov7p60m32ot.apps.googleusercontent.com',
    TOUCAN_APPLE_AUTH_CLIENT_ID: APPLE_AUTH_PRODUCTION_CLIENT_ID,
    TOUCAN_GRAPHQL_ENDPOINT: 'https://toucanapi.com/graphql',
    AZURE_TEXT_TO_SPEECH_KEY: publicRuntimeConfig.AZURE_TEXT_TO_SPEECH_KEY ?? '',
    IP_REGISTRY_GEOLOCATION_KEY: publicRuntimeConfig.IP_REGISTRY_GEOLOCATION_KEY ?? '',
    GOOGLE_AUTH_REDIRECT_URI: 'https://jointoucan.com/auth/google/redirect',
    GOOGLE_TAG_MANAGER_ACCOUNT_ID: 'GTM-PGTDQDD',
    APPLE_AUTH_REDIRECT_URI: 'https://jointoucan.com/auth/apple/process',
    PAYPAL_CLIENT_ID: 'ATnnPdUQDas9bcu-v5NkIdAb3-edRYnw-5dlxriOR0WdY_yAxaISNKvdkgDYo74P8ETtdJCJHSFJ0u7O',
    AMPLITUDE_EXPERIMENT_DEPLOYMENT: 'client-hw34wxmmAkHi20H6SgJooW1JWXWomPJq',
    AMPLITUDE_API_KEY: '60c9194131c14fab42b444f81a5627e3',
  },
};

export default configs[env];
