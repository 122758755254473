import { useState, useContext } from 'react';

import cookie from 'cookie';

import GlobalContext from '~/context/GlobalContext';

type CookieInfo = {
  name: string;
  path?: string;
  sameSite?: boolean | 'none' | 'lax' | 'strict' | undefined;
  secure?: boolean;
};

interface SetCookieInfo extends CookieInfo {
  value: string;
  numDaysActive: number;
}

export const setCookie = ({
  name,
  value,
  numDaysActive,
  path = '/',
  sameSite = 'none',
  secure = true,
}: SetCookieInfo) => {
  // If the site is running on a secured connection, allow any combination of samesite/secure
  // If it's not, it's running locally and we need to allow cookies to be accessed from not secured contexts.
  // Read more about this here: https://www.notion.so/jointoucan/Same-Site-Cookies-3a087666927f45d79d3ac3fc3249ca46
  const isSecureHost = window.location.protocol === 'https:';
  document.cookie = cookie.serialize(name, value, {
    maxAge: numDaysActive * 24 * 60 * 60,
    path,
    sameSite: isSecureHost ? sameSite : 'lax',
    secure: isSecureHost ? secure : false,
  });
};

export const expireCookie = ({ name, path = '/', sameSite = 'none', secure = true }: CookieInfo) => {
  document.cookie = cookie.serialize(name, '', {
    maxAge: -1,
    path,
    sameSite,
    secure,
  });
};

export const getAllCookies = ({ cookies = {} }: { cookies?: Partial<{ [key: string]: string }> }) => {
  return typeof document !== 'undefined' ? cookie.parse(document.cookie) ?? cookies : cookies;
};

export const getCookie = ({ name, cookies = {} }: { name: string; cookies?: Partial<{ [key: string]: string }> }) => {
  const allCookies = getAllCookies({ cookies });
  return allCookies[name];
};

export const useCookies = () => {
  const { allCookies } = useContext(GlobalContext);
  // use client cookies if we are on the client
  return typeof document !== 'undefined' ? cookie.parse(document.cookie) : allCookies;
};

export const useCookie = (name: string) => {
  const [_, reload] = useState(0); // This just forces reload of cookie values
  const cookies = useCookies();

  return [
    getCookie({ name, cookies }),
    (options: { value: string; numDaysActive: number }) => {
      setCookie({ name, ...options });
      reload(_ + 1);
    },
  ] as const;
};
