import Bowser from '@jointoucan/bowser';
import getNextConfig from 'next/config';

import { createExtensionConfig, ExtensionConfig } from '~/lib/extension-config';
import { DeviceConfig } from '~/types/next';

interface ExtensionDeviceConfig {
  extensionConfig: ExtensionConfig;
  deviceConfig: Partial<DeviceConfig>;
}

export const getConfig = (userAgent: string = ''): ExtensionDeviceConfig => {
  const environment = getNextConfig().publicRuntimeConfig.ENV || 'development';
  const { browser, os } = userAgent
    ? Bowser.parse(userAgent)
    : { browser: { name: '', version: '' }, os: { name: '', version: '' } };
  const extensionConfig = createExtensionConfig({
    browserName: browser.name,
    browserVersion: browser.version,
    environment,
    osName: os.name,
  });
  const deviceConfig: Partial<DeviceConfig> = {
    browserName: browser.name,
    browserVersion: browser.version,
    operatingSystem: os.name,
    operatingSystemVersion: os.version,
  };

  return {
    extensionConfig,
    deviceConfig,
  };
};
