export const delay = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getFirstName = (fullName: string | undefined | null) => {
  const [firstName] = fullName?.split(/<[/\w\s-]+>|\s/g) || [];
  return firstName;
};

export const centsToDollars = (cents: number) => {
  return (cents / 100).toFixed(2);
};

export const replaceBracketStrings = (str: string, map: Record<string, string | string[] | undefined>) => {
  return Object.keys(map).reduce((updatedString, wordToMatch) => {
    const regex = new RegExp(`\\[${wordToMatch}]`);
    const word = map[wordToMatch];
    if (!word) {
      return updatedString.replace(regex, Array.isArray(word) ? word[0] : word);
    }
    return updatedString;
  }, str);
};

export const filterNull = <T>(v: T | null | undefined): v is T => !!v;

export const fetchJSON = async (url: string) => {
  const response = await fetch(url);
  return response.json();
};
