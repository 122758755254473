import { FC, useMemo } from 'react';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { FinchProvider, FinchClient, QueryCache } from '@finch-graphql/react';
import { ToucanTheme } from '@jointoucan/toucan-design';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';

import { useExtensionConfig } from '~/hooks/useExtensionConfig';
import { ToastProvider } from '~/hooks/useToast';

import ModalProvider from './Modal/ModalProvider';
import { SeoProvider } from './Seo/SeoProvider';

interface ProvidersProps {
  emotionCache: EmotionCache;
}

// Wrapper for the app to live in. This makes sure that apollo client is passed through
const Providers: FC<ProvidersProps> = ({ children, emotionCache }) => {
  const { extensionId, messageKey } = useExtensionConfig();
  const finchClient = useMemo(
    () =>
      new FinchClient({
        id: extensionId,
        messageKey,
        cache: new QueryCache(),
      }),
    [extensionId, messageKey],
  );

  return (
    <FinchProvider client={finchClient}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={ToucanTheme}>
          <CssBaseline />
          <ToastProvider>
            <ModalProvider>
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              </Head>
              <SeoProvider>{children}</SeoProvider>
            </ModalProvider>
          </ToastProvider>
        </ThemeProvider>
      </CacheProvider>
    </FinchProvider>
  );
};

export default Providers;
