import { createContext } from 'react';

import { NextSeoProps } from 'next-seo';

interface SeoContextValue {
  options: NextSeoProps;
  setOptions: (options: NextSeoProps) => void;
}

export const SeoContext = createContext<SeoContextValue>({
  options: {},
  setOptions: () => {},
});
