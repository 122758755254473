import { useState, useEffect, FC } from 'react';

import Lottie, { EventListener, Options } from 'react-lottie';

interface LottieAnimationProps {
  filepath: string;
  startDelayMilliseconds?: number;
  width?: number;
  height?: number;
  className?: string;
  isAnimationLooped?: boolean;
  isAnimationOnAutoplay?: boolean;
  isAnimationStopped?: boolean;
  eventListeners?: ReadonlyArray<EventListener>;
  isClickToPauseDisabled?: boolean;
  options?: Options;
  noWrap?: boolean;
}

/**
 * LottieAnimation is being `DEPRECATED` in favor of `LottiePlayer`.
 * the API are similiar but the library is better supported and
 * allows for starting an animation at the appropiate time.
 */
export const LottieAnimation: FC<LottieAnimationProps> = ({
  filepath,
  startDelayMilliseconds = 0,
  width,
  height,
  className,
  isAnimationLooped = false,
  isAnimationOnAutoplay = false,
  isAnimationStopped = false,
  eventListeners,
  isClickToPauseDisabled,
  options,
  noWrap,
}) => {
  const [isStopped, setIsStopped] = useState(isAnimationStopped || startDelayMilliseconds !== 0);

  useEffect(() => {
    setIsStopped(isAnimationStopped);
  }, [isAnimationStopped]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStopped(false);
    }, startDelayMilliseconds);
    return () => clearTimeout(timer);
  }, []);

  const content = (
    <Lottie
      isStopped={isStopped}
      options={{
        // @ts-ignore Lottie lib types need updating
        path: filepath,
        loop: isAnimationLooped,
        autoplay: isAnimationOnAutoplay,
        ...options,
      }}
      width={width}
      height={height}
      eventListeners={eventListeners}
      isClickToPauseDisabled={isClickToPauseDisabled}
    />
  );

  return noWrap ? content : <div className={className}>{content}</div>;
};
