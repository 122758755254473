import { useTranslation } from 'next-i18next';

import { useUILanguage } from '~/components/LocaleProvider';
import {
  Language,
  LanguageId,
  LanguageInformation,
  SupportedSourceLanguages,
  LanguageVariant,
} from '~/constants/languages';
import { LanguageId as ServerLanguageId } from '~/networking/schema';

export const getLanguageIdFromLanguage = (
  languageInfo: LanguageInformation,
  language: Language,
  languageVariant?: string | undefined | null,
): ServerLanguageId => {
  if (!languageVariant || !languageInfo.variants) {
    return (languageInfo.languageId as unknown) as ServerLanguageId;
  }
  const languageVariantInfo = Object.values(languageInfo.variants).find((variant) => variant.key === languageVariant);
  return languageVariantInfo?.languageId ?? ServerLanguageId.Es;
};

export const useLanguageInfo = () => {
  const { t } = useTranslation();
  const { uiLanguage } = useUILanguage();
  /**
   * order determines how languages are displayed on modal
   */
  const languageInfo: Record<Language, LanguageInformation> = {
    [Language.English]: {
      key: Language.English,
      languageId: LanguageId.En,
      language: t('english'),
      inLanguage: 'English',
      imagePath: '/images/flags/round/english.svg',
      flagEmoji: '\u{1F1FA}\u{1F1F8}',
    },
    [Language.French]: {
      key: Language.French,
      languageId: LanguageId.Fr,
      language: t('french'),
      inLanguage: 'Français',
      imagePath: '/images/flags/round/french.svg',
      flagEmoji: '\u{1F1EB}\u{1F1F7}',
    },
    [Language.German]: {
      key: Language.German,
      languageId: LanguageId.De,
      language: t('german'),
      inLanguage: 'Deutsch',
      imagePath: '/images/flags/round/german.svg',
      flagEmoji: '\u{1F1E9}\u{1F1EA}',
    },
    [Language.Italian]: {
      key: Language.Italian,
      languageId: LanguageId.It,
      language: t('italian'),
      inLanguage: 'Italiano',
      imagePath: '/images/flags/round/italian.svg',
      flagEmoji: '\u{1F1EE}\u{1F1F9}',
    },
    [Language.Korean]: {
      key: Language.Korean,
      languageId: LanguageId.Ko,
      language: t('korean'),
      inLanguage: '한국어',
      imagePath: '/images/flags/round/korean.svg',
      flagEmoji: '\u{1F1F0}\u{1F1F7}',
      variants: {
        [LanguageVariant.Hangul]: {
          languageId: ServerLanguageId.Ko,
          key: LanguageVariant.Hangul,
          isDefault: true,
        },
        [LanguageVariant.Roman]: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.KoRoman,
        },
      },
    },
    [Language.Portuguese]: {
      key: Language.Portuguese,
      languageId: LanguageId.Pt,
      language: t('portuguese'),
      inLanguage: 'Português',
      imagePath: '/images/flags/round/portuguese.svg',
      flagEmoji: '\u{1F1E7}\u{1F1F7}',
    },
    [Language.Spanish]: {
      key: Language.Spanish,
      languageId: LanguageId.Es,
      language: t('spanish'),
      inLanguage: 'Español',
      imagePath: '/images/flags/round/spanish.svg',
      flagEmoji: '\u{1F1EA}\u{1F1F8}',
    },
    [Language.Arabic]: {
      key: Language.Arabic,
      languageId: LanguageId.Ar,
      language: t('arabic'),
      inLanguage: 'اللغة العربية',
      imagePath: '/images/flags/round/arabic.svg',
      flagEmoji: '\u{1F30D}', // NOTE: country agnostic - using globe
      isEarlyAccess: true,
      variants: {
        [LanguageVariant.Abjadiyyah]: {
          languageId: ServerLanguageId.Ar,
          key: LanguageVariant.Abjadiyyah,
          isDefault: true,
        },
        [LanguageVariant.Roman]: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.ArRoman,
        },
      },
    },
    [Language.Chinese]: {
      key: Language.Chinese,
      languageId: LanguageId.Zh,
      language: t('chinese'),
      inLanguage: '中文',
      imagePath: '/images/flags/round/chinese.svg',
      flagEmoji: '\u{1F1E8}\u{1F1F3}',
      isEarlyAccess: true,
      variants: {
        [LanguageVariant.SimplifiedChinese]: {
          languageId: ServerLanguageId.Zh,
          key: LanguageVariant.SimplifiedChinese,
          isDefault: true,
        },
        [LanguageVariant.Roman]: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.ZhRoman,
        },
      },
    },
    [Language.Hebrew]: {
      key: Language.Hebrew,
      languageId: LanguageId.He,
      language: t('hebrew'),
      inLanguage: 'עברית',
      imagePath: '/images/flags/round/hebrew.svg',
      flagEmoji: '\u{1F1EE}\u{1F1F1}',
      isEarlyAccess: true,
      variants: {
        [LanguageVariant.KtavAshuri]: {
          languageId: ServerLanguageId.He,
          key: LanguageVariant.KtavAshuri,
          isDefault: true,
        },
        [LanguageVariant.Roman]: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.HeRoman,
        },
      },
    },
    [Language.Hindi]: {
      key: Language.Hindi,
      languageId: LanguageId.Hi,
      language: t('hindi'),
      inLanguage: 'हिंदी',
      imagePath: '/images/flags/round/hindi.svg',
      flagEmoji: '\u{1F1EE}\u{1F1F3}',
      isEarlyAccess: true,
      variants: {
        [LanguageVariant.Devanagari]: {
          languageId: ServerLanguageId.Hi,
          key: LanguageVariant.Devanagari,
          isDefault: true,
        },
        [LanguageVariant.Roman]: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.HiRoman,
        },
      },
    },
    [Language.Japanese]: {
      key: Language.Japanese,
      languageId: LanguageId.Ja,
      language: t('japanese'),
      inLanguage: 'にほんご',
      imagePath: '/images/flags/round/japanese.svg',
      flagEmoji: '\u{1F1EF}\u{1F1F5}',
      isEarlyAccess: true,
      variants: {
        kanji: {
          languageId: ServerLanguageId.JaKanji,
          key: LanguageVariant.Kanji,
        },
        hiraganaKatakana: {
          languageId: ServerLanguageId.Ja,
          key: LanguageVariant.HiraganaKatakana,
          isDefault: true,
        },
        roman: {
          key: LanguageVariant.Roman,
          languageId: ServerLanguageId.JaRoman,
        },
      },
    },
  };

  const getDefaultLanguageInfo = (sourceLanguage: SupportedSourceLanguages) => {
    return sourceLanguage === LanguageId.En ? languageInfo.spanish : languageInfo.english;
  };

  const defaultLanguageInfo = getDefaultLanguageInfo(uiLanguage);

  return {
    languageInfo,
    defaultLanguageInfo,
    getLanguageInfo: (language: Language) => languageInfo[language],
    getLanguageId: (suppliedLanguage: Language) => languageInfo[suppliedLanguage].languageId,
    getLanguageInfoFromLanguageId: (suppliedLanguage: string) =>
      Object.values(languageInfo).find(
        (info) =>
          info.languageId === suppliedLanguage ||
          Object.values(info.variants ?? {})?.some((variants) => variants.languageId === suppliedLanguage),
      ),
    getLanguageFromLanguageId: (suppliedLanguage: string) =>
      Object.values(languageInfo).find((info) => info.languageId === suppliedLanguage)?.language,
    getNonEnglishLanguageIds: () =>
      Object.values(languageInfo)
        .filter((info) => info.language.toLocaleLowerCase() !== Language.English)
        .map((language) => language.languageId),
    getNonEnglishLanguageNames: () =>
      Object.values(languageInfo)
        .filter((info) => info.language.toLocaleLowerCase() !== Language.English)
        .map((language) => language.language),
    getDefaultLanguageInfo,
    getLanguageIdFromLanguage,
  };
};
