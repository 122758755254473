import { ReactNode, createContext } from 'react';

interface UnknownProps {
  [key: string]: unknown;
}

interface ShowModalProps {
  ContentComponent?: ReactNode;
  closeButtonColor?: string;
  contentProps?: UnknownProps;
  isCloseButtonVisible?: boolean;
  onClose?: () => void;
  hasPadding?: boolean;
  className?: string;
  modalContentClassName?: string;
  disableBackdropClick?: boolean;
  isModalHeaderVisible?: boolean;
  isToucanBrandingVisible?: boolean;
  modalHeader?: ReactNode;
}

export interface ModalContextProps {
  modal: {
    showModal: (props: ShowModalProps) => void;
    closeModal: () => void;
  };
}

const ModalContext = createContext<ModalContextProps>({
  modal: {
    showModal: () => {},
    closeModal: () => {},
  },
});

export default ModalContext;
