import React, { FC } from 'react';

import { css } from '@emotion/react';
import { ToucanColors, ToucanComponents } from '@jointoucan/toucan-design';
import { useTheme } from '@mui/material';

import { Box, BoxImage, MotionBox } from '~/components/shared/Box';
import { LottieAnimation } from '~/components/shared/LottieAnimation';

import { ToastProps } from './types';

const { Typography, ToucanIcon } = ToucanComponents;

export const Toast: FC<ToastProps & { onAttemptClose: () => void; id: string; yPosition: number }> = ({
  id,
  title,
  subtitle,
  onClick,
  image,
  className,
  yPosition,
  titleColor,
  subtitleColor,
  backgroundColor,
  onAttemptClose = () => {},
  isAnimation = false,
  boldTitle = true,
  boldSubtitle = true,
}) => {
  const theme = useTheme();

  const onAttemptCloseProxy = (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>) => {
    // Stop the propagation to avoid banner click
    e.stopPropagation();
    onAttemptClose();
  };

  const outProps = { opacity: 0, x: -20 };

  return (
    <MotionBox
      key={id}
      animate={{ opacity: 1, x: 0, y: yPosition }}
      transition={{ y: { type: 'spring', stiffness: 100, damping: 25 } }}
      exit={outProps}
      initial={outProps}
      role="button"
      tabIndex={0}
      aria-hidden="false"
      onClick={onClick}
      onKeyDown={onClick}
      className={className}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="442px"
      width="442px"
      minWidth="442px"
      zIndex={2147483647}
      left={theme.spacing(2)}
      bottom={theme.spacing(2)}
      position="fixed"
      css={css`
        cursor: pointer;
        ${theme.breakpoints.down(442)} {
          width: calc(100% - 32px);
          min-width: auto;
        }
      `}
    >
      <Box
        bgcolor={backgroundColor ?? ToucanColors.white}
        borderRadius="8px"
        boxShadow="0px 12px 36px rgba(22, 22, 22, 0.08)"
        display="flex"
        alignItems="center"
        p={2}
        position="relative"
        width="100%"
      >
        <Box mr={2} width="48px" height="48px" position="relative">
          {isAnimation ? (
            <LottieAnimation filepath={image} />
          ) : (
            <BoxImage src={image} alt="Toast Icon" width="48px" height="48px" />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          css={css`
            p:nth-of-type(1) {
              color: ${titleColor ?? ToucanColors.gray[400]};
            }
            p:nth-of-type(2) {
              color: ${subtitleColor ?? ToucanColors.teal[300]};
            }
          `}
        >
          <Typography isBold={boldTitle} font="primary" variant="sm">
            {title}
          </Typography>
          {subtitle && (
            <Typography isBold={boldSubtitle} font="primary" variant="sm">
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignContent="center" justifyContent="center" flex={0} pl={2}>
          <Box
            onClick={onAttemptCloseProxy}
            onKeyDown={onAttemptCloseProxy}
            role="button"
            tabIndex={0}
            display="inline-flex"
            css={css`
              svg {
                color: rgba(0, 0, 0, 0.2);
                width: ${theme.spacing(2)};
                height: ${theme.spacing(2)};
              }
            `}
          >
            <ToucanIcon icon="times" />
          </Box>
        </Box>
      </Box>
    </MotionBox>
  );
};
