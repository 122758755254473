import { FC } from 'react';

import { useFeatureEnabled } from './hooks';

export const Feature: FC<{ name: string }> = ({ name, children }) => {
  const isEnabled = useFeatureEnabled(name);

  if (!isEnabled) {
    return null;
  }

  return <>{children}</>;
};
