import { ApolloClient } from '@apollo/client';

import { MeQuery, MeQueryVariables, MeDocument } from '~/networking/schema';

export type LoggedInUser = MeQuery['me'];

const getLoggedInUser = async (apolloClient: ApolloClient<object>) => {
  try {
    const response = await apolloClient.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
    });

    return response.data.me;
  } catch (error) {
    return false;
  }
};

export default getLoggedInUser;
